import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article7() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article8');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article6');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article7-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article7-img2.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article7-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <div className="row mb-5 align-items-center">
              <div className="col-sm-6">
                <motion.div
                  variants={textAnimate}
                >

                  <h1 className="fs-xl fw-normal mb-5">The <br />Lighthouse <br />Family </h1>

                </motion.div>
              </div>
              <div className="col-sm-6">
                <motion.img variants={textAnimate} className="img-fluid"
                  src={require("../../Images/article7-img3.jpg")}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>Inchkeith, Hoxa Head and Girdleness. Bass Rock, Sule Skerry and the Butt of Lewis. Ushenish, Rhuvaal and Muckle Flugga. The list of Scotland’s lighthouses – which now stretches to some 207 different sites around the country – reads like an atlas of places to get away from it all. These exposed, gust-blown beacons dot some of the wildest, most remote parts of the coastline, each one representing a minor miracle of engineering. They sit on cliffs, ledges, breakwaters and islands. More remarkably still, the vast majority of them were designed by multiple generations of the same family.</motion.p>
                <motion.p className="" variants={textAnimate}>Their story begins with a Glaswegian sugar trader dying of fever in the Caribbean. The man was Alan Stevenson, whose 1774 death brought his family’s income to an abrupt halt. Back in Scotland he left a young wife, Jean, and a two-year-old son, Robert. The boy was sent to a charity school, then later moved to Edinburgh with his mother, where she married a lamp-maker named Thomas Smith. This simple twist of fate would shape the look of Scotland’s coastline for centuries to come.</motion.p>
                <motion.p className="" variants={textAnimate}>Back in the late 18th century, the Industrial Revolution had spurred a growing demand for sea commerce. But while the shipping lanes around Scotland were well used, they could also be treacherous. No mariner wanted to be scuppered on the rocks, however lucrative the job. So 1786 saw the formation of the Northern Lighthouse </motion.p>

              </div>
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>Board, tasked with making the coastline safer to negotiate. Thomas, whose reputation was strong after working on street lighting in Edinburgh, was invited to become part of the new team. He was, it seems, a good sort, and he took his stepson under his wing by arranging for Robert to be employed as an apprentice.</motion.p>

                <motion.p className="" variants={textAnimate}>The teenage boy took to the work well, enjoying the mix of adventure and creativity that lighthouse design entailed. It took guile and determination, after all, to create vast towers that could face down the Scottish elements in far-flung, inhospitable locations. By the age of 19, young Robert showed so much promise that he was entrusted with supervising the construction of Little Cumbrae Lighthouse in the Firth of Clyde. It’s still there today, standing sentry above the cliffs. </motion.p>
                <motion.p className="" variants={textAnimate}>The rest is legend. Robert spent close to half a century as an engineer for the Northern Lighthouse Board, designing and overseeing at least 19 coastal beacons around Scotland. His masterpiece – or rather one of many – was Bell Rock Lighthouse, constructed on a notorious reef more than 11 miles off the Angus coast. It was built on a perilous, uneven surface only accessible at low tide, taking a team of 60 men more than three years to finish. The solidity of its design means the stonework remains unchanged to this day, despite being pummelled by more than 200 years’ worth of raging North Sea waves.</motion.p>
              </div>
            </div>

          </div>

          <div className="" ref={secondContainer}>
            <h4 className="mb-4">The Light Keeper</h4>
            <div className="row mb-5 fs-sm">
              <motion.div variants={textAnimate} className="col-sm-4">
                The brilliant kernel of the night,
                <br />The flaming lightroom circles me :
                <br />I sit within a blaze of light
                <br />Held high above the dusky sea…

              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-4">
                The clear bell chimes : the clockworks strain :
                <br />The turning lenses flash and pass,
                <br />Frame turning within glittering frame
                <br />With frosty gleam of moving glass…

              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-4">
                And lastly when dawn ends the night
                <br />And belts the semi-orb of sea,
                <br />The tall, pale pharos in the light
                <br />Looks white and spectral as may be.
              </motion.div>
            </div>
            <motion.img variants={textAnimate} className="img-fluid" src={require("../../Images/article7-img4.jpg")} />
            <p className="mt-2 mb-4 fs-sm fw-semibold">Above: Noss Head Lighthouse, right page: Dunnet Head Lighthouse</p>
            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">


                <p className="">Robert also revolutionised the type and efficacy of the lights themselves, introducing the idea of rotating oil lamps in front of silver-plated parabolic reflectors. But in many ways, his legacy was only just beginning. He had ten children, three of whom – David, Alan and Thomas – chose to continue the same profession as their father. David joined the Northern Lighthouse Board. Alan and Thomas oversaw the building of more than 30 lighthouses apiece, all at one point manned by keepers. The Stevenson dynasty was in full flow.</p>
                <p className="">If Robert’s magnum opus was Bell Rock, his son Alan ran him close with the construction of Skerryvore Lighthouse, a six-year undertaking on a rocky island 11 miles off the west coast. It was a fiendish commission. The island was, and is, tiny; the tower weighed some 4,300 tons, using granite quarried on Mull; the first barrack built to hold the 40-man workforce was destroyed in a gale. But the project succeeded, eventually, taking from 1838 to 1844 to complete. With 151 interior steps it remains the tallest lighthouse in Scotland.</p>
              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">The family tale doesn’t end there. David’s two sons, Charles and David, continued in the same singular trade, designing and creating almost 30 lighthouses between them, pushing the Stevenson production line right through to the 1930s. It’s almost no exaggeration to say that the wilds of the Scottish coast have been bent to one family’s will. </p>
                <p className="">Mention also has to go, of course, to the most famous Stevenson of the lot. Thomas was disappointed when his only son, born in 1850, declined to go into the family business. But Robert Louis Stevenson was destined for a fate of his own, going on to write world-famous works such as Treasure Island, Kidnapped, and Strange Case of Dr Jekyll and Mr Hyde. He also penned a poem, called The Light-Keeper. Its verses give testament to an ancestral obsession.</p>

              </motion.div>
            </div>
          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article7;
