import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article6() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0
  });
  const { ref: fourContainer, inView: fourInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img4 = document.getElementById("img4");
      img4.className = '';
      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img4 = document.getElementById("img4");
      img4.className = '';
      return "";
    }
    else if (thirdInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = 'active';
      var img4 = document.getElementById("img4");
      img4.className = '';
      return "";
    }
    else if (fourInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img4 = document.getElementById("img4");
      img4.className = 'active';
      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article7');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article5');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article6-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article6-img3.jpg")} />
          <img id="img3" className={getAnimationClass()} src={require("../../Images/article6-img4.jpg")} />
          <img id="img4" className={getAnimationClass()} src={require("../../Images/article6-img5.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article6-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div
              variants={textAnimate}
            >
              <h4>A family stay in</h4>
              <h1 className="fw-normal">RURAL <br />PERTHSHIRE</h1>
              <h6 className="font-italic" variants={textAnimate}>Travel writer Laurie Goodlad shares her favourite <br />family experiences from rural Perthshire.</h6>
            </motion.div>
            <div className="mr-n2"><motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article6-img2.png")} /></div>
            <div className="row mb-5">
                  <div className="col-sm-6">

                    <motion.p className=" text-orange" variants={textAnimate}>With vibrant forests and woodlands, rivers bursting with crystal clear water, cascading waterfalls tumbling into quiet glens and a myriad of activities for both young and old, Perthshire is the ideal place to enjoy a family break this year. Solitary and peaceful, adventure-packed and active, Perthshire has something for everyone.</motion.p>
                    <motion.p className="" variants={textAnimate}>Known as the Big Tree Country, Perthshire gives Scotland its lungs, providing a rich tapestry of autumn colour with rich oranges, russet browns, golds and yellows that transform to lush, leafy green in summer. Each season brings magic and drama to this compelling and inspiring landscape.</motion.p>
                    <motion.p className="" variants={textAnimate}>Perthshire offers a gateway to the Highlands, centrally located and easily accessible, just a short drive from Glasgow and Edinburgh.</motion.p>
                    <motion.p className="" variants={textAnimate}>With Scotland’s longest river, Perthshire’s highest summit, and the oldest living thing in Europe – an aged yew tree – the Loch Tay area is the ideal base for a family adventure.</motion.p>

                  </div>
                  <div className="col-sm-6">

                    <motion.p className="" variants={textAnimate}>We chose the picturesque village of Killin, at the western head of Loch Tay, for our stay. Killin is probably most famous for its incredible series of rapids known as the Falls of Dochart that pass through the village in a turbulent torrent of water.</motion.p>

                    <motion.p className="" variants={textAnimate}>We stayed at The Crannog Cottage, a two-bedroom self-catering lodge named after the prehistoric loch-side roundhouses traditional to Scotland’s past. The Crannog is a luxury holiday cottage with a hot tub and picturesque views over the River Lochay. Loch Tay, who own the Crannog, offer a range of stunning lodges and cabins throughout the Loch Tay area.</motion.p>

                    <motion.div variants={textAnimate} className="bg-gold p-4">
                      <h4 className="mt-0 mb-3">Top tip</h4>
                      <p className="mb-0">Whilst staying in Killin, book a table at the Courie Inn, where you’ll enjoy a warm welcome and delicious food in a beautiful, family-friendly setting.</p>
                    </motion.div>
                  </div>
                </div>

          </div>

          <div className="" ref={secondContainer}>
            
            
            <h3 className="mb-5">Day one <br /><span className="text-gold">Aberfeldy</span></h3>
            <motion.p className="font-italic" variants={textAnimate}>The following is a suggested itinerary <br />for a three-day family stay.</motion.p>
            <motion.div className="" variants={textAnimate}><img className="img-fluid mb-4" src={require("../../Images/article6-img6.png")} /></motion.div>
            
            <div className="row mb-5">
                  <motion.div variants={textAnimate} className="col-sm-6">
                    
                    <p className="">Travelling with kids means that there must be a balance between indulging in cultural activities and burning off excess energy, and Perthshire offers the perfect solution. With some of the best woodland trails in Scotland, Tay Forest Park boasts some of the country’s tallest trees alongside breathtaking scenery and magnificent viewpoints across several of Scotland’s most famous vistas.</p>
                    <h5 class="mb-2 text-gold">Birks of Aberfeldy walk</h5>
                    <p className="">A circular woodland walk upriver towards the famous ‘Birks’ (from the Scots for birch trees) overlooking the impressive Falls of Moness and Moness gorge.</p>
                    <p className="">The walk begins on the western outskirts of Aberfeldy and is well-signposted, with plenty of parking. The path leads up the Moness burn and is punctuated with several waterfalls and a steep-sided gorge. Along the walk, you’ll discover Burns’ seat – where Robbie Burns was inspired to write the song Birks of Aberfeldy.</p>
                    <p className="">This 2.3-mile (4.7 km) walk is ideal for young children, with a decent path and plenty to interest them along the way. The first part of the walk is accessible for pushchairs and accessibility wheelchairs; however, the final part of the route involves steps and uneven ground. The lower reaches of the path run parallel to the river with good views of the waterfalls</p>

                  </motion.div>
                  <motion.div variants={textAnimate} className="col-sm-6">

                    <h5 class="mb-2"><span className="text-gold">Lunch:</span> Aberfeldy Watermill Bookshop & Café</h5>
                    <p className="">Serving the best soups, thick-cut sandwiches, and a delicious selection of cakes and bakes, this restored watermill is also home to a famous bookshop and gallery – crowned UK Independent Bookshop of the Year.</p>
                    <h5 class="mb-2 text-gold">Dewars Aberfeldy Distillery</h5>
                    <p className="">Award-winning whisky distillery and five-star visitor attraction.</p>
                    <p className="">Dewars have been producing single-malt whisky since 1898, and it remains the whisky of choice for export to the American market. Aberfeldy’s water of life has also been granted a Royal Warrant – a testament to the quality of the whisky.</p>
                    <p className="">Many distilleries don’t allow children, and although children aren’t permitted on the tasting tours, they are welcomed in the café and the museum showcasing the distillery’s history. Our children enjoyed the distillery, even if their presence meant that only one adult could do the tour!</p>
                    <div className="bg-gold p-4">
                      <h4 className="mt-0 mb-3">Top tip</h4>
                      <p className="mb-0">Spirit of Wood is just up the road from the distillery, selling beautiful hand-crafted pieces of furniture.</p>
                    </div>
                  </motion.div>
                </div>

          </div>

          <div className="" ref={thirdContainer}>
            <h3 className="mb-4">Day two <br /><span className="text-gold">Highland Safaris & Red Deer Centre</span></h3>
            
            <motion.div className="" variants={textAnimate}><img className="img-fluid mb-5" src={require("../../Images/article6-img7.png")} /></motion.div>
            
            <div className="row mb-5">
                  <motion.div variants={textAnimate} className="col-sm-6">
                    
                    <p className="">Highland Safaris, a few miles outside Aberfeldy, is a real gem. With excellent facilities, this family-friendly Centre is where you’ll want to spend the whole day.</p>
                    
                    <p className="">The popular Red Deer Centre offers a flying demonstration from Ossian, the barn owl, and the opportunity to meet and feed the deer. Pre-booking necessary.</p>
                    <p className="">Our guide explained the importance of deer management in the area as the children fed the deer. UK deer populations are very high, and without control, deer have a devastating effect on the landscape, causing damage to woodlands and bird populations.</p>
                    <p className="">It’s worth visiting during the rutting season, which begins in September and lasts until early November. The stags, pumped full of testosterone, become vocal and dominant over the herd.</p>
                    <h5 class="mb-2 text-gold">Panning for gold</h5>
                    <p className="">This area is geologically fascinating, with gold found in the region’s Dalradian rock. The Centre’s gold-panning station, complete with a sluice station and all the glittering gemstones that any would-be prospector could wish for, was a fantastic way of making geology accessible and engaging for  </p>

                  </motion.div>
                  <motion.div variants={textAnimate} className="col-sm-6">
                    <p className="">all the family. This activity suits all ages, young and old, and the stations are the correct height to allow wheelchair users to participate fully.</p>
                    <h5 class="mb-2"><span className="text-gold">Lunch:</span> <br />Highland Safaris Café</h5>
                    <p className="">With a menu serving local produce, including their famous venison burgers, the warm and welcoming atmosphere left no doubt that the coveted title of Best Visitor Experience in Scotland was well-deserved.</p>
                    <h5 class="mb-2 text-gold">Bike hire</h5>
                    <p className="">Highland Safaris have a range of bicycles and tag-along options for younger riders. With plenty of trails to choose from, suitable for all ages and abilities, the staff advise riders on the best route. We opted for one of the easier options, following the course of the River Tay along a rough footpath through woodland and open farmland into Aberfeldy. The trail was 2.5 miles each way, and we treated ourselves to more cake and lemonade at the Watermill Café before heading back.</p>
                    <h5 class="mb-2 text-gold">Glen Lyon</h5>
                    <p className="">Pronounced ‘Lion’, this beautiful scenic drive meanders through one of Scotland’s loneliest and most beautiful glens.</p>
                    
                  </motion.div>
                </div>
                <div className="bg-light-gold outter-box">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                    <motion.div className="" variants={textAnimate}><img className="img-fluid" src={require("../../Images/article6-img9.png")} /></motion.div>
                    </div>
                    <div className="col-sm-6">
                      <h5 className="text-brown p-5 text-justify mb-0">Sir Walter Scott once described Perthshire as “The fairest portion of the northern kingdom”, and it’s easy to see why. We merely scratched the surface, but its scenery and activities offered something for all the family. Perthshire enjoys all the drama of the Highlands, interspersed with fertile, lush glens. The Loch Tay area provides a ‘wow’ around every corner.</h5>
                    </div>
                  </div>
                </div>
          </div>

          <div className="" ref={fourContainer}>
          <h3 className="mb-4">Day three <br /><span className="text-gold">A Highland Safari & Killiecrankie</span></h3>
            
            <motion.div className="" variants={textAnimate}><img className="img-fluid mb-5" src={require("../../Images/article6-img8.png")} /></motion.div>
            
            <div className="row mb-5">
                  <motion.div variants={textAnimate} className="col-sm-6">
                    <h5 class="mb-2 text-gold">Land Rover 4x4 Safari</h5>
                    <p className="">Head into the hills with Highland Safaris (pre-booking required) and learn more about the area’s geology and wildlife. This family-friendly tour will ignite your children’s imaginations as they learn about the natural world.</p>
                    
                    <p className="">We explored Drummond Hill, an extinct volcano shaped by the last Ice Age. We saw deer, crossbills, and a buzzard flying overhead along the way. The guide explained that there were capercaillie – huge woodland grouse – and pine martens in the woodland, although we didn’t spot them!</p>
                    <p className="">Exploring the diversity of species that thrived in the native forest, including heathers, fungi, mosses, lichens and a host of other species, was great fun before stopping at the impressive Black Rock Viewpoint with commanding views towards Kenmore and the Scottish Crannog Centre.</p>
                    <p className="">We left Highland Safaris once more to head to The Queen’s View overlooking Loch Tummel, a 20-mile drive from Aberfeldy. It was made famous by Queen Victoria, who visited in 1866. Describing it as a ‘spectacular view’, it has since become one of the most photographed places in Scotland – despite the shameless ‘tourist trap’ Visitor Centre.</p>

                  </motion.div>
                  <motion.div variants={textAnimate} className="col-sm-6">
                    <h5 class="mb-2 text-gold">Killiecrankie</h5>
                    <p className="">Meaning the place of the woodpecker, famous for the Battle of Killiecrankie in 1689, which saw the defeat of government forces by a Jacobite army led by ‘Bonnie Dundee’. </p>
                    
                    <p className="">Follow the 2.5-mile forest trail from the Visitor Centre to Garry Bridge, passing the Soldier’s Leap, where a Redcoat soldier evaded Jacobite forces by jumping the 5.5 metres across the river. This tranquil and outstandingly beautiful walk follows the river’s course through dense woodland toward the bridge offering stunning views of the gorge. The forest, much admired by Queen Victoria, is an oak and mixed deciduous woodland. </p>
                    
                    <p className="">This walk is unsuitable for pushchairs and wheelchair users, but the Visitor Centre has a shop, café, changing facilities and children’s activities.</p>
                    <div className="bg-gold p-4">
                      <h4 className="mt-0 mb-3">Top tip</h4>
                      <p className="mb-0">Stop at Fortingall Yew, a relict tree thought to be around 5,000 years old and the oldest living thing in Europe – if not the world.</p>
                    </div>
                    
                  </motion.div>
                </div>
                <div className="row mb-5">
                  <motion.div variants={textAnimate} className="col-sm-6">
                    <img className="img-fluid mb-2" src={require("../../Images/article6-img10.jpg")} />
                    <p class="mb-0">The Scottish Crannog Centre</p>
                  </motion.div>
                  <motion.div variants={textAnimate} className="col-sm-6">
                    <h4 class="mb-4 mt-3">Other things to do in the area include:</h4>
                    <ul class="mb-5 text-gold list-unstyled h4">
                      <li className="mb-3">The Scottish Crannog Centre</li>
                      <li className="mb-3">A boat trip around Loch Tay</li>
                      <li className="mb-3">Watersports – from SUP boarding to kayaking</li>
                      <li className="mb-3">Go Ape provides a treetop adventure for those with teenagers</li>
                      <li className="mb-3">The Hermitage</li>
                      <li className="mb-3">Beatrix Potter Garden</li>
                      <li className="mb-3">Iain Burnett Highland Chocolatier</li>
                    </ul>
                    <p className="">For those travelling without children, try some incredible hikes, including Sciehallion – known as the fairy hill – which is one of Scotland’s best-loved Munros.</p>
                    
                    
                    
                  </motion.div>
                </div>
          </div>
          </div>

        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article6;
