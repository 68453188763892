import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article19() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article20');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article18');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article19-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article19-img2.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article19-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div variants={textAnimate}>
              <h1 className="fs-xl fw-light mb-3">Where the <br/>Muse takes <br/>you.</h1>
            </motion.div>
            <motion.p className="mb-5" variants={textAnimate}>Throughout Scotland’s people-less places there are cradles of creativity. From artists’ residencies in hip Highland hideaways to writing retreats on remote islands, those drawn to the wilderness for silence, solitude and space to create can find sanctuary in bespoke bothies, off-grid cabins and cottages, and bohemian art-themed hotels.</motion.p>

            <h2 class="fw-normal mb-5 text-brown">“...the sense of freedom created by stripping away life’s clutter along with immersion in the natural environment is vital for the artistic process...”</h2>

            <motion.p className="" variants={textAnimate}>Almost a century ago novelist Virginia Woolf asserted “a woman must have money and a room of her own if she is to write fiction,” in a lecture to the Arts Society at Newnham in 1928, later published as “A Room of One’s Own.” As true today – if not truer, artists, writers, makers, creators have always sought silence, solitude and space far from life’s distractions.</motion.p>
            <motion.p className="" variants={textAnimate}>It was the ethos behind the foundation of the Bothy Project in 2011. Hiking, sketching and bedding down in basic shelters in the mountains gave artist Bobby Niven’s work a new energy. The light bulb moment, that the sense of freedom created by stripping away life’s clutter along with immersion in the natural environment is vital for the artistic process, led him to establish the Bothy Project with friend and architect Iain MacLeod.</motion.p>
            <motion.p className="" variants={textAnimate}>As part of the RSA Residencies for Scotland Programme they designed the Artist Bothy in 2011 and went on to set up the charitable organisation allowing artists and writers the space to explore ‘creativity, landscape and living simply’ through a programme of residencies and subsidised retreats.</motion.p>
            <motion.p className="" variants={textAnimate}>The two bespoke off-grid cabins (with more on the drawing board) provide a simple, functional work and living space. The utilitarian yet sleek structures are kitted out with everything you need and nothing more, a stove, table and chair, a ladder up to a bed in the eaves.</motion.p>
            <motion.p className="" variants={textAnimate}>The original bothy is at Inshriach, a 200-acre estate in the Cairngorms National Park, hidden in the forest on the banks of the River Spey. The second, Sweeney’s Bothy, on the Hebridean Isle of Eigg, was built in collaboration with artist Alec Finlay as part of Creative Scotland’s Year of Natural Scotland in 2013.  For six months of the year the bothies host residencies, for the other six months they can be rented for a DIY creative retreat. </motion.p>

            
          </div>

          <div className="" ref={secondContainer}>

          <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">In December 2022, storyteller and adventurer Lee Craigie checked in to the bothy on Eigg to finish her first book. “It took a day or so to let go and then the creativity I’d been lacking at home returned. I find it impossible to be creative when I’m distracted and there are so many distractions in real life. The fact that Eigg is powered by renewable energy adds another element to living simply and tuning in to your natural environment. When you’ve had to nurture a fire all day to get water hot enough to wash, you value these resources so much more than if they were available at the flick of a switch.” </p>

                <p className="">“I need to be outside every day or I feel sluggish and disconnected from myself. On Eigg I had the perfect balance. I’d rise at 6.30am and write until it got light. Then I’d have breakfast and keep writing until there was a weather window when I’d leap outside for a bike ride, a walk, a swim or a run before returning to the warmth of the bothy to resume writing.” </p>

                <p className="">Another writer who spent time on Eigg last year, and one of the recipients of the Bothy Project’s five week-long Non-fiction Creative Practitioner Residencies, was writer and poet Sophie Lau, her project a book on Hong Kong based around a series of family interviews. </p>

                <p className="">“I was influenced by the silence and isolation. I woke each morning and looked out at the sea. It was so calming it shifted my perspective. I used to be a procrastinator and would blitz a project as the deadline approached but that kind of frantic energy is not sustainable. On Eigg everything takes time and everything you do depends on the weather, the light. I walked everywhere as I didn’t bring a bicycle. I loved chopping wood, boiling water. I stopped using the gas ring and cooked on the fire. I started to embody slow living.” </p>

                <p className="">She read six books during the week, went for long walks and did a willow-weaving course. And wrote. </p>
                <p className="">“It made me more patient with the project. There was no internet, just me and a Word document. Being so remote helped with my writing and in determining how I want to write in future. The project shifted from the original premise of long interviews to a reflective essay based around anecdotes. It now feels more grounded and present, more intuitive.” </p>
                <p className="">At Inshriach in October Ukranian-Canadian artist Ayla Dmyterko ‘who completed a Master of Fine Art at Glasgow </p>
                
              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">

                <p className="">School of Art in 2020’ had a similarly affirming experience. “This was my first residency but I grew up in Saskatchewan and spent summers on the farm where my mother was raised so I am comfortable with isolation. Artists need time to think without the distractions of the city.  </p>
                <p className="">The residency helped me to focus. I read three books on colour theory, I borrowed some costumes from Scottish Opera and made a series of films and completed three paintings outside, working with the light and shadows of the forest.” </p>
                <p className="">Not every residency requires a hermit-like existence - although there’s also a pared-back, monastic element to Boath House, a creative bolthole on the Moray coast. Artist and restaurateur Jonny Gent, one of the founders, with architect Russell Potter and chef Florence Knight, of London’s Sessions Arts Club, was introduced to the area by his friend and local resident Tilda Swinton establishing an artists studio in the grounds of a nearby castle. So when the Grade A-listed Georgian hotel came up for sale it was on his radar - and last year became the team’s next project.  </p>
                <p className="">The hotel is set in 22 bucolic acres with orchards, a willow-fringed pond and an ancient walled garden. In its new incarnation the volume has been turned down, the palette softened to soothing neutrals. There are nine clutter-free rooms. The TVs have been taken away, a library added and soundproof listening room with curated collection of records.  </p>
                <p className="">There’s no reception desk, the drawing room has an honesty bar. There are two restaurants, one in a walled garden surrounded by the vegetable beds. Add a wood-fired sauna, artists’ cabins, hammocks and rowing boats and the vibe is more bohemian house party than hotel. </p>
                <p className="">Along with rooms in the main house you can book two ‘studios’. The Drawing Studio is an open-plan space on the banks of the Boath Burn decorated with original artworks, a desk in front of picture windows facing out onto woodland. The Writing Studio, in a 16th-century building in the walled garden, has a vaulted ceiling, woodburner and writing desk for a self-styled retreat. </p>
                <p className="">The concept was to create a remote sanctuary built around the tenets of art, music and food. A halfway house between hotel and creative retreat, multi-disciplinary artists, writers, chefs and musicians are invited to take up residence.  </p>

              </motion.div>

            </div>

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article19-img3.jpg")} />

            <div className="row mb-4">
              <div className="col-6">
                <motion.img variants={textAnimate} className="img-fluid"
                  src={require("../../Images/article19-img4.jpg")}
                />
              </div>
              <div className="col-6">
                <motion.img variants={textAnimate} className="img-fluid"
                  src={require("../../Images/article19-img5.jpg")}
                />
              </div>
            </div>

            

            <div className="row mb-4">
              <div className="col-6">
                <motion.img variants={textAnimate} className="img-fluid"
                  src={require("../../Images/article19-img6.jpg")}
                />
              </div>
              <div className="col-6">
                <motion.img variants={textAnimate} className="img-fluid"
                  src={require("../../Images/article19-img7.jpg")}
                />
              </div>
            </div>

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article19-img8.jpg")} />

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">One of those who made the journey north is Ali Hewson. A graduate of Camberwell College of Art, Ali makes ceramic tableware and vessels inspired by historical objects, handwriting studies and repeated motifs. During her residency at Boath House she worked with the studio manager James to develop a ceramics and sculpture studio. </p>

                <p className="">“We built a sawdust pit kiln and a brick pit kiln to test whether we could make, dry and fire pieces over just a few days. The kilns are in a corner of the walled garden behind the groves of fruit trees. I stayed late into the evenings in the studio hand-building pieces to dry and fire, and burnishing them with stones found on Findhorn beach. We fired the kilns a few times, testing local seaweeds to add flashes of colour to the fired clay. The idea is that guests can make a piece and either fire it or leave it to be fired in the kilns. </p>

                <p className="">“Firing with sawdust is a very immediate way to work. The flames leave marks on the pots, ideally turning them a deep black. I normally work in a refined colour palette, derived from natural oxides and clay colours, so sawdust firing felt like a natural step. It’s lower impact environmentally than larger wood firings and it produced very different results. Burnishing pieces before firing lets the clay take on more of the effects of the flame and smoke, and is in itself a meditative process. I took time with the pieces at a stage where I would normally leave my work for fear of damaging it before it was fired. To handle and burnish it with a stone for a couple of hours was so much more intimate. </p>

                <p className="">“Boath is a special place, the house is so serene and peaceful. There always seemed to be trees on view through the windows, with the most beautiful autumn light on them. The team and all of the other guests were so supportive and friendly and many of them came to watch the firings.”</p>

                <p className="">Art and its creation is also at the heart of another Highland hideaway. The Fife Arms in Braemar, on the eastern edge of the Cairngorms, is owned by Swiss gallerists Manuela and Iwan Wirth and has a constantly changing collection of modern art - and bespoke activities designed for guests including wild sketching classes, landscape photography and creative writing workshops.  </p>
                <p className="">Over the past few years, a number of artists have been invited to take up residency here including Alec Finlay who created ‘Gathering’, a guide to the Cairngorms in poems, essays, photographs and maps, and Gideon Summerfield, </p>
                
              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">

                <p className="">a graduate of the Royal School of Drawing in London, who created a series of portraits of Braemar’s residents which now hang in The Flying Stag bar. </p>
                <p className="">“I’ve lived most of my life in London,” Gideon explains, “and so spending six months in a small Scottish village, becoming part of the community, was a new experience. Along with the portraits I also made 50 or 60 landscape drawings. I draw from life and so being out in the wilderness, studying the landscape and the light was a form of therapy.” </p>
                <p className="">The landscape of Eilean Shona, the rugged 2,000-acre island retreat owned by Vanessa Branson also inspires and nurtures  – and artists and writers have long played a part in its cultural make-up. As well as retreats and workshops, each year a sculptor is chosen for a month-long residency in association with the Royal Society of Sculptors. </p>
                <p className="">There are no roads, just paths threading the rocky coast and smattering  of self-catering cottages, some off-grid, lit by gas lamps, together. J.M. Barrie summered here in the 1920s when he was writing the screenplay for Peter Pan – and found his Neverland.   Other writers who have crossed the choppy waters of Loch Moidart to the island include Amy Liptrot, Polly Devlin and Freya North.  </p>
                <p className="">“There’s a magic to Eilean Shona,” Vanessa explains, “a wild, adventurous sense of place that we try to harness in our writing retreats, to fire the imagination of the next generation of writers.” </p>
                <p className="">It’s an otherworldly environment, cut off from the world. If any place can feed, fire and inspire creativity it’s this mist-cloaked, salt-soaked, real-life fantasy island.  Alone, but for wheeling gulls, slumbering seals, otters ducking and diving in the waves, the coast is clear for the muse. </p>

                <motion.div variants={textAnimate} className="bg-gold p-4 mt-5">
                  <p className="mb-0">
                  Bothy Project bothyproject.com
                  <br/>Ayla Dmyterko ayladmyterko.com
                  <br/>Boath House boath-house.com
                  <br/>Ali Hewson alihewson.co.uk
                  <br/>The Fife Arms thefifearms.com
                  <br/>Gideon Summerfield gideonsummerfield.com
                  <br/>Eilean Shona eileanshona.com
                  </p>
                </motion.div>

              </motion.div>

            </div>

          </div>
        </div>
        </div>
      </motion.div>
      
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>

    </motion.div>

  );
}

export default Article19;
