import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Article22 = () => {
  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article1');
      myFunction();
    }, 2000); // Delay of 2000 milliseconds (2 seconds)
    
  };
  

  const myFunction = () => {
    // Perform any actions you need here
    console.log('Function called after delay');
    
  };

  


  return (
    <div>
      <button onClick={handleClick}>Add Class</button>
      <div className={addClass ? 'my-class' : ''}>
        Test
      </div>
    </div>
  );
};

export default Article22;

