import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article17() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });



  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';

      return "";
    }

  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article18');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article16');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article17-img1.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article17-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div variants={textAnimate}>
              <h1 className="fs-xl fw-normal mb-2">Baern Café</h1>
              <h3 className="fs-md font-italic mb-4">Our daily bread</h3>
            </motion.div>
            <motion.p className="mb-5 font-italic" variants={textAnimate}>In the old grain store on the working Balcaskie estate in the East Neuk of Fife, local produce becomes an endlessly creative medium in the hands of Hazel Powell and Giacomo Pesce. Changing with the seasons, their café Baern stands for sustainability, zero waste and really good bread.</motion.p>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">“We’re just two people who really love good food and feeding people,” says chef Hazel Powell, who last year launched Baern Café in the Bowhouse food production hub in St Monans in the East Neuk of Fife with her friend and business partner Giacomo Pesce. A glance at the menu confirms this. It is small, changes weekly, and lets the ingredients do the talking: bacon brioche, kohlrabi kimchi, haggis sausage rolls and slow-cooked beef ragu. Then there’s Baern’s signature sourdough: “We’re incredibly proud of it,” says Hazel. “Giacomo lives and breathes the sourdough; every day he tweaks something to try and improve it.”</p>
                <p className="">The bread, along with all Baern’s dishes, preserves and ferments, is made “entirely from scratch” in the open kitchen of the café, which occupies the old grain store at Balcaskie, the working estate where Bowhouse is situated. A big open space, “it still nods to its grain store roots”, with exposed beams, neutral colours and an enormous picture window overlooking the estate. Then there’s “a cavernous upstairs, to which we’ve just had a mezzanine added, so you can see out over the Forth to Bass Rock. It’s pretty magical”. </p>
                <p className="">Bowhouse was set up to connect small growers and producers with restaurants and shoppers, so Hazel and Giacomo are surrounded by like-minded food creatives and can source everything they need for their weekly menu from their neighbours. The flour for their bread is from Scotland The Bread, which grows small-scale heritage grains “for nutritional value rather than for yield or profit”; their vegetables are nurtured by Tom and Connie at the East Neuk Market Garden; and “the fish landed in St Monans is incredible”.</p>


              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">

                <p className="">Hazel, originally from Glenfinnan, fell in love with the place after helping out her friend Jess Rose Young, who had a pop up café in Bowhouse the year before – “I just couldn’t get it out of my head,” says Hazel, who has a fine art background but converted to cookery at Ballymalloe Cookery School in Ireland before joining the team at Ballintaggart in Perthshire. She met Giacomo while working at the restaurant Fhior in Edinburgh. Originally from Treviso, just north of Venice, he had arrived at baking via a PhD in mineral physics and a career in fine dining. </p>
                <p className="">The pair realised they shared an ethos when it came to food, one centred around seasonality, environmental values and zero waste: Baern’s leftovers get used somehow, be it kimchi juice in the sourdough or corn on the cob from the soup to make a sweetcorn and tarragon lemonade. “To us food is about community, time and place,” muses Hazel. “It’s an endlessly creative medium, changing with the seasons and constantly evolving.” </p>
                <p className="">They recently launched seasonal evening sessions “with candlelight, great tunes and a really fun à la carte menu”, plus organic wines from Futtle next door. She says that when they thought up Baern, they wanted it to be a space that became part of the community, a space where people could meet for a conversation and take home their weekly loaf. </p>
                <p className="">Is there one thing that visitors must try? “Our sausage rolls are pretty special I think,” smiles Hazel. Just now they’re rabbit and venison from the estate grounds, with mulled wine, and an incredibly old variety of apples from the estate’s orchard. “And always the bread.”</p>

              </motion.div>

            </div>


          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article17;
