import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article1() {

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0
  });
  const { ref: fourContainer, inView: fourInView } = useInView({
    threshold: 0
  });




  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img4 = document.getElementById("img4");
      img4.className = '';
      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img4 = document.getElementById("img4");
      img4.className = '';
      return "";
    }
    else if (thirdInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = 'active';
      var img4 = document.getElementById("img4");
      img4.className = '';
      return "";
    }
    else if (fourInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img4 = document.getElementById("img4");
      img4.className = 'active';
      return "";
    }
  };



  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 3
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article2');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()}  */}
          <img id="img1" className="active" src={require("../../Images/article-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article-img9.jpg")} />
          <img id="img3" className={getAnimationClass()} src={require("../../Images/article-img10.jpg")} />
          <img id="img4" className={getAnimationClass()} src={require("../../Images/article-img7.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article-img1.jpg")} alt="" />
          </motion.div>
          <motion.div
            variants={textAnimate}
            className="hamburger-menu">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
        <div className="article-mob-wrap">
          <div className="mb-5" ref={firstContainer}>
            <div
              variants={textAnimate}
            >
              <h3>THE JOY OF THE SEARCH</h3>
              <h5 className="fw-bold">In Search of Otters on Islay</h5>
            </div>
            <h6 className="font-italic" variants={textAnimate}>word - Ben Lerwill & photography - Jack Anstey & Sarah Afiqah Rodgers</h6>
            <p className="text-blue fw-semibold" variants={textAnimate}>It's 6.15pm in late summer, and the sea loch is like silk. A single boat putters across the water, a dad and lad on an evening fishing trip. The rest of the scene is quiet. The opposite shore is a distant rumple of olive-green hills. It's jumper weather, but the low sun on my face feels warm. Far off, a skein of geese flies from east to west. Islay is winding down for the day.</p>
            <div variants={textAnimate} className="short-divder"></div>
            <p variants={textAnimate}>I'm sat on the foreshore of Loch Indaal, hoping for an otter. This happens a lot when I'm on the islands. There's pleasure to be had in lingering, waiting, looking. Your eye gets drawn by the quiet rock-hop of a wheatear, or the sky-tilt of a gannet. You stare, and you marvel a little, then it's back to watching the shoreline. At times I find myself scanning the bays without knowing I'm doing it, searching for the furtive swim-and-flip of the creature that beguiles me more than any other</p>
            <p variants={textAnimate}>Lutra lutra. The Eurasian otter. It besots people for fun. Nature writer Robert MacFarlane
              calls it “a shape-shifter… a supple slider out of holt and into water”. For poet Liz Brownlee
              it's a “quicksilver whizz of bubbles and fizz”. In 2020, the National Trust of Scotland held a
              poll to find the nation's favourite animal. Some 60,000 votes were cast and the otter took
              top spot, though it was far too shy to accept the praise, and in fact far too busy skittering
              out of sight to give it any thought at all. Point out an otter and it's usually gone by the time
              you raise your finger.</p>

            <p variants={textAnimate}>But the species thrives here. Between the 1950s and the 1970s, when pesticides sullied the
              waterways in England and Wales, otters found a stronghold north of the border, where
              the seas and rivers provided a cleaner, wilder habitat. According to NatureScot, the otter
              is today flourishing across Scotland, with a population in the region of 8,000. This is
              cheering, though when you factor that figure against the vast dimensions of the coastline,
              it explains why the creature is so notoriously elusive to spot.</p>
            <p variants={textAnimate}>But the species thrives here. Between the 1950s and the 1970s, when pesticides sullied the
              waterways in England and Wales, otters found a stronghold north of the border, where
              the seas and rivers provided a cleaner, wilder habitat. According to NatureScot, the otter
              is today flourishing across Scotland, with a population in the region of 8,000. This is
              cheering, though when you factor that figure against the vast dimensions of the coastline,
              it explains why the creature is so notoriously elusive to spot.</p>
            <p variants={textAnimate}>As the sun sends its last blood-red rays over the loch and the insects get too much, I get to
              my feet and walk back along the shore into Port Charlotte, to the whisky burble of the hotel
              bar. No otters. Not today. This is my fourth night on the island and I've been lucky with
              the wildlife. I've traced a hen harrier scything over the hills, watched mottled seals in their
              dozens, seen great flocks of sanderlings gusting along the foreshore. On my first evening
              I'd even passed a lone red deer fawn, sat on the grass just feet from where the waves lapped
              the rocks. But no semiaquatic mustelids.
            </p>
            <p variants={textAnimate}>In my experience, that's how it goes with otters. They either don't emerge at all or they
              appear when you're not looking for them. Few other animals are so frequently described as
              reclusive or withdrawn. Even bang in the middle of an otter's territory you're more likely
              to spot forensic clues - pawprints, slide marks, little piles of fishy spraints - than an actual,
              fur-and-whiskers otter. They can be merciless predators, but they venture into the open
              on their own terms.</p>
          </div>

          <div className="secondSec" ref={secondContainer}>
            <img variants={textAnimate}
              src={require("../../Images/article-img8.jpg")}
              className={"w-100 mb-5"}
              alt=""
            />
            <br />
            <p variants={textAnimate}>This timidity around humans is one of the main reasons they have such a hold over people. To catch sight of an otter in the wild is an event. There's something in the way they move, and the way that, despite their broad muzzles and almost comically short legs, they seem precision engineered for both land and water. An otter is evidence that a creature only slightly bigger than a housecat can dominate a horizon-wide view, simply by being there.</p>
            <p variants={textAnimate}>I remember with a thrill the first time I saw one up close in the wild. I'd travelled to Unst, at the top of the Shetland archipelago, and I was shattered after spending three days walking around its coast. I'd barely passed a soul. I was hungry, and my clothes were damp, and I'd been repeatedly dive-bombed by great skuas, whose hidden ground-nests I kept inadvertently approaching.</p>
            <p variants={textAnimate}>But as I slogged through the final couple of miles, something happened. The weather, by some meteorological quirk, passed from overcast to glorious in the space of a few minutes. Where there had been greyness, now there was limpid sunshine; the neighbouring islands burned green in the pre-dusk light. And as I stared out from a low clifftop, I caught a movement in the water below. A shimmy among the rocks, a half-flicker that appeared, then vanished, then came into focus again. An otter.</p>
            <p variants={textAnimate}>I stopped myself from shouting out loud. For about 15 minutes I hunkered and watched it in motion, diving and bobbing, fishing and preening. It flowed and fussed through the clear shallows, twice bringing a catch into the inlet to devour it. Its nostrils were flared, its coat was spiked, its tail was thick. It was hungry and it was here, off a lonely corner of a lonely island, unannounced and real. I was close enough to hear it eating. The sighting sent my spirits soaring, recasting the whole three days as an adventure rather than an ordeal, pushing to the fore the giddying splendour of where I was. In its own way, the encounter was transformative.</p>

          </div>
          <div className="mb-5" ref={thirdContainer}>
            <p variants={textAnimate}>Back to late summer on Islay. The bus network here is basic but reliable, which lets me busy my days on foot, not just in search of wildlife but as a means of feeling my way around the island. Near Port Ellen I wander between distilleries, sampling peaty drams of Laphroaig and Lagavulin, clambering up into the ruins of Dunyvaig Castle for a windswept sandwich as the sun arcs overhead. At Bowmore I trek out past barnacled lobster pots to the coast, watching a heron flying low and hard, following its own shadow over the land. And stymied by the bus times, I end up hitching a lift down to beautiful Portnahaven, where I buy a slice of upside-down cake from an honesty box café and sit and watch the bay. Ultimately, you see, it always comes back to watching the bay.</p>
            <p variants={textAnimate}>I can count on two hands the number of otters I've seen in the years since Unst. I think especially of Mull, where I once had an unfeasibly fortunate week of sightings - including a glorious show of rock-feasting near the remote Carsaig Arches - and of the Outer Hebrides. But far more often there have been times of no-otter, days when you convince yourself that somewhere a pair of beady mammalian eyes must be watching you and staying hidden: coastal hikes where I've yearned for a sleek, busy head to materialise but seen nothing.</p>
            <p variants={textAnimate}>Except that on a Scottish island, there's no such thing as nothing. Even if an otter is the aim, its non-appearance is always gobbled up by the drama and detail of the setting. Slanting headlands and booming sea caves; puffins and razorbills; soft beaches and craggy outcrops; machair dunes and porpoises; brown hares and burnet moths; open moorland and sea campion. In many ways, once your senses are on alert and you're noticing the little things, all that matters is the joy of the search.</p>
            <p variants={textAnimate}>On a quiet island road a mile outside of Portnahaven, with green fields and hillocks all around, I walk past a house with a painted shipping container in its garden. It turns out to be a shop of sorts, named Islay Celtic Craft. The owner, Mairi, is sitting outside in the sun. We chat for a minute before I realise she has a duckling at her feet. “The egg had been left. We had to hatch it on the stove,” she says, concern in her voice. “But it's not growing as fast as it should. It's a wee ditherer.”</p>
            <p variants={textAnimate}>The shop is an Aladdin's Cave of handmade leather and reworked natural materials. My attention is grabbed by the heavy, sea-rounded stones which Mairi has gathered, then painted with delicate images of local wildlife. There are eagles, deer, and curlews. One shows an otter surrounded by sea kelp, staring out at the viewer, ruffled but defiant. The same stone is in front of me now, as I write at home: my own otter totem, direct from the islands. It's beautiful, and very cold to the touch.</p>
            <p></p>
          </div>
          <div className="" ref={fourContainer}>
            <img
              src={require("../../Images/article-img2.jpg")}
              className={"w-100"}
              alt=""
            />
            <br /><br />
            <div className="row mb-4">
              <div className="col-sm-6">
                <img
                  src={require("../../Images/article-img11.jpg")}
                  className={"w-100"}
                  alt=""
                />
              </div>
              <div className="col-sm-6">
                <img
                  src={require("../../Images/article-img12.jpg")}
                  className={"w-100"}
                  alt=""
                />
              </div>
            </div>
            
            <img
              src={require("../../Images/article-img13.jpg")}
              className={"w-100 mb-5"}
              alt=""
            />
            

            <p>On my final morning on Islay I haul myself out of bed at 6.30am for a pre-breakfast walk, returning to a spot where I've already spent hours with my binoculars, at the base of the squat lighthouse north of Port Charlotte, overlooking Loch Indaal. I'm heading back not so much for the prospect of finally seeing an Islay otter, but in the knowledge that I'll be happy while I'm sitting there.</p>

            <p>From a certain distance, lots of things look like an otter. Rocks that peek above the swelling tide. Diving cormorants. Dark clusters of seaweed that sway on their roots. Seals that periscope their heads above the water's surface. Each one of these makes the heart skip a little faster until - ah, no. And so I sit there, looking out at the loch as the sun rises through the clouds. Way out on the water, a plunge of gannets is on the hunt.</p>

            <p>And then. After 20 minutes or so, off to my left, I notice the skim of a silhouette. It's some fifty feet away, just offshore. Even peering at it through my lenses, it takes me a few seconds to be sure that what I'm seeing is what I think I'm seeing. A head, a back, a tail, an acrobat's rapid tuck to vanish underwater. Fast-fading ripples. Unmistakably, impossibly, an otter.</p>

            <p>The next half-hour is heady. I stay seated, fixed as stone, as the animal swims through the morning, twisting itself down into the sea loch again and again. Sometimes at a distance, sometimes tremblingly close, a fish-slayer on a steady, zigzagging dawn patrol. Eventually it catches a meal - something long and spiny - and drags it onto a rock to gnaw. Then it slips back into the water to continue its work. Soon it swims directly into the glitter beneath the rising sun and I have to jolt my gaze from the glare.</p>

            <p>When I recover myself, the otter has disappeared and the sea loch is calm. Gulls cry overhead. And I'm back where I was, where all that matters is the joy of the search.</p>

          </div>
        </div>
        </div>
      </motion.div>

      <div className="btns-group">
        
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>

    </motion.div>

  );
}

export default Article1;
