import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article15() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });



  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article16');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article14');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article15-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article15-img2.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article15-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div variants={textAnimate}>
              <h1 className="fs-xl fw-light mb-5">What Happened <br />to the Picts?</h1>
            </motion.div>

            <div className="row mb-5">
              <div className="col-6">
                <img className="img-fluid"
                  src={require("../../Images/article15-img3.jpg")}
                />
              </div>
              <div className="col-6">
                <img className="img-fluid"
                  src={require("../../Images/article15-img4.jpg")}
                />
              </div>
            </div>

            <motion.p className="" variants={textAnimate}>Scotland has a fascinating history. From mysterious ancient megaliths to bloody clan feuds, there are plenty of chapters to get lost in. There’s one period with more unanswered questions than most though, surrounding a population who became known as Europe’s Lost People. They were the Picts, with a territory that once stretched from the northern isles to Fife.</motion.p>

            <motion.p className="" variants={textAnimate}>They left behind intricately carved stones full of indecipherable symbols to remind us of their presence but very little else. Their language has disappeared, their culture been forgotten and by the 10th century no more iconic carvings were being created. So, what happened to the Picts?</motion.p>

            <motion.p className="mb-5" variants={textAnimate}>Before searching for their fate, it’s important to find out who the Picts were. Unfortunately, because they didn’t leave us any written evidence, almost everything we know about Scotland’s most mysterious inhabitants comes from what others have written about them and that includes their name.</motion.p>


          </div>

          <div className="" ref={secondContainer}>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">Pict comes from the Roman word “Picti” meaning painted people, believed to be a derogatory term used by soldiers for the people they encountered in the north of Britain. It was a way of provoking thoughts of wild and savage barbarians, Imperial propaganda at its best. The collection of tribes that the Romans faced certainly wouldn’t have referred to themselves as Picts, but by the 4th century a more cohesive unit appeared and the Pictish Era had begun.</p>
                <p className="">Scotland has an unrivalled ability to fill any historical gaps with stories. Legend attributes the origins of the Picts to a King called Cruithne who ruled Pictland for 100 years. As his reign was coming to an end, he split the enormous Kingdom between his seven sons and their names can still be found in several of the regions we know today.</p>
                <p className="">Some are easily identified, such as Fib now known as Fife, Cait as Caithness or Fotla as Atholl, but Fortrenn, Cirig, Fidach and Ce are slightly harder to place. The myth of all these Pictish territories springing from a solitary figure shows an attempt to identify the Picts and those who came after them as a unified people.</p>
                <p className="">The most enduring evidence the Picts left behind are dozens of intricately carved symbol stones found scattered across Scotland. Early examples known as Class 1 stones appear as rough boulders, displaying symbols such as Z-rods, crescents, mirrors and beasts. The meanings behind the icons are still being debated today.</p>
                <p className="">Before long, Christianity spread around Pictland and their art began changing to reflect that. The Tarbat Discovery Centre tells the story behind a monastery at Portmahomack, dating from around 550AD and full of Pictish stones carved with Christian symbols. Found nearby, the Shandwick Stone and reproduction of the Hilton of Cadboll Stone are incredible examples of what are known as Class 2 stones, with Pictish symbols covering one face and an enormous Celtic Cross on the reverse.</p>
                <p className="">Some of these carved stones were designed to tell a story. In Aberlemno Kirkyard, mysterious</p>

              </motion.div>

              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">“Pictish Beasts” flank an enormous cross, while on the reverse, a Z-rod and Crescent stand above a battle scene. It’s believed this commemorates the Pictish victory over an invading Northumbrian army at the Battle of Dun Nechtain.</p>
                <p className="">Finally, towards the end of the Pictish era, we find Class 3 stones without any sign of pagan symbolism, evidence of a drastic shift in culture. Each stone is covered in purely Christian iconography, although that doesn’t make them any less impressive. One of these later creations, Sueno’s Stone in Forres is a gargantuan 6.5 metres tall, weighing over 7 tonnes. On the other side of the prominent cross, we find a brutal scene full of warriors and piles of decapitated heads.</p>
                <p className="">These battle scenes are an important reminder that the Picts weren’t the only people who called what we now know as Scotland home. As well as constant pressure from Northumbrians, the independent Kingdom of Strathclyde stood to the southwest and the Scots of Dalriada were growing in power to the west.</p>
                <p className="">The most important location in Dalriada was at Dunadd Hill in Argyll, the centre of a Kingdom that stretched along the west coast of Scotland and across the Irish sea. This was where their Kings were crowned, with a footprint carved into stone believed to be part of ancient inauguration ceremonies. It wasn’t an impregnable fortress though and in the 8th century, the Pictish King Angus won a great battle and breached Dunadd’s walls.</p>
                <p className="">Conflict between the Picts and the Scots was a regular occurrence, but as the centuries passed, so was marriage. Many of the later Kings could boast a parent from both sides of the border and suggests these two cultures were beginning to assimilate. There would soon be a bigger problem for both of them to deal with, as the middle of the Pictish era saw the beginning of the Viking Age.</p>
                <p className="">These Scandinavians arrived with a bang, appearing out of nowhere to slaughter and plunder, then sailing away before anybody could react. The Picts lost ground in the north and the </p>
              </motion.div>
            </div>


            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article15-img5.jpg")} />
            <motion.img variants={textAnimate} className="img-fluid mb-5" src={require("../../Images/article15-img6.jpg")} />


            <motion.p className="" variants={textAnimate}>monastery at Portmahomack saw those beautiful Pictish crosses smashed into pieces. In the west, the islands inhabited by Scots were particularly vulnerable to attacks from longships.</motion.p>
            <motion.p className="" variants={textAnimate}>This new threat was squeezing every area of the Scottish coast, not just the Picts and Scots but the Britons of Strathclyde as well. In the 9th century, they overcame the fortress on Dumbarton Rock and now these Vikings intended to stay. Amongst the incredible collection of carved Govan stones are five enormous hogbacks – graveslabs shaped like Scandinavian longhouses.</motion.p>
            <motion.p className="" variants={textAnimate}>In 839, an enormous Viking army succeeded in defeating a joint force of Picts and Scots, killing both Kings in the process. Kenneth MacAlpine soon became ruler of the Scots of Dalriada and saw an opportunity to take advantage of his weakened neighbours to the east. The historical records are murky, but it’s said that Kenneth won a huge battle and declared himself as also King of the Picts.</motion.p>
            <motion.p className="mb-5" variants={textAnimate}>Centuries after his reign, in an attempt to answer the question of what happened to the Picts, a legend arose known as the Treachery of MacAlpine. It claims that the new King invited all the Pictish nobility to a feast at Scone to heal old wounds. It was a trap. Once the Picts were drunk, Kenneth’s men pulled pins out of their benches, causing them to fall into pits of deadly spikes!</motion.p>
            <motion.p className="mb-5" variants={textAnimate}>In reality, the Pictish people weren’t simply destroyed in one fell swoop and Kenneth’s immediate successors even called themselves Kings of the Picts. However, in the background things were changing. With the power of the written word and the Gaelic Church, the Scots of Dalriada were becoming dominant in this new unified Kingdom known as Alba.</motion.p>
            <motion.p className="mb-5" variants={textAnimate}>The monks writing the chronicles had no need to use the word Pict anymore and it suddenly stops appearing in the records. It seemed as if the Picts had just disappeared overnight when really, they had merged into a larger nation which could better defend itself.</motion.p>
            <motion.p className="mb-5" variants={textAnimate}>We may have lost the knowledge of their language, culture and symbols, but the Picts didn’t go anywhere. They were right at the heart of a united Scotland and their descendants are still here.</motion.p>




          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article15;
