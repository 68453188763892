import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article8() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article9');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article7');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article8-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article8-img2.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article8-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div variants={textAnimate}>

              <h1 className="fs-xl fw-normal mb-2">Through <br />Eyrie Woods </h1>
              <h4 className="mb-5">Golden Eagles at Glen Tanar</h4>
            </motion.div>
            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article8-img3.jpg")} />
            <div className="row mb-5">
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>Scotland has a beautiful diverse natural landscape that is home to some incredible wildlife. Watching an otter feed at low tide for butterfish on the weathered western shores, or catching a glimpse of a noble red stag roaring in an ancient pine forest are incredible sights to behold. Moments like these, which allow us to gaze into the intimate lives of Scotland’s wild inhabitants, are breathtaking.</motion.p>
                <motion.p className="" variants={textAnimate}>However, for one of Scotland’s most famous, yet secretive residents, sightings like these are few and far between. This is a story about Scotland’s top aerial predator – the golden eagle - and the Eyrie woods which some of them call home.</motion.p>
                <motion.p className="" variants={textAnimate}>If you have ever been lucky enough to see one soaring in the sky far above your head, then you are indeed very fortunate. Perhaps you even briefly mistook them for a glider in the wind if not for the occasional beat of their wings and the silhouette of their dark brown plumage setting them apart.</motion.p>
                <motion.p className="" variants={textAnimate}>These magnificent birds of prey have an impressive king-sized wingspan but, despite their imposing size, they manage to lead relatively undisturbed and secretive lives </motion.p>

              </div>
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>in Scotland’s remote glens and bleak, but beautiful, craggy coastal cliffs.</motion.p>

                <motion.p className="" variants={textAnimate}>Nearly all the UK’s golden eagle population of just over 400 pairs call the Scottish Highlands home. Having surveyed and searched the landscape for the most suitable territories, these giants of the sky make their homes in those places they hold in highest regard. The population is denser in the West of Scotland where the appealing nature of the rugged and relatively uninhabited landscape has proven to be a determining feature.</motion.p>
                <motion.p className="" variants={textAnimate}>When settled, these magnificent birds of prey patrol their territories, which can be hundreds of kilometres wide, searching for food and protecting their hunting grounds. Despite the sheer scale of their territories the golden eagle does, however, lead a relatively slow and quiet life, generally mating for life, which on average lasts around 20 years.</motion.p>
                <motion.p className="" variants={textAnimate}>Fully mature female golden eagles are larger than their male counterparts. Both the male and female are skilled predators and competent part-time scavengers. Hunting usually takes place in open ground where they fly</motion.p>
              </div>
            </div>

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article8-img4.jpg")} />
            <div className="row mb-5">
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>unimpeded. They are not overly fussy about their food; they prey mostly on small animals like mountain hares, grouse, and seabirds on the west coast. However, they are known to occasionally take larger animals such as foxes and small deer which are not beyond the clasp of their talons. They regularly reach speeds of up to 150mph when hunting and it is an impressive and rare sight to catch a glimpse of an eagle plunging towards the ground.</motion.p>
                <motion.p className="" variants={textAnimate}>Mating pairs build nests in the most inaccessible refuges such as rocky cliff faces and high in the tops of impenetrable trees, carefully selected to provide a haven until their chicks fledge. Choosing and protecting their chosen nesting site is fundamental to the species’ survival.</motion.p>
                <motion.p className="" variants={textAnimate}>Although they are often considered one of our better-known birds of prey, there are just 20 pairs of tree-nesting golden eagles in the UK, all of whom have chosen Scotland as their home.  These tree-nesting pairs have selected dense forest locations that provide a shelter and sanctuary from which to raise their chicks. The surrounding forest provides them with a bountiful larder of prey and ample building material for their nests.</motion.p>
                

              </div>
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>Each eagle territory contains several night-time roosts and often a choice of two or three nest sites. These sites, called eyries, are situated high up amongst the cover of the forest canopy or on cliff ledges. Male and female birds work together gathering branches, twigs and heather to build their nests with, adding new material every single year.</motion.p>

                <motion.p className="" variants={textAnimate}>Giant in size, these nest sites are rotated every year and are often used by successive generations of eagles to rear their  young. In Scotland the largest known nest, discovered in 1954, was 45 years old and 4.6 metres deep. Despite their sheer scale, golden eagle nests are exceedingly hard to locate and even harder to film. So rare are sightings of these extraordinary and elusive golden eagles that there is still much to be learnt about their behaviours and family lives.</motion.p>
                <motion.p className="" variants={textAnimate}>In March 2023, the BBC gave us unprecedented access into the lives of one of these maverick pairs of golden eagles in their series ‘Wild Isles’ presented by Sir David Attenborough. The creation of this film was a result of close collaboration over a number of years. What resulted was the capture of some outstanding and rare footage.</motion.p>
                
              </div>
            </div>

          </div>

          <div className="" ref={secondContainer}>
            

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article8-img5.jpg")} />
            <div className="row mb-5">
              <div className="col-sm-6">
                <h5 class="mb-2">The Setting - Glen Tanar Estate</h5>
                <motion.p className="" variants={textAnimate}>Stretching across 25,000 acres of heather-clad hills on the eastern edge of the Cairngorms National Park lies Glen Tanar Estate. Since its very beginnings, Glen Tanar has been inviting people to enjoy the great outdoors whilst balancing wildlife conservation with sustainable land management. The estate includes a 5,400-acre National Nature Reserve and one of Scotland’s last remaining remnants of the ancient Caledonian Pine Forest, a forest that once covered much of Scotland. The estate is home to some of Scotland’s most renowned species, including one of the 20 tree-nesting pairs of golden eagles in the UK.</motion.p>
                <h5 class="mb-2">Filming Golden Eagles</h5>
                <motion.p className="" variants={textAnimate}>With so few pairs remaining in the UK, golden eagles are a heavily protected species. Sensitivity to disturbance and human interference means that a licence is required to film them and only a few carefully selected people and industry experts are granted these licences each year. A collaboration between Glen Tanar Estate, Silverback Films and Colin McClean was awarded a licence to film and photograph these magnificent birds on the estate.</motion.p>
                <motion.p className="" variants={textAnimate}>Filming activity began during the winter breeding period as the mating pairs started to gather new nesting material.</motion.p>
                

              </div>
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>Whilst the birds busily gathered vegetation, Sudanese-British wildlife cameraman and presenter, Hamza Yassin, was hard at work trying to locate the eagles’ selected nest site from over one million trees.</motion.p>

                <motion.p className="" variants={textAnimate}>This mammoth undertaking involved countless hours of sifting through heavy forest canopies, scrutinising the treetops through binoculars. Finally, after several days, copious amounts of caffeine and methodical searching, the eagles’ secretive nest site was found. A beautiful large eyrie, 1.5 metres deep, resting high up in a Scots Pine tree in a remote corner of the estate.</motion.p>
                <motion.p className="" variants={textAnimate}>Over the following months the nesting eagles’ behaviour and activity was monitored, allowing a rare insight into the creation of their home. By March, the manufacture of their nest - using bones, branches, twigs and heather, lined with woodrush and grass and decorated with green foliage - was complete. This provided a perfect platform from which to breed, all encouraging signs for a successful year.</motion.p>
                <motion.p className="" variants={textAnimate}>In early spring Colin McClean and Glen Tanar Estate wildlife manager, Adam Smith, delivered the exciting news that the female had laid two eggs. Careful non-intrusive monitoring throughout the six-week incubation period</motion.p>
                
              </div>
            </div>
            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article8-img6.jpg")} />

            <motion.div variants={textAnimate} className="row mb-4">
              <div className="col-6">
                <img className="img-fluid"
                  src={require("../../Images/article8-img7.jpg")}
                />
                <p className="mt-2 mb-0 fs-sm fw-semibold">Hamza Yassin</p>
              </div>
              <div className="col-6">
                <img className="img-fluid"
                  src={require("../../Images/article8-img8.jpg")}
                />
                <p className="mt-2 mb-0 fs-sm fw-semibold">Colin McLean</p>
              </div>
            </motion.div>
            <img className="img-fluid" src={require("../../Images/article8-img9.jpg")} />
            <p className="mt-2 mb-5 fs-sm fw-semibold">John Aitchison & Lily Moffat</p>
              
            <h2 className="fw-light font-italic mb-3">“The footage captured of the eagles was jaw dropping and beyond all our wildest dreams.”</h2>
            <p className="">revealed the successful hatching of both chicks. Over the next ten weeks the chicks were nurtured by their parents, being drip fed a constant supply of good food. In June 2022, when their fledgling flight was felt to be fast approaching, the production team returned to try and capture the moment the eagle chicks took to the skies for the first time.</p>
            <p className="">Thrilled that both chicks had flourished in the nest, as golden eagles often only manage to rear one chick successfully, the odds of capturing the moment of the first fledgling flight were higher than expected and an air of anticipation was evident within the team.</p>
            <p className="">Wildlife filmmaker John Aitchison, who was tasked with trying to capture the first flight on film, described spending days in a remote camera hide, from dawn till dusk, as an extraordinary privilege. Being permitted a personal window from which to film the daily lives of such an uncommon pair of golden eagles and their two chicks provided a uniquely special insight into the world of this reclusive family.</p>
            <p className="">As is commonly accepted by wildlife filmmakers, animals never quite behave the way you want them to, and John knew to expect the unexpected. However, although their first flight eluded the filmmaker, the curious behaviour of the eaglets that he captured could not have been predicted.  Quite extraordinarily, John filmed the pair leaving the nest and taking short excursions by climbing down, branch by branch, to the forest floor and climbing back up again.  Coupled with the footage filmed of the eagles in the nest, this presented a rare glimpse into the early months of the eaglets’ lives and acted as a reminder that there is still much to be learnt about these magnificent birds.</p>
            <p className="">BBC wildlife shows are renowned for their spectacular imagery and jaw-dropping moments and the footage captured of the eagles in Glen Tanar was beyond all our wildest dreams.</p>
            <p className="">Golden eagles play a crucial role in the balance of our Highland habitat. By tracking their progress, filming their behaviours, and monitoring how they interact with our landscape, we hope to better understand them.</p>
            <p className="">There is no doubt that Glen Tanar sits in the heart of one of Scotland’s oldest and most majestic landscapes. To have a mating pair of golden eagles – surely the most majestic of all our aerial predators - call the estate “home” is an honour to cherish, and one which will hopefully continue for many generations to come.</p>

            
          </div>
        </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article8;
