import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article11() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });




  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';

      return "";
    }

  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article12');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article10');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article11-img1.jpg")} />
          {/* <img id="img2" className={getAnimationClass()} src={require("../../Images/article10-img4.jpg")} /> */}
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article11-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
<div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <h4>exploring the smaller</h4>
            <h1 className="fs-xl fw-light mb-2">MUSEUMS <br />& GALLERIES </h1>
            <h4 className="mb-5">of Edinburgh.</h4>

            <motion.p className="mb-5" variants={textAnimate}>Edinburgh has a host of wonderful museums and galleries to choose from. Many will be familiar with the well-loved National Museum of Scotland with its range of engaging exhibitions, as well as the trio of National Galleries of Scotland which house Scotland’s impressive national collection of fine art. There is also a collection of smaller yet equally fascinating museums and galleries which are well worth a visit; here are just a few to keep in mind.</motion.p>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <img className="img-fluid mb-4"
                  src={require("../../Images/article11-img2.jpg")}
                />
                <h4 className="fs-md mb-2">St Cecilia’s Hall & Music Museum</h4>
                <h5 className="mb-4">50 Niddry Street</h5>
                <p className="mb-4 mb-sm-0">Tucked away just off Cowgate is Scotland’s oldest purpose-built concert hall, St Cecilia’s Hall. At the heart of the Georgian building is an outstanding elliptical concert hall which was beautifully restored by the University of Edinburgh. The concert hall is complemented by four galleries and a conservation studio with a large glass window allowing visitors a glimpse into the conservation work. The museum houses the University’s collection of historic musical instruments, which consists of over 500 rare instruments from across the globe with a particular focus on instruments that are no longer in regular current use dating from the 16th century. Many of the museum’s instruments are still played as part of the concert hall’s programme, making this the only place in the world where it is possible to listen to 18th-century music in an 18th-century concert hall played on 18th-century instruments. Don’t be put off if you don’t play an instrument, it’s still a fascinating visit!</p>
              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">
                <img className="img-fluid mb-4"
                  src={require("../../Images/article11-img3.jpg")}
                />
                <h4 className="fs-md mb-2">Collective</h4>
                <h5 className="mb-4">Calton Hill</h5>
                <p className="mb-0">Calton Hill has a fascinating history as the birthplace of astronomy and timekeeping in Edinburgh. The City Observatory once operated as a site for scientific innovation and discovery, as well as providing accurate time for sailors. In 2018, Collective sympathetically incorporated a beautiful contemporary art gallery into the historic City Observatory and City Dome. The gallery is spread out over the site with an exciting programme of contemporary art exhibitions taking place within the restored City Dome as well as a purpose-built exhibition space and the grounds. Alongside the contemporary art are clues to the site’s rich history, such as the two-faced ‘politician’s clock’ that was used by sailors to set their ship’s chronometers. It can still be found in the window of the Transit House, along with an impressive telescope that was installed in the City Observatory in 1831.</p>
              </motion.div>
            </div>

            <motion.h2 className="text-pink fw-normal mb-5" variants={textAnimate}>“Lady Stair’s Close 
is undoubtedly one of the most picturesque corners in Old Town.”</motion.h2>

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article11-img4.jpg")} />
            <motion.div variants={textAnimate} className="">
                
                <h4 className="fs-md mb-2">The Writers’ Museum</h4>
                <h5 className="mb-4">Lady Stair’s Close</h5>
                <p className="mb-5">Lady Stair’s Close is undoubtedly one of the most picturesque corners in Old Town. At the heart of the close, surrounded by sky-high tenements, is Lady Stair’s House, a spectacular 17th-century townhouse which is home to The Writers’ Museum. The museum is a celebration of Scotland’s literary past and present, with a focus on the lives of three prominent Scottish writers: Sir Walter Scott, Robert Burns and Robert Louis Stevenson. Through a variety of portraits, rare books and personal objects, visitors are given a more intimate look into the fascinating lives of these well-known authors. A few noteworthy items include a first edition of Scott’s novel “Waverley,” accompanied by the press on which the novels were printed, Burns’ writing desk and a chest that belonged to the Stevenson family which was made by Deacon Brodie, whose double life may have inspired the novel “Strange Case of Dr Jekyll and Mr Hyde”.</p>
              </motion.div>

              <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <img className="img-fluid mb-4"
                  src={require("../../Images/article11-img5.jpg")}
                />
                <h4 className="fs-md mb-2">Museum of Edinburgh</h4>
                <h5 className="mb-4">142-146 Canongate</h5>
                <p className="mb-4">Halfway along the Canongate is a charming and colourful 16th-century building called Huntly House. The house was bought by the city council and restored to create a museum dedicated to Edinburgh’s origins, history and legends. As you make your way through the warren of wood-panelled rooms, across squeaky old floorboards and past original fireplaces, you’ll encounter a range of fascinating objects including the National Covenant of 1638, James Craig’s renowned plans of the New Town (which are on display from 12pm to 2pm) as well as Greyfriars Bobby’s collar and bowl. The museum continues upstairs with an insightful exhibition into Field Marshall Earl Haig’s life and an impressive display of Scotland’s decorative art. Before you leave, don’t forget to visit the museum’s courtyard which contains almost 100 architectural fragments dating from the late 15th century. One remnant formed part of Netherbow Port, Edinburgh’s ancient city gates, which has a very gruesome past.</p>
                <p className="fs-sm mb-4 mb-sm-0">Note: Access to the courtyard is weather dependent.</p>
              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">
                <img className="img-fluid mb-4"
                  src={require("../../Images/article11-img6.jpg")}
                />
                <h4 className="fs-md mb-2">The Georgian House</h4>
                <h5 className="mb-4">7 Charlotte Square</h5>
                <p className="mb-4">Charlotte Square was designed by renowned Scottish architect Robert Adam, who created a uniform square of grand Georgian townhouses with impressive palace-like frontages. The National Trust for Scotland has beautifully restored no 7 Charlotte Square for visitors to discover what life in a typical, wealthy New Town home of the early 19th century would have looked like. The home was originally owned by John Lamont who bought it for £1,800 in 1796 and as you move through the lavishly furnished drawing room and elegant master bedroom, you’ll discover fine collections of period furniture, porcelain, silver and glassware which reflect the Lamonts’ lifestyle. The museum covers several floors with the basement housing the kitchen and servants’ rooms, which are in stark contrast to the rest of the house. Throughout the experience, friendly volunteers and staff are on hand to tell you a little more about the house.  </p>
                <p className="fs-sm mb-0">Note: The Georgian House is closed during the winter months.</p>
              </motion.div>
            </div>

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article11-img7.jpg")} />
            <motion.div variants={textAnimate} className="">
                
                <h4 className="fs-md mb-2">Dovecot Studios</h4>
                <h5 className="mb-4">10 Infirmary Street</h5>
                <p className="mb-0">Escape the hustle and bustle of South Bridge by heading down Infirmary Street to visit this world-renowned tapestry studio. Dovecot Studios is housed within the former Infirmary Street Baths, which were the first public baths in Edinburgh. The building was in danger of demolition but, thankfully, The Dovecot Foundation rescued it and transformed it into a beautiful exhibition space which hosts a programme of curated exhibitions and events, a tapestry studio, a shop which supports local makers, and a popular café. The tasteful and sympathetic refurbishment means the building’s charming character is still intact, with the original Ladies Baths converted into an event space and the former swimming pool used as a tapestry studio. By heading up to the top floor, visitors can stand in the gallery, which would once have overlooked the swimming pool, and observe the talented artists at work in the tapestry studio.</p>
              </motion.div>
            
          </div>
</div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article11;
