import React, { useEffect } from 'react';
import './App.css';
import Layout from "./Layout/Layout";
// import Mouse from './Mouse';
// import VideoSkip from './Components/Front/VideoSkip';
// import IntroVideo from './Components/Front/IntroVideo';

function App() {

    useEffect(() => {
        // Get the modal element
        const myModal = document.getElementById('exampleModal');
    
        // Create a Bootstrap modal instance
        const modal = new window.bootstrap.Modal(myModal, {
          backdrop: 'static', // optional: specify backdrop behavior
          keyboard: false // optional: disable keyboard interaction
        });
    
        // Show the modal
        modal.show();

        // Add a class to an element when the modal is closed
        myModal.addEventListener('hidden.bs.modal', () => {
            const element = document.getElementById('mainContent');
            element.classList.add('main-content-show');
        });
    
        // Clean up the modal instance when component unmounts
        return () => {
          modal.hide();
          modal.dispose();
        };
      }, []);

    return (
        <div className=''>
            {/* <Mouse /> */}


            <div className="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        
                    <video class="intro-video" src="https://player.vimeo.com/external/314206034.hd.mp4?s=097fa1a40c4a1ee398d582704c89fe89e425f715&amp;profile_id=174" loop="true" autoplay="autoplay" muted></video>

<button type="button" className="btn video-skip-btn" data-bs-dismiss="modal">Skip introduction</button>
                    </div>
                </div>
            </div>

            <div className="main-content" id="mainContent">
                <Layout />
            </div>

            {/* <IntroVideo />
            
       
            <VideoSkip /> */}
        </div>
    );
}

export default App;


