import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article16() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });




  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }

  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article17');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article15');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article16-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article16-img6.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article16-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div className="mb-5" variants={textAnimate}>
              <h1 className="fs-xl fw-light mb-3">A Walk Through Scotland’s National Book Town</h1>
              <h3 className="fs-md">WIGTOWN Dumfries & Galloway.</h3>
            </motion.div>

            <div className="row align-items-center mb-5">
              
              <motion.div variants={textAnimate} className="col-sm-6">

              <motion.h3 className="mb-5 font-italic fw-light text-gold" variants={textAnimate}>“Wigtown is a true Scottish jewel and a treasure trove for visiting bibliophiles”</motion.h3>

              </motion.div>

              <motion.div variants={textAnimate} className="col-sm-6">

              <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article16-img2.png")} />

              </motion.div>

            </div>

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article16-img3.jpg")} />

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">A small market town with less than 1,000 residents, Scotland’s dedicated National Book Town is located along the coast of southwest Scotland in Dumfries and Galloway. Despite its small size, Wigtown is a true Scottish jewel and a treasure trove for visiting bibliophiles. Not only does the town boast more than a dozen book related businesses, but it also hosts a yearly literary festival for ten days each autumn. Book lovers may also recognise Wigtown from Shaun Blythell’s bestselling book, Diary of a Bookseller, which tells the tale of life running Scotland’s largest second hand bookshop. It’s a humorous and reflective read that gives readers a rare insight into this special corner of the country. </p>

                <p className="">Wigtown has a rich history that begins way before it was named Scotland’s National Book Town in 1997. Having first been established more than 1,000 years ago, Wigtown has lived through rule by the Celts, Anglo Saxons and the Norse, after their invasion in the 9th century. Its unique name is believed to have stemmed from the phrases ‘wic’ and ‘ton’ meaning either ‘village’ and ‘hill’ or ‘bay’ and ‘town’. Despite its quirky name, Wigtown is infamously known for its dark past, which, in 1685, saw two women drowned for refusing to join the dominant religion of the </p>

              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">

                <p className="">time. Popularly known as the ‘Wigtown Martyrs’, Margaret Maclauchlan and Margaret Wilson’s resting places can still be found in the local churchyard and a monument lies on Windy Hill, which is just a short walk from the town centre. </p>

                <p className="">Today, of course, Wigtown is famed for its celebration of literature and collection of independently owned bookshops. You won’t find a chain bookshop here! The vibrant Wigtown Festival is the highlight of its calendar year, having been set up in 1999 and running every year since. It typically attracts a number of high profile authors, and supports a variety of projects for new and upcoming writers, such as the Wigtownshire Young Writers Programme. The festival itself runs in the autumn, with its 2023 dates set from the 22nd of September to the 1st of October. The literary culture here is progressive, celebrating the old and the new together in one place.</p>

                <p className="">The best way to set about exploring Wigtown is with a stroll down Bank Street, which leads into North Main Street and runs from one end of the town to the other. At the top of the road you’ll find Beltie Books & Café, which is housed in a pretty stone building and adorned with a sign that’s reminiscent of a classic Penguin Books cover. This is the first of two bookshops in town that </p>

              </motion.div>

            </div>

            

            



          </div>

          <div className="" ref={secondContainer}>

            <div className="row mb-5">
              <div className="col-6">
                <img className="img-fluid"
                  src={require("../../Images/article16-img4.jpg")}
                />
              </div>
              <div className="col-6">
                <img className="img-fluid"
                    src={require("../../Images/article16-img5.jpg")}
                  />
              </div>
            </div>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="">also run their own cafés, and further down the road you’ll spot ReadingLasses with its characteristic purple and blue exterior. Specialising in books written by and about women, ReadingLasses also stock directly from the wonderful Persephone Books - an exquisite publisher based in Bath that specialises in neglected works by 20th-century female writers. It’s a fab place to pop into for a piece of cake, a cup of tea and a read of one of their many feminist tomes. </p>

                <p className="">Continuing down North Main Street, you’ll come across an eclectic collection of shops before arriving at The Book Shop, which you’ll want to dedicate a good portion of time to, in order to peruse its shelves thoroughly. It’s a charming bookshop with such a large and varied collection of books that you’re bound to find something that peaks your interest. Next door to The Book Shop you’ll find the specialist children’s bookshop Foggie Toddle Books, renamed from Curly Tail Books, and the Book End Studio, as well as Number 11, which is home to the town’s book festival, selling a number of different books and gifts. Towards the bottom of town you’ll find The Open Book and Well Read Books of Wigtown - the latter being the town’s newest bookshop having been opened by Ruth Anderson in 2018. </p>

              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">

                <p className="">You’ll find more bookshops by wandering up South Main Street, including Byre Books, which specialises in second hand books about folklore and mythology, Scottish books, play scripts and radio plays, as well as archeology and books of a more general interest. ReadingLasses is just a short way up the road, followed by the Old Bank Bookshop. Like the name suggests, this bookshop is based in an old bank, preserving the history of the town while selling a wide variety of second hand books.</p>

                <p className="">It’s not just bookshops that book loving folk will find during a trip to Wigtown. The town also hosts the country’s first bookshop B&B, The Open Book, which is a fully blown literary experience for those who have always dreamed of running their own bookshop. Guests are granted full reign over the shop while lodging in the apartment above. They’re free to man the till, interact with customers and create their own displays, while also having the freedom to shut up shop when they fancy it and go out exploring the local area. It sounds like a flexible and fun, low risk way for book lovers to live their bookshop running dreams, without making the full commitment themselves. Be warned, The Open Book is often booked up many months in advance, so you’ll need to get in there early and may be in for a long wait.</p>

              </motion.div>

            </div>

            <motion.p className="" variants={textAnimate}>While Wigtown is unique regarding its high concentration of independently owned bookshops, it’s also located in an area of significant literary importance. This makes it the perfect base for a literary trip to the Scottish Borders, beginning in Wigtown and extending up to Dumfries, which was occupied by a number of high profile Scottish writers. The author of Peter Pan, J. M. Barrie, spent much time playing in an elegant home to the east of the city named Moat Brae. A place where the young author would play imaginary games with his friends, its garden is said to have been the inspiration for Neverland while he was writing Peter Pan. After recently facing demolition, a fundraising drive allowed it to be saved and reopened as a National Centre for Children’s Literature and Storytelling. Still known as Moat Brae, the house is a magical place for children and adults alike, preserving the precious history of a world famous story.</motion.p>

            <motion.p className="" variants={textAnimate}>The area also shares strong links with Robert Burns, the Scottish literary great who inspired Burns Night, which takes place on the 25th of January each year. The renowned novelist and poet who is famed for writing in the Scots language, once lived in Dumfries on the aptly named Burns Street. Burns was based here from 1788 - 1791, where he spent the twilight years of his career. The house itself remains a snapshot of life during the 18th century, and still contains a number of personal items that belonged to the late bard, including his writing desk and chair. </motion.p>

            <motion.p className="" variants={textAnimate}>On the topic of Scotland’s literary greats, Abbotsford, the grand home of Sir Walter Scott can also be found further up the country in the Scottish Borders. This is where Scott resided from 1817 until 1825. The house itself is based on a 1,400-acre estate, and a large portion of it is open to the public. Visitors can view his writing desk, beautiful library and other items from his personal collection. One wing of the house even accepts overnight guests! The Hope Scott wing was once home to Scott’s granddaughter, Charlotte Hope Scott. The wing itself is still filled with the family’s original furniture, making for an amazing immersive experience. Charlotte inherited Abbotsford after her father’s death, commissioning the new wing for herself and her family in 1847. Tragically, Charlotte’s life was short and she passed away during childbirth in 1858.</motion.p>

            <motion.p className="" variants={textAnimate}>Wigtown and its surrounding area is so much more than a humble collection of second hand bookshops. It’s truly a hub of literary activity, nestled in the heart of Scottish literary history, and watched over by some of Scotland’s greatest literary figures. It’s a truly special area of the country, one that’s sure to enchant visitors over and over again.</motion.p>

            


          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article16;
