import React from "react";

import Scroll from '../../Layout/SmoothScroll';
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import { useState } from "react";
const Front = () => {
  const textAnimate={
    offscreen:{x:100, opacity:0},
    onscreen:{x:0,
    opacity:1,
    transition: {type:"spring",
    bounce:0.25,
    duration:0.5}}
  }
  const [hoveredImage, setHoveredImage] = useState(0)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <Scroll />
      <div className="magazine-wrap">
        <motion.div 
          className="left-unit"
          initial={{ translateX: "100%" }}
          animate={{ translateX: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <motion.img 
          src={require("../../Images/0-Cover.jpg")} 
          className={"front-left-img list-img"} 
          alt="" 
          
          style={hoveredImage === 0 ? { opacity: 1 } : { opacity: 0 }}
          />
          <img style={hoveredImage === 1 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/1-in-search-of-otters-on-islay.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 2 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/2-Lochs-and-Walks.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 3 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/3-a-weekend-in-north-berwick.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 4 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/4-in-scotland-we-surf.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 5 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/5-malcolm-appleby.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 6 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/6-rural-perthshire.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 7 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/7-the-lighthouse-family.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 8 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/8-Through-Eyrie-Woods.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 9 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/9-The-kelpie-of-loch-garve.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 10 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/10-the-rewilding-of-supper-club.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 11 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/11-museums-and-galleries.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 12 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/12-caithness-nooks-and-crannies.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 13 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/13-at-one-with-mountains.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 14 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/14-whats-on-scotland.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 15 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/15-what-happened-to-the-pics.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 16 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/16-a-walk-through-scotlnads-national-book-town.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 17 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/17-Baern-cafe-interview.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 18 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/article18-img1.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 19 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/19-where-the-muse-takes-you.jpg")} className={"front-left-img list-img"} alt="" />
          <img style={hoveredImage === 20 ? { opacity: 1 } : { opacity: 0 }} src={require("../../Images/20-scotlands-secret-gardens.jpg")} className={"front-left-img list-img"} alt="" />
        </motion.div>
        <motion.div
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{once:false, amount:0.5}}
          transition={{staggerChildren:0.25}}
          className="right-unit scroll-sec"
          >
            
              <div className="left-unit-mob">
                <img src={require("../../Images/front.jpg")} className={"front-left-img"} alt="" />
              </div>

            
              <motion.div
                onMouseEnter={() => setHoveredImage(1)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article1">
                  <h6>01 /</h6>
                  <h4>The Joy of the Search : In Search of Otters on Islay</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(2)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article2">
                  <h6>02 /</h6>
                  <h4>Discovery Roundup : Lochs & Walks</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(3)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article3">
                  <h6>03 /</h6>
                  <h4>A Weekend Exploring North Berwick : A Local's Guide in Scotland We Surf</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(4)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article4">
                  <h6>04 /</h6>
                  <h4>In Scotland We Surf</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(5)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article5">
                  <h6>05 /</h6>
                  <h4>Fine Lines : Malcolm Appleby</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(6)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article6">
                  <h6>06 /</h6>
                  <h4>A Family Stay in Rural Perthshire</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(7)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article7">
                  <h6>07 /</h6>
                  <h4>The Lighthouse Family</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(8)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article8">
                  <h6>08 /</h6>
                  <h4>Through Eyrie Woods : Golden Eagles at Glen Tanar</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(9)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article9">
                  <h6>09 /</h6>
                  <h4>Folklore, Myths & Legends : The Kelpie of Loch Garve</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(10)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article10">
                  <h6>10 /</h6>
                  <h4>Garve The Rewilding of The Supper Club</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(11)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article11">
                  <h6>11 /</h6>
                  <h4>Exploring The Smaller Museums & Galleries of Edinburgh</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(12)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article12">
                  <h6>12 /</h6>
                  <h4>Discovering The Nooks & Crannies of Caithness</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(13)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article13">
                  <h6>13 /</h6>
                  <h4>At One With The Mountains : An Interview With Nick Gardner</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(14)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article14">
                  <h6>14 /</h6>
                  <h4>What's on Scotland</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(15)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article15">
                  <h6>15 /</h6>
                  <h4>What Happened To The Picts?</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(16)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article16">
                  <h6>16 /</h6>
                  <h4>A Walk Through Scotland's National Book Town : Wigtown</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(17)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article17">
                  <h6>17 /</h6>
                  <h4>Baern Cafe : Our Daily Bread</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(18)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article18">
                  <h6>18 /</h6>
                  <h4>Recipe From Baern : Gooseberry & Oregano Friands</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(19)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article19">
                  <h6>19 /</h6>
                  <h4>Where The Muse Takes You</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>

              <motion.div
                onMouseEnter={() => setHoveredImage(20)} onMouseLeave={() => setHoveredImage(0)}
                variants={textAnimate}
                className="article-list"
              >
                <Link to="/article20">
                  <h6>20 /</h6>
                  <h4>Scotland's Secret Gardens</h4>
                  <h5>Ben Lerwill</h5>
                </Link>
              </motion.div>
            
          </motion.div>
      </div>
    </motion.div>
    
  );
}

export default Front;
