import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState, useEffect } from "react";
function Article3() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0
  });
  const { ref: fourContainer, inView: fourInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView)
    {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
        img2.className = '';
        var img3 = document.getElementById("img3");
        img3.className = '';
        var img4 = document.getElementById("img4");
        img4.className = '';
      return "";
    }
      
    else if (secondInView)
    {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';
        var img3 = document.getElementById("img3");
        img3.className = '';
        var img4 = document.getElementById("img4");
        img4.className = '';
      return "";
    }
    else if (thirdInView){
      var img1 = document.getElementById("img1");
      img1.className = '';
        var img2 = document.getElementById("img2");
        img2.className = '';
        var img3 = document.getElementById("img3");
        img3.className = 'active';
        var img4 = document.getElementById("img4");
        img4.className = '';
      return "";
    }
    else if (fourInView){
      var img1 = document.getElementById("img1");
      img1.className = '';        
        var img2 = document.getElementById("img2");
        img2.className = '';
        var img3 = document.getElementById("img3");
        img3.className = '';
        var img4 = document.getElementById("img4");
        img4.className = 'active';
    return "";
    }
  };

  const textAnimate={
    offscreen:{y:10, opacity:0},
    onscreen:{y:0,
    opacity:1,
    transition: {type:"spring",
    bounce:0.25,
    duration:0.5}}
  }
  const imgAnimate={
    offscreen:{ opacity:0},
    onscreen:{ opacity:1,
    transition: {type:"spring",
    bounce:0.25,
    delay: 0.5,
    duration:0.5}}
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article4');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article2');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{once:false, amount:0.5}}
      transition={{staggerChildren:0.25}}
      className="magazine-wrap article-wrap"
      >
        <motion.div
        variants={imgAnimate} 
        className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article3-img4.jpg" )}  />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article3-img5.jpg")}  />
          <img id="img3" className={getAnimationClass()} src={require("../../Images/article3-img9.jpg")}  />
          <img id="img4" className={getAnimationClass()} src={require("../../Images/article3-img11.jpg")}  />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article3-img4.jpg")} alt="" />
          </motion.div>

          <motion.div 
          variants={textAnimate} 
          className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
              </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <div className="row mb-5">
              <div className="col-sm-7">
                <div
                variants={textAnimate}
                >
                  <h4 className="pt-2 mb-4">meet your local guide</h4>
                  <h1 className="fs-xl text-gold mb-5 f-lora">Stephanie <br/>Lothian</h1>
                  
                </div>
                <p className="" variants={textAnimate}>Together with her husband Ryan and son Lewis, Stephanie owns and runs ‘The Barn’ at Williamstone Farm Steadings in the beautiful seaside town of North Berwick. The Barn is a grade ‘B’ listed holiday cottage which has been extensively renovated to create a luxurious and stylish home from home.</p>
              </div>
              <div className="col-sm-5">
                <img className="img-fluid"
                  src={require("../../Images/article3-img1.jpg")}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-sm-6">
                <p className=" text-gold" variants={textAnimate}>How long have you lived in North Berwick and what brought you here?</p>
                <p className="" variants={textAnimate}>Back in 2015 we had sold our house and were renting a flat in Edinburgh. We were sent the schedule for Williamstone Farm and it was love at first sight. The Steadings were derelict, and the Farmhouse needed significant upgrading, but the tranquil location on the edge of the beautiful seaside town of North Berwick sealed the deal. It was a huge project to take on, particularly as we were both working full time and sharing the project management of the renovation.</p>
                <p className="" variants={textAnimate}>Seven years on we still feel like we’re on holiday so I think we definitely made the right decision.</p>
                <p className=" text-gold" variants={textAnimate}>What is it that you particularly enjoy about this part of Scotland?</p>
                <p className="" variants={textAnimate}>East Lothian honestly has it all! We have a great selection of
                fabulous beaches, numerous top golf courses and beautiful
                farmland. Best of all there is a thriving community of small
                independent cafés, bars, shops and restaurants which makes
                it so unique. Being so close to Edinburgh is the icing on the
                cake - 30 mins by train you’re in Edinburgh City Centre.</p>
              </div>
              <div className="col-sm-6">
                <p className=" text-gold" variants={textAnimate}>What advice would you give to anyone looking to explore North Berwick</p>
                <p className="" variants={textAnimate}>Do it now! You won’t be disappointed as North Berwick has so much to offer no matter your age or interests. Guests often tell us that a 3 night stay simply isn’t enough and before they’ve left they’ve booked their return stay. We’ve even had a few guests who’ve moved to North Berwick they loved it so much! If you’re coming in the summer then my advice would be to book activities, restaurants etc in advance.</p>
                <p className=" text-gold" variants={textAnimate}>Can you give us a rundown on what North Berwick is like as the seasons change, and in your opinion when is the best time to visit?</p>
                <p className="" variants={textAnimate}>Of course North Berwick is hugely popular in the summer
                months but for me the really special months are November,
                January and March. The clear, crisp days for beach walks
                followed by an evening in front of the log burning stove is
                the perfect way to have a relaxing beach escape.</p>
              </div>
            </div>

          </div>
          
          <div className="" ref={secondContainer}>
            <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article3-img2.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                      src={require("../../Images/article3-img3.jpg")}
                    />
                </div>
              </div>

              <p className=" text-gold mb-4" variants={textAnimate}>Can you share with us what your ideal weekend looks like in North Berwick?</p>
              <h4 variants={textAnimate} className="mb-4 fs-md">Saturday.</h4>
              
              
              <p className="" variants={textAnimate}>My ideal weekend would start with an early morning sea swim followed by a selection of pastries from Bostock Bakery. Take them back to the Barn to enjoy a relaxed breakfast with some freshly roasted Steampunk coffee (which you’ll find in your welcome pack at The Barn). Once you’re ready to go exploring, walk from the Barn along the beach and coastline into North Berwick (there’s a map in a welcome pack that I like to include when staying at The Barn showing the route). Browse the many independent shops along the High Street, finishing up at the harbour for some freshly caught lobster at either The Lobster Shack or The Rocketeer (make sure you leave room for an Alanda’s ice cream). In the afternoon book an e-bike tour with Tina from Ez-Riders who’ll guide you to some off-the-beatentrack beaches, past castles and stunning countryside. Back at the Barn take a relaxing bath - time to sample our range of Laura Thomas products! For dinner head to the Herringbone for a lovely atmosphere and the best cocktails in town.</p>

              <h4 variants={textAnimate} className="mb-4 fs-md">Sunday.</h4>

              <p className=" mb-5" variants={textAnimate}>My perfect Sunday would involve a lovely relaxed start to the day with a leisurely breakfast at the Barn. Check in advance to see when low tide is for the most spectacular walk along the coastline to Drift Cafe and enjoy a delicious lunch made from local fresh produce coupled with the most spectacular view of the Bass Rock. (Drift does accept walk-ins but we always recommend that our guests book a table - and there’s always the option of a takeaway).</p>

              <div className="py-3">
                <div className="row py-5 mb-5">
                  <div className="col-sm-6">
                    <h3 className="mb-3">plan your weekend</h3>
                    <h1 className="fs-xl fw-light text-gold f-lora mb-4">Steph’s <br/>Top Picks</h1>
                    <p className="" variants={textAnimate}>Together with her husband Ryan and son Lewis, Stephanie owns and runs ‘The Barn’ at Williamstone Farm Steadings in the beautiful seaside town of North Berwick. The Barn is a grade ‘B’ listed holiday cottage which has been extensively renovated to create a luxurious and stylish home from home.</p>
                  </div>
                  <div className="col-sm-6">
                    <img className="img-fluid"
                        src={require("../../Images/article3-img6.jpg")}
                      />
                  </div>
                </div>
              </div>
              
              <div className="row mb-5">
                <div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-4 fs-md mb-3">eat ~ <br />daytime.</h4>
                  <p className=" text-gold">Drift Café & Coffee House</p>
                  <p className=" mb-4">Freshly made seasonal menu. Book a
                  window table for a breathtaking view
                  of the Bass Rock and you might even
                  spot dolphins.</p>

                  <p className=" text-gold">Bostock Bakery</p>
                  <p className=" mb-4">Order takeaway for a beach picnic or
                  eat in. Award winning pastries and
                  delicious cheese toasties.</p>

                  <p className=" text-gold">Tyninghame Café</p>
                  <p className=" mb-4">Excellent home-baked cakes and lots
                  of lunch options. There’s plenty of
                  seating both indoors and outdoors.</p>

                  <p className=" text-gold">Falko</p>
                  <p className=" mb-4">German Konditorei in Haddington.
                  Lovely lunch options but leave room
                  for the unbelievable cakes!</p>
                </div>
                <div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-4 fs-md mb-3">eat ~ <br />daytime.</h4>
                  <p className=" text-gold">The Rocketeer</p>
                  <p className=" mb-4">You’ll find freshly caught lobster on
the menu. The fish & chips are the best in town.</p>

                  <p className=" text-gold">Osteria</p>
                  <p className=" mb-4">Multi-award-winning fine dining
Italian. Family run with an extensive
wine list.</p>

                  <p className=" text-gold">The Grange</p>
                  <p className=" mb-4">The steaks and burgers are the best
in town and service with a smile - our
family favourite.</p>

                  <p className=" text-gold">The Herringbone</p>
                  <p className=" mb-4">Great atmosphere and a menu to cater for all.</p>
                </div>
                <div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-4 fs-md mb-3">eat ~ <br />daytime.</h4>
                  <p className=" text-gold">Drift Café & Coffee House</p>
                  <p className=" mb-4">Best cocktails in town and only a 15
minute walk back to the Barn.</p>

                  <p className=" text-gold">Lockett Bros</p>
                  <p className=" mb-4">Wine merchant which sells by the
glass or bottle. Choose a window seat
and order a selection of cheeses too.</p>

                  <p className=" text-gold">No.12 Quality Street</p>
                  <p className=" mb-4">Lovely large beer garden at the rear if
you’re visiting in the summer months.</p>

                  <p className=" text-gold">The Ship Inn</p>
                  <p className=" mb-4">Extensive selection of beers and a
good mix of locals and tourists.</p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article3-img7.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                      src={require("../../Images/article3-img8.jpg")}
                    />
                </div>
              </div>

              
            
          </div>

          <div className="mb-5" ref={thirdContainer}>
            <div className="row mb-5 align-items-center">
                <div variants={textAnimate} className="col-sm-4">
                  <h3 className="text-gold fw-normal mb-3 pe-4 f-lora-i">“there is a thriving community of small independent cafes, bars, shops and restaurants”</h3>
                  
                </div>
                <div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-4 fs-md mb-3">relax.</h4>
                  <p className=" text-gold">Fletchers Cottage Spa</p>
                  <p className=" mb-4">Book the Serenity package which
                  includes a bath hut experience &
                  massage treatment.</p>

                  <p className=" text-gold">East Lothian Beauty</p>
                  <p className=" mb-4">A short walk from the Barn, great for
nails & massages.</p>

                  <p className=" text-gold">Yoga at Drift</p>
                  <p className=" mb-4">There are often yoga classes available, which are also followed by a delicious brunch. It’s a great place to practice in front of spectacular views of the Bass Rock. Check before you visit.</p>

                  
                </div>
                <div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-4 fs-md mb-3">shop.</h4>
                  <p className=" text-gold">Rock & Bird</p>
                  <p className=" mb-4">Art supplies, children’s books & games, greetings cards etc.</p>

                  <p className=" text-gold">Laura Thomas</p>
                  <p className=" mb-4">Luxury Homewares, Scents & Soaps that are natural and refillable.</p>

                  <p className=" text-gold">Great Escape</p>
                  <p className=" mb-4">Everything from wetsuits & sunglasses to fashion wear.</p>

                  <p className=" text-gold">Heart & Sole</p>
                  <p className=" mb-4">Gorgeous ladies footwear and fashion.</p>
                </div>
              </div>
              <img className="img-fluid"
                      src={require("../../Images/article3-img10.jpg")}
                    />
          </div>

          <div className="" ref={fourContainer}>
            <div className="row mb-5 align-items-center">
                <div variants={textAnimate} className="col-sm-4">
                  <h3 className="text-gold fw-normal mb-3 pe-4 f-lora-i">“We have a
                  great selection
                  of fabulous
                  beaches,
                  numerous top
                  golf courses
                  and beautiful
                  farmland.”</h3>
                  
                </div>
                <div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-4 fs-md mb-3">relax.</h4>
                  <p className=" text-gold">Foxlake</p>
                  <p className=" mb-4">Try the Fox Fall, Wakeboarding & Segway tours. There’s also a great café and viewing area too. </p>

                  <p className=" text-gold">Coasteering & paddle boarding with Ocean Vertical</p>
                  <p className=" mb-4">The sunset paddle boarding sessions
in the summer are spectacular.</p>

                  <p className=" text-gold">Horse Riding at Seacliff Stables</p>
                  <p className=" mb-4">There are often yoga classes available, which are also followed by a delicious brunch. It’s a great place to practice in front of spectacular views of the Bass Rock. Check before you visit.</p>

                  <p className=" text-gold">Electric bike tours with Ez-Riders</p>
                  <p className=" mb-4">Tina is an expert at tailoring a tour to
                  your needs. It’s the most fun way to
                  explore around North Berwick with
                  minimal effort!</p>

                  
                </div>
                <div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-4 fs-md mb-3">shop.</h4>
                  <p className=" text-gold">Seacliff beach</p>
                  <p className=" mb-4">This was my dad’s favourite beach and now it’s ours.</p>

                  <p className=" text-gold">Tyninghame beach</p>
                  <p className=" mb-4">Ideal if you’d like a longer walk then go for cake at Tyninghame cafe.</p>

                  <p className=" text-gold">Yellowcraigs beach</p>
                  <p className=" mb-4">Within walking distance of the Barn and voted one of Scotland’s best 5 beaches.</p>

                  <p className=" text-gold">John Muir Country Park</p>
                  <p className=" mb-4">Beautiful mix of beaches and woodland.</p>
                </div>
              </div>

              <div className="row mb-5">
                <div variants={textAnimate} className="col-sm-6">
                  <h3 className="mb-3">and don’t forget</h3>
                  <h1 className="fs-xl fw-light text-gold f-lora mb-4">Steph’s <br/>Top Tips</h1>
                  <p className="">1.
                  <br />Don’t forget your 3 x £1 coins for the farmer’s barrier down
                  to Seacliff beach. Keep following the dirt track until you
                  reach the Portacabin public toilets and park up then follow
                  the path down to the beach. Head west along the beach to
                  marvel at the view of Tantallon Castle from the smallest
                  working harbour in Europe (you might see Jack unloading
                  his lobster catch). When leaving you’ll drive under an arched
                  bridge, make sure you look back and get a pic of the Bass
                  Rock framed by the arch.</p>
                  <p className="">2.
                  <br />Tyninghame, Yellowcraigs and John Muir Country Park all
                  have parking meters so make sure you have some coins as
                  you may struggle to get a signal on your phone. The traffic
                  wardens are pro’s - be warned!</p>
                  <p className="">3.
                  <br />Don’t leave without a visit to the nearby market town of
                  Haddington - pick up some fresh sushi at Minato Sushi, fine
                  artisan cheese from Svetlana at the multi-award-winning
                  ‘The Cheese Lady’, then savour some coffee and cake at
                  German Konditorei ‘Falko’s’.</p>
                </div>
                <div variants={textAnimate} className="col-sm-6">
                  <img className="img-fluid"
                      src={require("../../Images/article3-img12.jpg")}
                    />
                </div>
              </div>

          </div>
          </div>
          
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article3;
