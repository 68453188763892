import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState, useEffect } from "react";
function Article2() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0
  });

  const { ref: fourContainer, inView: fourInView } = useInView({
    threshold: 0
  });

  const { ref: fiveContainer, inView: fiveInView } = useInView({
    threshold: 0
  });

  const { ref: sixContainer, inView: sixInView } = useInView({
    threshold: 0
  });

  const { ref: sevenContainer, inView: sevenInView } = useInView({
    threshold: 0
  });

  const { ref: eightContainer, inView: eightInView } = useInView({
    threshold: 0
  });

  const { ref: nineContainer, inView: nineInView } = useInView({
    threshold: 0
  });

  const { ref: tenContainer, inView: tenInView } = useInView({
    threshold: 0
  });

  const { ref: elevenContainer, inView: elevenInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (thirdInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = 'active';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (fourInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = 'active';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (fiveInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = 'active';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (sixInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = 'active';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (sevenInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = 'active';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (eightInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = 'active';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (nineInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = 'active';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (tenInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = 'active';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (elevenInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = 'active';

      return "";
    }

  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article3');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article1');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article2-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article2-img2.jpg")} />
          <img id="img3" className={getAnimationClass()} src={require("../../Images/article2-img4.jpg")} />
          <img id="img4" className={getAnimationClass()} src={require("../../Images/article2-img5.jpg")} />
          <img id="img5" className={getAnimationClass()} src={require("../../Images/article2-img6.jpg")} />
          <img id="img6" className={getAnimationClass()} src={require("../../Images/article2-img7.jpg")} />
          <img id="img7" className={getAnimationClass()} src={require("../../Images/article2-img8.jpg")} />
          <img id="img8" className={getAnimationClass()} src={require("../../Images/article2-img9.jpg")} />
          <img id="img9" className={getAnimationClass()} src={require("../../Images/article2-img10.jpg")} />
          <img id="img10" className={getAnimationClass()} src={require("../../Images/article2-img11.jpg")} />
          <img id="img11" className={getAnimationClass()} src={require("../../Images/article2-img12.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article2-img1.jpg")} alt="" />
          </motion.div>
          <motion.div
            variants={textAnimate}
            className="hamburger-menu">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
            <div className="" ref={firstContainer}>
              <div
                variants={textAnimate}
              >
                <h1 className="fs-xl fw-light mt-0 pt-2 mb-4 f-lora">Lochs & Walks</h1>
                <h4 className="mt-0 mb-5 pb-2 f-lora-i">A roundup of some of the most spectacular <br />Lochs with surrounding scenic walks</h4>
              </div>

              <p className="" variants={textAnimate}>The Scottish landscape was scoured into shape slowly. At various points over the past three quarters of a million years, thick ice sheets blanketed almost the entire country, their size so immense that they would have left just the tips of the highest peaks visible. These frozen giants muffled the land for millennia, immutable and unrushed in the prehistoric dawn. But beneath this colossal volume of ice, deep down at ground level, slopes and valleys were being carved and hewn, increment by increment, as the glaciers pushed and nosed their way across the land, gouging paths.</p>
              <p className="" variants={textAnimate}>When they retreated, they gifted us a world of wonder. In the hollows that they left behind, water gathered. Some of these hollows were enormous – occupying entire U-shaped valleys – while others were far smaller. Today Scotland has more than 30,000 freshwater lochs and lochans, their names and reputations as diverse as their shapes and sizes. They are an indelible, inescapable part of the national scenery, bringing a sense of light, space and timeless natural beauty to the landscape. Little wonder that we tend to be drawn to their shores. A lochside walk remains a joy, and here are ten of our favourites</p>


            </div>

            <div className="article2-sec2" ref={secondContainer}>

              <div className="row mb-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH LOMOND</h2>
                    <h4 className="f-lora-i">Loch Lomond & The <br />Trossachs National Park</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 pt-2" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular" variants={textAnimate}>It takes under an hour to reach Balloch by direct train from Glasgow Queen Street, while the West Highland Line across to Oban has various stops in, or close to, the Loch Lomond & The Trossachs National Park. CityLink buses also head this way, while access by car is straightforward.</h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>Here’s a loch that’s not so much a destination as an entire region, a long, languid body of water that stretches for more than 22 miles from north to south. No body of water in the British Isles has a larger surface area – or, arguably, a wider reputation – than Loch Lomond, the mere mention of which conjures up images of the scenic, shortbread-tin archetype of the Scottish landscape. Bonnie banks and bonnie braes? You’ll find them right here. </p>
                  <p className=" text-justify" variants={textAnimate}>Its status as a visitor magnet, needless to say, is there for a reason. Because of the sheer area that it spans, the loch undergoes various shifts in mood, with the broader southern portion being studded with islands and fringed by water meadows, and the narrower northern section being flanked by the toppling mountains of the Highlands. The likes of Balloch at the loch’s far south, where Oasis famously played two mega-gigs in the mid-90s, and Luss, a village on the western bank, can get seriously busy </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>in high season. The welcome news for walkers, of course, is that there’s plenty of shoreline to go round. The stand-out choice for a long distance hike is to follow the fabled West Highland Way, which runs along more or less the entirety of the loch’s eastern shore, rising up the steep slopes of Conic Hill and squirrelling through long stretches of woodland. The views, at times, are sensational, and you’ll pass through settlements such as Balmaha and Rowardennan – from where, if the fancy takes you, you can veer off to climb Ben Lomond. Other established trails nearby include the Three Lochs Way, which begins at Balloch before curving west, and the Rob Roy Way, which starts out at Drymen then snakes inland. </p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Balmaha Boatyard</span>
                    This company uses an old wooden mailboat to run a ferry service to and from Inchcailloch Island on the loch, from Balmaha.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">West Loch Lomond Cycle Path</span>
                    Rather see the sights on two wheels? This 17-mile-long cycle path runs along the loch’s west bank, between Arrochar & Tarbet station and Balloch station.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">TreeZone Loch Lomond</span>
                    Crank up the adrenaline by having a go on the zip wires, balance beams, scramble nets and tightropes at this well-respected activity centre
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Clachan Inn</span>
                    If you’re looking for a drinking hole with history, head to the oldest licensed pub in the land. The first licensee was reportedly one of Rob Roy’s sisters.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Tamburrini & Wishart</span>
                    Splash out on the five-course tasting menu at this new fine-dining restaurant at Cameron House, a luxury hotel on the banks of the loch.h Island on the loch, from Balmaha.
                  </p>
                </div>
              </div>
              <img variants={textAnimate} className="img-fluid mb-5" src={require("../../Images/article2-img3.jpg")} />
            </div>

            <div className="mt-5" ref={thirdContainer}>
              <div className="row mb-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH NESS</h2>
                    <h4 className="f-lora-i">Inverness-shire</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>By public transport, the most logical way of getting to the loch is to travel first to Inverness, which has good rail links with the rest of the country (not to mention the Caledonian Sleeper night train to and from London), then hopping on a local bus to Urquhart Castle or Drumnadrochit. Alternatively, it’s a simple drive from Inverness.</h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>When you look beyond the monster frenzy, Loch Ness is, quite simply, a profoundly atmospheric spectacle. On its banks, the ruins of Urquhart Castle stare out across furling hills, moody waters and wide Scottish skies. Even on a crisp summer’s day, there’s something enigmatic about the scene. This might have something to do with the fact that, at its deepest, the loch plummets down more than 750 feet, and that its waters remain bitterly cold year-round, and that visibility below the surface is extraordinarily bad due to the high levels of peat trickling in from the mountain rivers. Nature seems to bend its rules slightly for Loch Ness. </p>
                  <p className=" text-justify" variants={textAnimate}>It is, in fact, the largest body of freshwater in Britain, reportedly containing more water than all the lakes of England and Wales combined, so there’s far more to it than its elusive, many-humped namesake. The loch’s eel-shaped outline lends itself well to long walks, with the Loch Ness 360˚ Trail </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>stretching for some 77 miles around its entire circumference, officially beginning and ending at Inverness Castle. For something shorter but still substantial, the 36-mile South Loch Ness Trail starts at Fort Augustus and focuses on the quieter, southern shoreline. </p>
                  <p className=" text-justify" variants={textAnimate}>The north bank has various points at which trails stride off across woodland and moorland, and there are alternative walks beginning at the likes of Fort Augustus and Drumnadrochit. For a memorable vantage point on a clear day, meanwhile, you can try climbing the rounded bulk of Meall Fuar-mhonaidh, the most prominent hill in the area. All told, you’ll find no shortage of ways to keep your legs ticking over while your eyes scan the loch’s surface for mysterious ripples. </p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Urquhart Castle</span>
                    Today’s imposing stone remains once formed part of a medieval fortress that held sway for 500 years. A fascinating place to visit (and great for Nessie-spotting).
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Loch Ness by Jacobite</span>
                    Heading out onto the water is more or less obligatory, and this well established operator offers cruises ranging in length from 50 minutes to 3 hours.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Falls of Foyers</span>
                    A beauty spot on the east shore of Loch Ness, where a waterfall plummets 140 ft into a wooded gorge, before flowing into the loch proper.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">The Camerons Tea Room & Farm Shop</span>
                    Based on a farm in Foyers, this enjoyable café serves breakfast, lunch and afternoon tea, with home baking and a soup of the day.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Dores Inn</span>
                    The chief draw at this lochside pub is the location, with an outdoor garden looking out across the water. There’s a wood-burner inside for nippy days.
                  </p>
                </div>
              </div>
            </div>

            <div className="" ref={fourContainer}>
              <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img13.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img14.jpg")}
                  />
                </div>
              </div>
              <div className="row mb-5 pt-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH MORAR</h2>
                    <h4 className="f-lora-i">Lochaber</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>The nearest train station to Loch Morar is Morar itself, which forms part of the world-famous Fort William to Mallaig line, on which you’ll cross the Glenfinnan Viaduct. The journey time by rail from Fort William is around 75 minutes. The same route can also be travelled by road, with the A380 winding through the Highlands. </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>If Loch Ness is deep, Loch Morar is deeper still. Trout, char, salmon and stickleback swim here in waters that plunge more than 1,000 feet below the surface. These fish might not be the only life astir – there have been multiple sightings of Morag, a spooky mermaid-like creature (or monstrous harbinger of death, depending on the source), dating back to the 1880s. Less dubious is the fact that the loch, which sits in the Lochaber region of the Highlands, is set among magnificent widescreen scenery. At its western end it’s just a couple of river bends away from the sea, thereby benefiting from the raging drama of the Atlantic-facing coast. </p>
                  <p className=" text-justify" variants={textAnimate}>Elsewhere around its banks are dense wooded hills and open pastures, which in turn makes this a prime location for wildlife-watching. Red and roe deer can both be spotted, and if you turn your eyes to the skies </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>you might spy anything from merlins and kestrels to golden eagles or – the largest avian predators of all – sea eagles. There are also brilliant fishing opportunities around the loch’s 11-mile perimeter – and hikers have plenty of their own to enjoy. </p>
                  <p className=" text-justify" variants={textAnimate}>The walking trails around the loch tend to focus more on deep panoramas than long distances, so while the longest of the three main paths extends no further than five-and-a-half miles, the views on a bright day can stretch as far as Ben Nevis, the Cuillins on Skye, and even the islands of the Hebrides.  The pick of the walks is the path from Bracorina to Tarbet on Loch Nevis, which crosses a low mountain pass and serves up glorious scenery. Once at Tarbet, you can even extend your explorations by catching the ferry out to Mallaig. Just hope that Morag’s not following you. </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Explore the Islands</span>
                    The nearby port of Mallaig has ferries across to Armadale on the Isle of Skye, as well as services to Eigg, Muck, Rum and Canna.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Mallaig Heritage Centre</span>
                    A local history museum telling the long story of the West Lochaber region, and serving as a reliable rainy-day option.
                  </p>

                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Arisaig Hotel</span>
                    An old coaching inn reinvented as an upmarket hotel. Head to its Crofters’ Rest restaurant for good food and drink, including plenty in the way of local seafood.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Chlachain Inn</span>
                    Whisky, gin, local ales and regionally sourced food are on the menu at this Mallaig pub – there’s even a dartboard
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">The Cabin</span>
                    For many, fish and chips are part of the Mallaig experience – and The Cabin is as fine a place as any to sate your cravings.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-5 pt-2" ref={fiveContainer}>
              <div className="row mb-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH FYNE</h2>
                    <h4 className="f-lora-i">Argyll & Bute</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>There’s no rail station on or close to the banks of the loch, but the nearest is Arrochar & Tarbet, which can be reached by train from Glasgow in about 1 hr 15 mins. From there it’s a 45-minute journey on a bus to Inveraray, but time your journey well as services can be sporadic. There’s simple road access to the loch from all directions. </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>As a sea loch – and the longest one in Scotland to boot – the vast Loch Fyne has a different character to the other bodies of water featured here. Dolphins, porpoises, seals and even basking sharks can be spotted in its sometimes choppy waters, although the loch’s name is generally synonymous with the seafood fished and farmed here, most notably its oysters. The first Loch Fyne Oyster Bar opened in 1988, effectively as little more than a roadside shack, and less than 20 years later had grown into a restaurant chain that sold for £68 million. </p>
                  <p className=" text-justify" variants={textAnimate}>But that’s as maybe. The scenery of the Cowal Peninsula is all undulating hills and morphing colours, a melding of land, loch and sky, and outdoor-lovers are drawn here in their droves. The sport diving is good, with a popular boulder slope reef (and a sunken speedboat!) at St Catherines, but the main riches are above the surface.  </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>Loch Fyne itself curves inland from the Sound of Bute for some 40 miles, with castles, mountains and National Trust gardens along its length. Little surprise then, that there are treats to be had for walkers. </p>
                  <p className=" text-justify" variants={textAnimate}>Various options exist, many of them clustered around Inveraray and River Fyne at the northern end of the loch. The woodlands near Inverary and the 18th-century Dùn na Cuaiche watchtower are enjoyable to wander, with enough ups and downs to serve up strong scenery, although the headline draw for serious hikers is the summit of Beinn Bhuidhe, a Munro rising up to a height of 3,110 feet (948 metres) near the head of the loch. It takes seven or eight hours to ascend and descend, so it’s one to save for the summertime, but the views from the top can be magnificent. </p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Inveraray Jail</span>
                    Tour the cell blocks and hear about the punishments doled out to the prisoners who were, from the mid-18th century onwards, accommodated by this town jail.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Purdie’s of Argyll</span>
                    A classy Inveraray shop selling organic skincare and beauty products, with everything handmade in Oban.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Inveraray Castle</span>
                    This turreted pile is still the family home of the fortunate Duke and Duchess of Argyll, but it’s open to visitors from the end of March to the end of October.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Cladach Mòr Bistro</span>
                    Expect loch views, tartan carpets, fresh seafood and open fires at this upscale restaurant, which forms part of the Loch Fyne Hotel & Spa.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Brambles Café</span>
                    A relaxed but well-run café open from 7.30am onwards, with breakfasts, salads, sandwiches, hot drinks, beers, wines and more. There’s a takeaway option too.
                  </p>
                </div>
              </div>
            </div>

            <div className="" ref={sixContainer}>
              <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img15.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img16.jpg")}
                  />
                </div>
              </div>
              <div className="row mb-5 pt-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH AN EILEIN</h2>
                    <h4 className="f-lora-i">Cairngorms National Park</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3 pt-2" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>Aviemore is simple to reach by rail, with Inverness a few stops to the north and Perth, Stirling, Dundee, Glasgow and Edinburgh all connected to the south. It’s also a stop on one of the Caledonian Sleeper services. Buses are also available, and there’s good road access. </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>Loch an Eilein translates as ‘Loch of the Island’. In truth, the name does little to convey the loveliness of this small freshwater loch in the Cairngorms, tucked away in the rampant countryside south of Aviemore. It’s ringed by the native Scots pines of the Rothiemurchus Estate, which long ago formed part of the great Caledonian Forest, so the place retains an air of natural majesty. This is helped by the presence of everything from capercaillies, crested tits, ospreys and red squirrels to – if your luck’s really in – Scottish wildcats. The name Rothiemurchus actually dates back as far as the 8th century, evidence that human presence has also been felt here since the days of old. </p>
                  <p className=" text-justify" variants={textAnimate}>The estate has been in the hands of the Grant family since the 16th century and still comprises around 10,000 hectares of forest, glens, rivers and mountains. But no part of it tugs at the heart quite like Loch an Eilein, where the crumbled remnants  </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>of a 600-year-old castle stand lonely on the small green island at its heart. The loch itself, meanwhile, is a sheet of water that reflects the pines, slopes and clouds above. As long ago as 1792, the usually strait-laced writers of the Statistical Account of Scotland rhapsodised to their readers that “the loch… exhibits a scene most picturesque and romantic, and by the situation of the surrounding hills are formed five very remarkable echoes.” It’s a fine spot. </p>
                  <p className=" text-justify" variants={textAnimate}>The walk that draws people here is a relatively simple two-hour circuit of the loch, with good footpaths tracing the four-mile shoreline. There’s only minimal ascent and descent involved, although there are optional extensions to nearby Ord Ban and Loch Gamhna for those in search of a longer hike. </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Loch an Eilein Pottery</span>
                    A charming little pottery run by Penny Weir, who crafts pots and jugs from red earthenware, glazed in blues, greens and turquoises. You can also try making your own.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Strathspey Railway</span>
                    Step back in time with a trip on this steam railway, which chuffs along the tracks between Aviemore, Boat of Garten and Broomhill.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Jack Drake Bothy</span>
                    An unassuming little wooden bothy set in pine woodland to the south of the loch, within Invereshie & Inshriach National Nature Reserve.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Old Bridge Inn</span>
                    Expect good food, good drink and unpretentious hospitality at this popular Aviemore pub, which has occasional live music events.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Route 7 Café</span>
                    Tucked away on the edge of Aviemore is this characterful café, where you’ll find everything from pancakes and burritos to club sandwiches and Cullen skink.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-5 pt-2" ref={sevenContainer}>
              <div className="row mb-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH AWE</h2>
                    <h4 className="f-lora-i">Argyll & Bute</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3 pt-2" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>It takes under an hour to reach Balloch by direct train from Glasgow Queen Street, while the West Highland Line across to Oban has various stops in, or close to, the Loch Lomond & The Trossachs National Park. CityLink buses also head this way, while access by car is straightforward. </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>Trace your finger north for a few miles from Loch Fyne and you’ll reach the freshwater expanse of Loch Awe.  It slants across the map in parallel fashion to its brackish neighbour, measuring more than 25 miles from end to end but stretching barely a mile from bank to bank. But while Loch Fyne is frequented by A-road traffic, Loch Awe is often a far quieter prospect. Scenically it’s a gem, with rolling wooded hills in the south and brawnier peaks in the north, and the loch is these days famed for everything from its trout fishing to its old battlements. The pleasingly moody 15th-century Kilchurn Castle at its northern end must rank as one of the most photogenic sites in the entire Argyll & Bute region. </p>
                  <p className=" text-justify" variants={textAnimate}>Close to the castle is the Pass of Brander, where Robert the Bruce overcame the MacDougall clan in the early 1300s. As if to form a contrast to this medieval drama, however, the </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>loch is also home to the remarkable Cruachan Power Station, opened by the Queen in October 1965 as the world’s first large-scale reversible pumped storage hydro system (or, in layman’s terms, a massive hydroelectric facility in a hollowed-out mountain). </p>
                  <p className=" text-justify" variants={textAnimate}>More pertinent than all of this, however, is the fact that the loch is a stunning place to walk, with plentiful wildlife (keep your eyes peeled for pine martens) and various shorter trails and hill paths to choose from. Try the ancient Atlantic oak woods around Dalavich for a simple walk with big scenery. And if you’d rather keep things sedentary, be aware that the tracks of the Glasgow to Oban train line run along part of the loch, giving billowing views out to the south. </p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Cruachan Power Station</span>
                    Venture deep into the mountainside on a guided tour, to see for yourself the power station’s vast underground machine hall.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Kilchurn Castle</span>
                    Home to the oldest extant barracks on the UK mainland, Kilchurn Castle dates back to the mid-1400s and remains a hugely atmospheric spectacle.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">St Conan’s Kirk</span>
                    Meticulously built and beautifully located, this church in the village of Lochawe is well known for combining various styles of architecture.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Ben Cruachan Inn</span>
                    Overlooking the loch is this multi-award-winning gastropub, where the kitchen makes the most of the natural larder on its doorstep.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Kilchrenan Inn</span>
                    Once an 18th-century trading post, the refurbished Kilchrenan Inn has stylish décor, a good restaurant and three guest rooms.
                  </p>
                </div>
              </div>
            </div>

            <div className="" ref={eightContainer}>
              <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img17.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img18.jpg")}
                  />
                </div>
              </div>
              <div className="row mb-5 pt-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">ST MARY'S LOCH</h2>
                    <h4 className="f-lora-i">Scottish Borders</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3 pt-2" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>Arriving by car is easy. The A708 runs right along the northern shore of the loch, linking with the A7 to the east and the A74(M) to the west. If you’re looking to visit by public transport, buses run from Selkirk to Ettrick, several miles to the south, but there’s no rail option. </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>“Thou know’st it well,—nor fen nor sedge/ Pollute the pure lake’s crystal edge; Abrupt and sheer, the mountains sink/ At once upon the level brink; And just a trace of silver sand/ Marks where the water meets the land.” Sir Walter Scott always says it best, and his poem By Lone St. Mary’s Silent Lake is no exception to this rule. This is the largest natural loch in the Scottish Borders, named for a church that once looked out from its northern bank. It’s only around three miles in length but remains a sheltered spot, with slopes hemming it in on all sides, which in turn means the surface is often mirror-calm. </p>
                  <p className=" text-justify" variants={textAnimate}>Scott wasn’t the only notable writer familiar with its charms. The self-educated James Hogg was born and died a few miles away in the village of Ettrick, and a large monument in his honour still stands at the loch’s southern end, where a narrow channel links St Mary’s Loch to the adjacent Loch of the Lowes. Going  </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>back further in time, William Wallace is thought to have used the nearby woodlands as a base for raiding English settlements. </p>
                  <p className=" text-justify" variants={textAnimate}>All of which brings serious atmosphere to a walk. It’s possible to complete a full loop of the loch on waymarked paths, with around nine miles to cover various points of historical interest en route. For long-distance hikers, two key routes trace the loch’s shoreline. The first is the gargantuan Southern Upland Way, which passes 212 miles from coast to coast, while the second is the Sir Walter Scott Way – him again – a 92-mile trail which, for much of its length, follows an almost identical route.  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Tibbie Shiels Inn</span>
                    Sir Walter Scott, William Wordsworth and Robert Louis Stevenson all reportedly visited this inn. It’s no longer a pub, but offers camping.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Grey Mare’s Tail Nature Reserve</span>
                    Peregrine falcons, ring ouzels and a long-drop waterfall (which gives the place its name) are among the selling points of this nearby nature reserve.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Bowhill House</span>
                    A 20-minute drive north of the loch brings you to this lavish country estate, which offers tours of its art-filled, ornate interiors.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Gordon Arms</span>
                    With an AA rosette under its belt, this historic coaching inn is well worth factoring in as a dining stop. It offers accommodation, too.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Tushielaw Inn</span>
                    An old coaching inn in the nearby Ettrick Valley, with good views, a snug bar for eating and drinking, and rooms to let.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-5 pt-2" ref={nineContainer}>

              <div className="row mb-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH GLASS</h2>
                    <h4 className="f-lora-i">Easter Ross</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3 pt-2" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>There are direct buses from Inverness to Evanton, taking around 35 minutes, and you can also pick up a bus from Dingwall, which has its own rail station just six miles to the south. Evanton itself sits just off the A9, so it’s easy to reach if you have your own vehicle.  </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>On a waterside far from any town or village there stands an abandoned house. Its walls are the same fairy-soft pink as flamingo feathers, or a rainforest flower – but this is no tropical escape. The house looks out across Loch Glass, a spectacularly serene Highland loch flanked by hills, glens and woodland. Inverness is 20 miles away to the southeast as the eagle flies, but may as well be on another planet. The loch feels properly remote, a little-visited refuge in the sun-patched wilds of Easter Ross; the whaleback contours of Ben Wyvis loom overhead as if hiding it from wider public view. </p>
                  <p className=" text-justify" variants={textAnimate}>The walk here, however, is surprisingly straightforward. If you park at Eileanach Lodge, to the south of the loch, a half-hour walk through quiet forest brings you to the shoreline, from where it’s easy to wander around the waterside. And despite how little climbing or </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>clambering you’ll have to do – the odd uneven or boggy piece of ground aside – the views are the kind you’ll want to linger over. </p>
                  <p className=" text-justify" variants={textAnimate}>But back to that pink house. Its eye-catching location and rosy paintwork have seen it become a popular subject of Instagram posts, although mystery still swirls around how and why it came to be here. It seems that back in the 1980s, it was built by private owners but had to be aborted after planning permission was refused. So while its walls and roof are intact, closer inspection reveals that inside – rather underwhelmingly – it’s little more than a shell, with no rooms to speak of and many of its windows simply painted to resemble glasswork from a distance. And why is it pink? That particular fact has been lost in the mists of the Highlands.</p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Black Rock Gorge</span>
                    This nearby gorge featured in Harry Potter & The Goblet of Fire, when a Hungarian Horntail dragon pursues Harry during the Triwizard Tournament.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Tollie Red Kites Nature Reserve</span>
                    The headline draw here are the red kites themselves. Bird flu put the usual scheduled feeding sessions on hold, but they’re hoping to restart them in mid-2023.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Fyrish Monument</span>
                    Built to resemble an Indian city gate, this hilltop monument is an eye-catching sight on the skyline. It’s well worth clambering up to, to take in the view of the surrounding area.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Storehouse Restaurant</span>
                    You’ll find a food hall, a gift shop and a restaurant in one at this venue on the banks of the Cromarty Firth, a little way south of Evanton.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Highland Farm Café</span>
                    A modern ‘eco café’ with all-day food – including burgers, mac and cheese, baked potatoes and salads – as well as a shop and an outdoor play area.
                  </p>
                </div>
              </div>
            </div>

            <div className="" ref={tenContainer}>
              <img variants={textAnimate} className="img-fluid mb-5" src={require("../../Images/article2-img19.jpg")} />
              <div className="row mb-5 pt-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fs-lg fw-regular">LOCH AFFRIC</h2>
                    <h4 className="f-lora-i">Glen Affric</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3 pt-2" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>A minor road leads down Glen Affric to the Loch Affric Circuit Car Park (it’s a scenic drive, which also traces the banks of Loch Beinn a’Mheadhoin). Buses run from Inverness to Tomich and Canich, which are several hours’ walk to the east of the loch. </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>Now here’s a Highland walk you won’t forget in a hurry. The Loch Affric Circuit follows a loop of just over 11 miles, rising and falling across moorland and through pine woods, with the magnificent loch itself a near-constant presence below the trail. It’s a long hike, so allow yourself at least five hours and be aware that once you’ve begun hiking, you’re effectively committing to the whole circuit. But it’s also drop-dead gorgeous, with epic mountain scenery unfurling as you walk. The banks of Loch Ness are a mere 16 miles away to the south, but the more isolated feel of Loch Affric is radically different. </p>
                  <p className=" text-justify" variants={textAnimate}>The loch forms part of the wider Glen Affric national nature reserve, which means that among the silver birch and Scots pine you might spot pine martens, wildcats and the much sought-after Scottish crossbills, while down at the water’s edge in the warmer months there are rare  </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>dragonflies to be found. It’s also a popular spot for fishing, with the loch’s prized stock of brown trout proving a draw for many anglers. </p>
                  <p className=" text-justify" variants={textAnimate}>Given the sheer drama of its surroundings, it comes as no surprise that the loch has featured on the silver screen in Hollywood. The fact that the film in question was Pokemon spin-off movie Detective Pikachu is more of a shock – but don’t let this amusing piece of trivia detract from the unbridled glories of the setting. This is a walk of many highlights, with bridges to cross and snow-capped summits to gaze at. It’s said that Bonnie Prince Charlie himself once hid away in the woods in this very glen. There would definitely have been worse spots to choose. </p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Glen Affric Viewpoint</span>
                    If you don’t have time for the full loop it’s just a five-minute hike from the car park to this superb viewpoint, looking back down the glen.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Highland Trekking & Trail Riding</span>
                    Experience the glen’s towering scenery on horseback, with this local operator based in the nearby hamlet of Cougie.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Dog Falls</span>
                    Rapids gush into a gorge at this beauty spot, set close to the northern end of neighbouring Loch Beinn a’Mheadhoin.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Tomich Hotel</span>
                    A one-time Victorian hunting lodge in a solid, stone-clad building, offering a decent place to eat, drink and stay.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Bog Cotton Café</span>
                    A welcome place to call into for hot drinks, homemade cakes, light bites and a chance to put your feet up. It’s in Cannich, around 15 miles from the loch’s car park.
                  </p>
                </div>
              </div>
            </div>

            <div className="" ref={elevenContainer}>
              <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img20.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article2-img21.jpg")}
                  />
                </div>
              </div>
              <div className="row mb-5 pt-5">
                <div className="col-sm-7">
                  <div
                    className="article2-sec2-top"
                    variants={textAnimate}
                  >
                    <h2 className="fw-regular">AN LOCHAN UAINE</h2>
                    <h4 className="f-lora-i">Cairngorms National Park</h4>
                  </div>
                </div>
                <div className="col-sm-5">
                  <h6 className="fs-sm mt-5 mb-3 pt-2" variants={textAnimate}>GETTING THERE</h6>
                  <h6 className="fw-regular lh-base" variants={textAnimate}>Aviemore is simple to reach by rail, with Inverness a few stops to the north and Perth, Stirling, Dundee, Glasgow and Edinburgh all connected to the south. It’s also a stop on one of the Caledonian Sleeper services. Buses are also available, and there’s good road access. </h6>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-6">

                  <p className=" text-justify" variants={textAnimate}>There are two main theories as to why this wonderful little freshwater loch in the Cairngorms has a distinctly green hue. The first is that the presence of algae, in conjunction with the reflected colours of the shoreline trees, alters the colour of the water. The second is that local fairies and pixies wash their green clothes in the loch. Our money’s on the fairies. The name An Lochan Uaine actually translates as ‘Green Lochan’, and its unusual tint certainly helps it stand out from many of the other lochs in the region, but it’s no mere novelty. Geologists point to it as an excellent example of a glacial tarn, which means it’s been wowing the locals for at least ten millennia. </p>
                  <p className=" text-justify" variants={textAnimate}>It’s located in a popular part of the Cairngorms National Park, being close to the road that leads between Aviemore and Boat of Garten, but at the same time it takes a bit of effort to reach. It’s best to begin your walk at </p>

                  <p variants={textAnimate}> </p>
                </div>
                <div className="col-sm-6">
                  <p className=" text-justify" variants={textAnimate}>the Glenmore Visitor Centre, where a track leads first to the Cairngorm Reindeer Herd Centre then veers right to climb through Caledonian pine woods up to the loch. </p>
                  <p className=" text-justify" variants={textAnimate}>It’s not a difficult trek by any means, but at times it’s a little snaggy underfoot and there’s no direct road access. Once you’re at the loch, steps lead down to the water’s edge. If you’d like to extend the hike, it’s possible to retrace your steps slightly then continue on the path towards Nethy Bridge, which serves up deep views of the glen and also brings you directly to Ryvoan bothy. From there, you can either stay the night or turn on your heels and go back the way you came. The whole return hike covers five miles or so. </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>SEE & DO NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Cairngorm Reindeer Herd</span>
                    Ever rambled with a reindeer? Now’s your chance. This is Britain’s only free-ranging reindeer herd, and the centre offers half-hour hill-walking trips to allow you to get up close.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Glenmore Visitor Centre</span>
                    Discover more about Glenmore Forest Park at this centre, which is also the setting-off point for various trails. There’s a monument to wartime resistance fighters just outside.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Ben Macdui</span>
                    If you’re looking for a challenge, try summiting Scotland’s second highest peak. It can be difficult, so make sure you have the requisite fitness, gear and know-how.
                  </p>
                </div>
                <div className="col-sm-6">
                  <h6 className=" mb-4" variants={textAnimate}>PLACES TO EAT NEARBY </h6>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Boathouse Café</span>
                    A café on the banks of nearby Loch Morlich, with a beach to wander while you sup on your coffee.
                  </p>
                  <p className="fs-sm mb-3" variants={textAnimate}>
                    <span className="fw-semibold d-block mb-3">Rothiemurchus Barn</span>
                    Opposite the Rothiemurchus Estate farm shop is this café and take-away serving stews, sandwiches, soups and more, with an emphasis on estate produce.
                  </p>
                </div>
              </div>
            </div>



          </div>

        </div>
      </motion.div>

      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>

    </motion.div>

  );
}

export default Article2;
