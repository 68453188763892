import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Article1 from "../Components/Articles/Article1";
import Article2 from "../Components/Articles/Article2";
import Article3 from "../Components/Articles/Article3";
import Article4 from "../Components/Articles/Article4";
import Article5 from "../Components/Articles/Article5";
import Article6 from "../Components/Articles/Article6";
import Article7 from "../Components/Articles/Article7";
import Article8 from "../Components/Articles/Article8";
import Article9 from "../Components/Articles/Article9";
import Article10 from "../Components/Articles/Article10";
import Article11 from "../Components/Articles/Article11";
import Article12 from "../Components/Articles/Article12";
import Article13 from "../Components/Articles/Article13";
import Article14 from "../Components/Articles/Article14";
import Article15 from "../Components/Articles/Article15";
import Article16 from "../Components/Articles/Article16";
import Article17 from "../Components/Articles/Article17";
import Article18 from "../Components/Articles/Article18";
import Article19 from "../Components/Articles/Article19";
import Article20 from "../Components/Articles/Article20";
import Article21 from "../Components/Articles/Article21";
import Article22 from "../Components/Articles/Article22";

import Front from "../Components/Front/Front";

const MyRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Front />,
    // loader: rootLoader,
  },
  {
    path: "/article1",
    element: <Article1 />,
    // loader: rootLoader,
  },
  {
    path: "/article2",
    element: <Article2 />,
    // loader: rootLoader,
  },
  {
    path: "/article3",
    element: <Article3 />,
    // loader: rootLoader,
  },
  {
    path: "/article4",
    element: <Article4 />,
    // loader: rootLoader,
  },
  {
    path: "/article5",
    element: <Article5 />,
    // loader: rootLoader,
  },
  {
    path: "/article6",
    element: <Article6 />,
    // loader: rootLoader,
  },
  {
    path: "/article7",
    element: <Article7 />,
    // loader: rootLoader,
  },
  {
    path: "/article8",
    element: <Article8 />,
    // loader: rootLoader,
  },
  {
    path: "/article9",
    element: <Article9 />,
    // loader: rootLoader,
  },
  {
    path: "/article10",
    element: <Article10 />,
    // loader: rootLoader,
  },
  {
    path: "/article11",
    element: <Article11 />,
    // loader: rootLoader,
  },
  {
    path: "/article12",
    element: <Article12 />,
    // loader: rootLoader,
  },
  {
    path: "/article13",
    element: <Article13 />,
    // loader: rootLoader,
  },
  {
    path: "/article14",
    element: <Article14 />,
    // loader: rootLoader,
  },
  {
    path: "/article15",
    element: <Article15 />,
    // loader: rootLoader,
  },
  {
    path: "/article16",
    element: <Article16 />,
    // loader: rootLoader,
  },
  {
    path: "/article17",
    element: <Article17 />,
    // loader: rootLoader,
  },
  {
    path: "/article18",
    element: <Article18 />,
    // loader: rootLoader,
  },
  {
    path: "/article19",
    element: <Article19 />,
    // loader: rootLoader,
  },
  {
    path: "/article20",
    element: <Article20 />,
    // loader: rootLoader,
  },
  {
    path: "/article21",
    element: <Article21 />,
    // loader: rootLoader,
  },
  {
    path: "/article22",
    element: <Article22 />,
    // loader: rootLoader,
  },
]);

export default MyRoutes;