import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article9() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });



  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article10');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article8');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap article9-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article9-img1.jpg")} />
        </motion.div>
        <div className="article-right article9-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article9-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <h1 className="fs-xl fw-light mb-3">THE KELPIE OF <br />LOCH GARVE </h1>
            <h3 className="text-gold fw-normal mb-5">Folklore, Myths & Legends</h3>
            <motion.p className="text-gold" variants={textAnimate}>One day, a builder living in the north of Scotland opened his door to find a beautiful, black stallion grazing near his cottage. It was the most remarkable animal he had ever seen, but there seemed to be no owner around to claim it. The horse wandered closer and when it knelt in front of him, the astounded builder thought it was his lucky day!</motion.p>
            <motion.p className="" variants={textAnimate}>However, as soon as he jumped on the horse’s back, he realised his mistake. This was no ordinary stallion; this was the Kelpie of Loch Garve. These creatures lived in the depths of lochs and rivers, emerging in the form of a horse to prey on unsuspecting humans. Once a Kelpie had lured a rider onto its back, they were unable to dismount and soon dragged beneath the water.</motion.p>
            <motion.p className="" variants={textAnimate}>Disappointed in his own foolishness, the builder found himself galloping towards the Kelpie’s lair, plunging into the icy water of Loch Garve while praying for his life. As they reached the bottom of the loch, his lungs were on fire from holding his breath. Finally, he gave up and took a deep gulp but to his surprise, he could still breathe!</motion.p>
            <motion.p className="" variants={textAnimate}>Suddenly, the booming voice of the Kelpie shook him out of his amazement. It explained that he shouldn’t be afraid, his life wasn’t in danger as long as he helped to solve a problem. There might even be a reward in it for him.</motion.p>
            <motion.p className="" variants={textAnimate}>The Kelpie had recently married, but life at the bottom of Loch Garve wasn’t exactly what his new wife had hoped for. She wasn’t immune to the biting cold down there and the builder could well understand, he’d barely arrived and already felt the chill in his bones.</motion.p>
            <motion.p className="" variants={textAnimate}>What they needed was a roaring fire down in their lair and so the Kelpie had searched for the best builder in the Highlands. His task was to construct a grand fireplace, with a chimney so enormous that it almost reached the surface of the loch. </motion.p>
            <motion.p className="" variants={textAnimate}>Being trapped underwater with a terrifying, supernatural creature, the builder could hardly refuse. With the strength of the Kelpie to shift the stone blocks, the fireplace and chimney were finished in no time at all. Soon, with a bit of Kelpie magic, the fire was roaring in the hearth and his wife was happy once again.</motion.p>
            <motion.p className="" variants={textAnimate}>The Kelpie of Loch Garve brought the relieved builder back to the surface and was so grateful that he promised to reward him the only way he could. His family would never go hungry, any time they needed fresh fish, they just left an empty basket by the side of the loch. By morning it would be full to the brim.</motion.p>
            <motion.p className="" variants={textAnimate}>It’s said that the Kelpie keeps the fire going to this very day. Even during the coldest of winters, when the surrounding area is covered in thick ice and snow, there is one spot on Loch Garve that never freezes over. Directly above the Kelpie’s chimney.</motion.p>

          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article9;
