import React, { useRef } from "react";

import { useNavigate } from 'react-router-dom';

import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article20() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article1');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article2');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };
  

  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article20-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article20-img2.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article20-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="mb-5" ref={firstContainer}>
            <motion.div variants={textAnimate}>
              <h1 className="fs-xl fw-light mb-2 d-inline-block">Scotland’s <br/>Secret Gardens</h1>
            </motion.div>
            <motion.h4 className="mb-5 fs-md font-italic fw-normal" variants={textAnimate}>“If you look the right way, you can see <br/>that the whole world is a garden.” </motion.h4>

            <motion.p className="font-italic text-orange mb-5" variants={textAnimate}>Frances Hodgson Burnett’s classic children’s book ‘The Secret Garden’ is a reminder not just that there is beauty to be found everywhere, but that we need to care for and nurture the natural world, its flora and fauna. And, as the summer crowds descend, filling cities to overflowing and swarming through Scotland’s wildest places, there are hidden green spaces, pockets of peace, both wild and cultivated, where you can retreat for a moment of calm. </motion.p>
            <motion.h4 className="mb-4 fs-md" variants={textAnimate}>A New Eden </motion.h4>
            <motion.p className="" variants={textAnimate}>“At first people refuse to believe that a strange new thing can be done, then they begin to hope it can be done, then they see it can be done - then it is done and all the world wonders why it was not done centuries ago.” Another excerpt from ‘The Secret Garden’ could almost be the Eden Project’s mission statement. If any organisation knows how to see the potential for natural beauty in industrial decay, it’s the Eden Project. </motion.p>



            <motion.p className="" variants={textAnimate}>After turning a disused clay quarry in Cornwall into one of the country’s most visionary tourist attractions in 2001, a ‘global garden’ and ‘living theatre of plants,’ the Eden Project has turned its eyes northwards - to Dundee. The city is currently undergoing a multi-million-pound regeneration of its waterfront - the star, until now, the first outpost of the V&A outside London and already an architectural icon. </motion.p>

            <motion.p className="" variants={textAnimate}>The Eden Project’s vision for the old gasworks on East Dock Street on the River Tay, due to open in 2025, centres around new ‘guilds’ for the 21st century: growers, healers, myth-makers, navigators, noticers, alchemists, celebrators, menders and re-sourcerers. The planting of a wildflower meadow is already underway, a pollination study completed. The gasworks’ original brick walls will be used to cradle gardens – walled gardens for a modern age, symbolising urban regeneration and creating beauty in what was an industrial wasteland. </motion.p>

          </div>

          <div className="" ref={secondContainer}>
            <h2 class="fw-normal mb-5 text-orange">“And the secret garden bloomed and bloomed and every morning revealed new miracles.”</h2>
            <motion.h4 className="mb-4 fs-md" variants={textAnimate}>Urban Green Space </motion.h4>
            <motion.p className="" variants={textAnimate}>Already established, The Hidden Gardens in Glasgow is another visionary restoration project. What was once a derelict area in Pollokshields has been turned into an environmental artwork, urban green space and sanctuary in the city. Over the past 20 years it has been the location for festivals of flowers with sound and light installations, a wildflower meadow has been planted to increase biodiversity and attract more bees and butterflies to the garden, Tai Chi classes are held on the grass and horticultural workshops in this city oasis. </motion.p>
            <motion.p className="" variants={textAnimate}>Edinburgh has no shortage of green spaces. It’s peppered with parks while the Royal Botanic Garden is a tranquil haven of 70 carefully cultivated acres. However, there are also some hidden green corners here.  </motion.p>
            <motion.p className="" variants={textAnimate}>Designed in the 1840s by local architect David Cousin, Warriston Cemetery was one of the fashionable new ‘garden’ cemeteries created in the Victorian era. Today, the top section is still manicured and maintained, but follow the dappled paths down through ancient sycamore, beech, oak, yew and cherry trees towards the Water of Leith and you’ll find a wild and overgrown area where nature has taken over. Ivy drapes ancient tombs and gravestones, a tangle of brambles make it a popular berry-picking spot and it’s a haven for wildlife.  </motion.p>
            <motion.p className="mb-5" variants={textAnimate}>Another Secret Garden can be found on Corstorphine Hill. The 19th-century walled garden of Hillwood House had fallen into disrepair, neglected and overgrown, until local volunteers, the Friends of Corstorphine Hill, stepped in. It is now a bucolic corner of the city to while away an hour or so meandering the fernery and wetlands area or sitting quietly on a bench among the flowers.  </motion.p>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <h4 className="mb-4 fs-md">The Artist’s Garden </h4>
                <p className="">In 1966 Ian Hamilton Finlay moved to Stonypath in the Pentland Hills and began the transformation of seven acres of rough moorland into what is described as his greatest work of art. Little Sparta is a garden woven with ideas and artworks – there are 270 peppered among the plants, each sculpture integral to the garden’s design, the concepts explored ranging from our relationship with nature to classical antiquity. The garden is only open during the summer, in June, July, August and September, as Finlay wanted it to be seen in full leaf with all the plants in bloom. </p>

                <p className="mb-5">On the edge of Edinburgh is another sculpture garden in the grounds of Bonnington House. Again only open during the summer, Jupiter Artland is peppered with pieces by Andy Goldsworthy, Antony Gormley, Cornelia Parker and Charles Jencks. </p>

                <img className="img-fluid mb-4" src={require("../../Images/article20-img3.jpg")}
              />
                
              </motion.div>
              <motion.div variants={textAnimate} className="col-sm-6">
                <h4 className="mb-4 fs-md">A Room with a Garden View  </h4> 
                <p className="">When Tessa Knott bought 103 acres of land in Galloway unseen it was a gamble. What she found was wild and wiry moorland, blanketed in heather and bracken and a ruined cottage that was to become the family home. What she created over four decades is now regarded as one of Scotland’s most spectacular gardens. </p>

                <p className="">With views out over Luce Bay and the Mull of Galloway, Glenwhan Gardens features an elegant arboretum and tree trail with over 160 species, a rock garden, water garden, wetland area, two lochans, a potager and herb garden. In spring the rhododendrons and azaleas create a spectacular display.  There is also a moorland walk threaded with around 120 types of wildflower and grasses. And at dawn and dusk, if you’re bedding down in the shepherd’s hut within the 12-acre ornamental garden, you have the whole place to yourself.</p>

                <p className="">Another holiday home for keen gardeners is Leuchie Walled Garden in East Lothian. The mid-century post-Bauhaus house, where Sir Hew Dalrymple spent his childhood, has recently been turned into an exclusive self-catering property and the five-acre garden, which gives the house its name,     restored by his wife Janey – who also offers guests private guided garden tours.</p>

                <p className="">The two-hectare, 18th-century walled garden is split into two parts, the northern end bordered by fruit trees and colourful herbaceous borders, while the southern half is still a working kitchen garden. Now with a more contemporary feel, the layout has been simplified and a number of trees removed to open up the views. Two Edwardian formal gardens have been restored, and the south-facing wall in the north garden softened with climbing roses. </p>

                <motion.div variants={textAnimate} className="bg-brown p-4 mt-2">
                  <p className="mb-0">
                  Edenproject.com
                  <br/>Thehiddengardens.org.uk
                  <br/>Corstorphinehill.org.uk
                  <br/>Glenwhangardens.co.uk
                  <br/>Leuchiewalledgarden.com
                  <br/>pitchup.com
                  </p>
                </motion.div>
              </motion.div>

            </div>


          </div>
          </div>
        </div>
      </motion.div>

      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn"> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>

    </motion.div>

  );
}

export default Article20;
