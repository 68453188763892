import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article13() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });

  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article14');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article12');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article13-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article13-img2.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article13-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div variants={textAnimate} className="mb-5">
              <h1 className="fs-xl fw-light mb-3">At One with <br/>the Mountains</h1>
              <h3 className="fs-md">An interview with Nick Gardner</h3>
            </motion.div>
            <motion.p className="mb-5 font-italic" variants={textAnimate}>Nick Gardner and his wife Janet were living an idyllic life on a croft in northwest Scotland when Janet received a devastating diagnosis. When she was moved into a care home, Nick felt powerless. Having climbed mountains his whole life, his instincts took him to the hills, and he set himself a challenge: to climb all 282 of Scotland’s Munros (mountains higher than 3,000 feet). He was 80 at the time. His goal was to raise £10,000 for Alzheimer Scotland and the Royal Osteoporosis Society. Two years after he set out, not only had he completed his challenge a year sooner than expected, but he had smashed his fundraising target. Now, a documentary has been made about his achievements, he is writing a book, and he has said, “I don’t think my life, whatever is left of it, will ever be the same again”.</motion.p>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <h4 className="mb-2">In a nutshell, how would you describe yourself? </h4>
                <p className="mb-4">Before the challenge, I would have described myself as quiet and introverted. Janet and I were a devoted couple who loved being together and our life on the croft was idyllic.  However, after beginning the Munros challenge, I found myself enjoying the publicity, and particularly the fundraising aspect of it. Perhaps I’m not as introverted as I thought.</p>

                <h4 className="mb-2">In 2020, you set out to climb all 282 Munros in Scotland, completing the final one, Cairn Gorm, in August 2022. What drove you to pursue this challenge?</h4>
                <p className="mb-4">The driving force was my love for Janet. It was bad enough realising that something was wrong and then getting the diagnosis, but when I couldn’t look after her any more, that was devastating. I knew that I was heading for mental health problems and I realised I needed something to </p>
                
              </motion.div>
              
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="mb-4">focus on. Since I had been a climber all my life, I turned to the mountains for help. I had seen a story in the magazine “Alzheimer Scotland” about a young woman who climbed 20 Munros in memory of her grandfather and raised a substantial sum of money. I thought, “I wonder if I could do that”. On further thought, I went for the lot. I hadn’t heard of anyone over 80 starting to climb them though, so I thought it would take me three years. It turned out that two was enough.</p>

                <h4 className="mb-2">You were 80 when you started the challenge. What did your training look like? </h4>
                <p className="mb-4">I didn’t need to do any extra training as I was already fit. Our lifestyle was such that we spent most of our time gardening. I would go climbing about twice a month and this was sufficient to maintain a reasonable level of fitness. In fact, I found that I was more tired after a day’s gardening than a similar length of time in the hills!</p>

              </motion.div>
            </div>







          </div>

          <div className="" ref={secondContainer}>

            <h2 class="fw-normal mb-5">“As I grow older, I just want to be in the mountains. In the mountains, I am at peace with the world. ”</h2>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <h4 className="mb-2 text-brown">Have you always enjoyed climbing and mountaineering? What is it about these outdoor pursuits that appeals to you?</h4>
                <p className="mb-4">I have always been fascinated by mountains. Even as a small boy I remember looking at pictures of mountains and asking if people could climb them. “Mountaineers can” was the answer I was always given. I asked my parents to take me to a mountain. They took me to Bradgate Park in Leicestershire – can you imagine my disappointment? I was expecting the Matterhorn. But the real beginning was when I went on an Outward Bound Mountain Course when I was 17 and then joined the University Mountaineering Club the following year. I fell in love with mountains. The feeling of wellbeing they give you is amazing – even in bad weather. I also found that I liked rock climbing and I used to spend time on crags, experiencing the enjoyment of moving on rocks. When winter came, I went ice climbing. As I grow older, I just want to be in the mountains. In the mountains, I am at peace with the world. To me they have very powerful therapeutic qualities.</p>
                
              </motion.div>
              
              <motion.div variants={textAnimate} className="col-sm-6">

                <h4 className="mb-2 text-brown">From 2020 to 2022, how did you plan your time? Did you mainly walk alone? Which factors did you have to take into account?</h4>
                <p className="mb-4">I climbed the first few Munros with old friends, but they didn’t want to travel long distances – and I didn’t want to climb alone. So, my daughter Sally and I used social media to find walking companions. The response was amazing and I soon discovered that other hill walkers were interested in what I was doing. Once I had a nucleus of friends, I would wait for a good weather forecast and pick an area. I didn’t have a schedule as such: it was a case of watching the weather and contacting friends. Of course, during all of this I wanted to visit Janet in the care home as much as possible, but the doctors and staff said that I must continue with my climbing. Another factor was Covid – not being allowed to go into the hills was bad enough, but being prevented from visiting my beloved wife was dreadful. I don’t know how I got through those months. It might sound strange, but the climbing bit of my challenge was the easy bit.</p>

              </motion.div>
            </div>

            <div className="row mb-5">
              <motion.div variants={textAnimate} className="col-sm-6">
                <h4 className="mb-2 text-brown">You are based on the northwest coast. Logistically, you must have had to travel some distance to many of the summits. Did the trips involve wild camping, or how did you manage this?</h4>
                <p className="mb-4">Climbing the Munros in the southern ranges was very difficult because of the large distances – for example, Fort William is three hours away and the Perthshire hills are almost five hours’ drive. But last winter I had a wonderful piece of good fortune: a couple from Taynuilt, who owned some self-catering cottages, offered me the use of one of their cottages anytime during the low season as a base for climbing the Munros that are a long way from my home. I also bought a campervan, to allow me to be more flexible, but I regularly slept in the back of my car if it was more convenient. In the summer I did some bivvying or wild camping to access the remote hills.</p>

                <h4 className="mb-2 text-brown">Were any of the Munros more challenging than others? Are the biggest ones always the most difficult?</h4>
                <p className="mb-4">The degree of difficulty varies considerably. There are some areas where the Munros are so close together that many can be climbed in a day – on two occasions I managed to climb seven in a day. Also, there are some with a road that takes you to 2,000 feet, which makes climbing them easier even than climbing a Corbett or a Graham. In fact, there are lots of hills, particularly in the northwest, that are far more difficult to climb than many Munros – some are only accessible via long estate tracks, others require scrambling and even rock climbing. However, lots of the Munros are very demanding, in particular those on the Cuillin Ridge on Skye – these are my favourites. They form a magnificent ridge that I find exhilarating to traverse. I also love An Teallach in Wester Ross.</p>

                <h4 className="mb-2 text-brown">How did you feel after achieving all 282 summits? Is it possible to put the emotion into words?</h4>
                <p className="mb-4">Words cannot describe the emotion of walking through the “guard of honour” on that final day. It was surreal. I felt sorry that my beloved wife couldn’t be there, but if she could have been, there would have been no Munros Challenge.</p>

                <h4 className="mb-2 text-brown">You were awarded the title “Fundraiser of the Year 2022” in the Endurance category of the JustGiving Awards and in October 2021, you were invited to have afternoon tea with the then Duchess of Cornwall at Clarence House in recognition of your fundraising – both because you smashed your JustGiving target of £10,000, going on to raise over £82,000 for Alzheimer Scotland and the Royal Osteoporosis Society. Could you tell us more about your connection to these causes?</h4>
                
              </motion.div>
              
              <motion.div variants={textAnimate} className="col-sm-6">
                <p className="mb-4">Janet had been suffering from osteoporosis for 20 years, with periods when she was in a lot of pain. Then in 2016 I began to notice behavioural changes and she was eventually diagnosed with Alzheimer’s and Vascular Dementia. It wasn’t such a shock as I knew there was something seriously wrong. When I had decided on my challenge, I contacted both charities. But I always hasten to add that the initial spark for the challenge was me. I knew that I had to do something – though the charity connection came very quickly afterwards. The support and encouragement I have had from people has been unbelievable and I cannot thank my friends, old and new, enough. The figure raised has exceeded my wildest dreams and if my knees behave I hope to raise more. </p>
                <h4 className="mb-2 text-brown">Do you have any advice for people wanting to attempt all 282 Munros? What are the essentials for your rucksack? </h4>
                <p className="mb-4">For inexperienced people who want to climb the Munros, they must be aware that they are dealing with mountains. They are very beautiful, but they are also very dangerous, and every year people are killed on our mountains. I recommend joining a club, buying appropriate gear and attending skill-building courses. If you plan on going alone, a map and a compass – and knowing how to use them – are essential. I’d also recommend packing survival gear, a first aid kit, a torch and spare batteries, as well as water and food. You must always make provisions for an emergency and possibly an overnight bivvy. Mobile phones make things easier, but they must not replace the map and compass because there is no guarantee that there will be a signal.</p>
                <h4 className="mb-2 text-brown">How do you spend your time when you’re not out in the mountains?</h4>
                <p className="mb-4">Before Janet’s diagnosis, we both spent all our time in the garden and were self-sufficient. I have now moved house to be close to Janet. My new home still needs work doing on it. I visit Janet every day unless I go climbing or visit family and friends – I know that she is well cared for in the residential home.</p>

                <motion.div variants={textAnimate} className="bg-brown p-4 mt-5">
                  <p className="mb-0">Follow Nick on Instagram: <br/>@nicks.munro.challenge</p>
                </motion.div>

              </motion.div>
            </div>

          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article13;
