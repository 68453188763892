import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article4() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0
  });



  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';
      var img3 = document.getElementById("img3");
      img3.className = '';

      return "";
    }
    else if (thirdInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article5');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article3');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article4-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article4-img2.jpg")} />
          <img id="img3" className={getAnimationClass()} src={require("../../Images/article4-img3.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article4-img1.jpg")} alt="" />
          </motion.div>
          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <div variants={textAnimate}>
              <h1 className="fs-xxl fw-normal mb-5 pb-3 d-inline-block lh-1"><span className="f-lora-i d-block">in</span> <span className="fw-semibold ls-4">SCOTLAND</span> <span className="text-end d-block f-lora-i">we surf</span></h1>
            </div>
            <p className="mb-5 font-italic" variants={textAnimate}>Scotland’s varied coastline is drawing a surf crowd with awe-inspiring tales to tell. It’s a diverse group celebrating the values of mental wellbeing, community and environmental awareness. Now, the surfers’ stories have been told in the photographic biography ‘In Scotland We Surf’. Here, its creator, surfer-photographer Sam Howard, reports on 36 months chasing the waves.</p>

            <img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article4-img4.jpg")} />
            <img variants={textAnimate} className="img-fluid mb-5" src={require("../../Images/article4-img5.jpg")} />

            <p className="font-italic text-blue" variants={textAnimate}>“With three different sides to Scotland, there’s usually a wave to be surfed somewhere,” says Aberdeenshire photographer and surfer Sam Howard, with apologies if some of his latest email was incoherent. It was sent at 2am – testament to the hours he’s been putting in balancing his documentary work and ‘In Scotland We Surf’, a photographic biography of the emerging Scottish surf community, including performance athletes, surf charities, musicians, craftspeople and environmental ambassadors.</p>
            <p className="" variants={textAnimate}>He might have been burning the midnight oil, but his passion for Scotland and its surf scene is delivered clearly. “The sunsets, the sunrises, the brutal weather and long summer days,” ponders the 26-year-old, who started surfing while he was studying for a master’s in engineering at Robert Gordon University in Aberdeen. He speaks of a diverse coastline, with clean, cold water that is “good for your body”, and an abundance of non-threatening wildlife – no dangerous sharks, for example, that can be found in surf hotspots. “And there’s something super satisfying about wrapping up warm after a surf and tucking into a chipper.”</p>
            <p className="" variants={textAnimate}>Sam should know. While compiling ‘In Scotland We Surf’, he spent a year and a half travelling Scotland “between work and swells”, with “a lot of time on the road, ferries and sofas, allowing tides and charts to sporadically run my calendar”, all while meeting and photographing more than 85 Scottish surfers. He was keen to focus on people rather than places, to showcase a community that is often overlooked by mainstream surf media. Some surfers shared their passions, lifestyles, or hobbies; others preferred their stories to be kept secret. </p>
            <p className="" variants={textAnimate}>He says that every shoot was special in its own way – be it a beautiful life story, an amazing location, or even a meaningful final portrait. One memorable experience was listening to traditional music in Celtic Chords Stonehaven, a music shop down the road from Sam’s old primary school, where owner Pete Murray spoke of his surf trips. “It was amazing to hear he was surfing the local spots back in the 1980s and the same waves we surf now,” says Sam. Pete told him that although “surfing was great for fitness, it sometimes made it difficult to hold down a relationship…”. He went on to explain: “When you try to describe the feeling of catching a really good wave to someone who doesn’t surf, they just can’t fully understand. It’s an incredible rush and flow; it’s so addictive. For 30 years of my life, I lived for surfing.”</p>
            <p className="mb-5" variants={textAnimate}>The impetus for ‘In Scotland We Surf’ came during lockdown, when Sam, like many others, had a tough time with his mental health through not being able to do the things he loved. “When the rules began to lift, I started dedicating time to getting into the water each week, taking photos of the waves. The release of endorphins and swimming out into the North Sea at sunrise helped me feel I’d accomplished something. Meeting people at the beach and in the water sparked a feeling of normality in a very weird time,” he remembers.</p>







          </div>

          <div className="" ref={secondContainer}>


            <div className="row align-items-center mb-5">
              <div variants={textAnimate} className="col-sm-6 mb-3">
                <img className="img-fluid"
                  src={require("../../Images/article4-img6.jpg")}
                />
              </div>
              <div variants={textAnimate} className="col-sm-6">
                <h4 className="fs-md mb-2">Alison Young</h4>
                <h5 className="mb-4">Coordinator, Wave Project Scotland</h5>
                <p className="mb-0">Alison is kind and caring, with an infectious energy that makes everyone feel involved and special. This makes her a great leader at Wave Project Scotland, which has a huge impact on local communities through surf therapy that rebuilds confidence in youngsters who need it. </p>
              </div>
            </div>

            <div className="row flex-row-reverse align-items-center mb-5">
              <div variants={textAnimate} className="col-sm-6 mb-3">
                <img className="img-fluid"
                  src={require("../../Images/article4-img7.jpg")}
                />
              </div>
              <div variants={textAnimate} className="col-sm-6">
                <h4 className="fs-md mb-2">Jason Burnett</h4>
                <h5 className="mb-4">Founder, Jay Surfboards</h5>
                <p className="mb-0">Scottish surfboard shaper and artist Jay is globally renowned for his incredibly beautiful surfboards, resin tints and experimental designs. A month after Sam captured his photo, Jay was hit hard, losing his workshop, tools, art and boards to a fire. The local and global surf community rallied behind him with a fundraiser that reached over £11,000. His shop is now rebuilt and he’s back in business.  </p>
              </div>
            </div>

            <div className="row mb-5">
              <div variants={textAnimate} className="col-sm-6">
                <p className="">In the same way surfing and photography helped him out of lockdown, he wanted to help others, and he decided to put his talents to good use. “I wanted to help people, share their stories and encourage them to try new activities,” he says. Incidentally, photography and surfing had provided a solace for him at university as he studied for an academic degree with undiagnosed dyslexia. He started planning a project that would capture photographs of people in the outdoors, visually representing their stages of happiness. He was approached by a local business and design consultancy, Dark North, who, also avid surfers, were keen to support his project if it was more surf orientated. ‘In Scotland We Surf’ was born.</p>

                <p className="">“What’s funny is,” says Sam, “if you look back five or six years, 95 per cent of people wouldn’t have even known you could surf in Scotland.” The scene is now growing rapidly with junior competitions, surf schools and surf charities, including Surfable, Groundswell and Wave Project Scotland, which offer surfing to adults and children with disabilities or those at risk of mental ill-health. “I’ve seen quiet, nervous kids become more confident in just a single surfing session,” says Sam. </p>

                <p className="">What’s more, the Scottish Surfing Federation has been pushing the sport’s development across the country, and Scotland’s youngsters are brushing shoulders with some of the best surfers in the world. In 2022 alone, Craig McLauchlan of Thurso was crowned under-18 British champion; Ben Larg from the Isle of Tiree signed with Red Bull and has taken on some of the world’s biggest and heaviest waves. His younger sister Robyn secured the Scottish under-18 title; and Callie and Kai Cruickshank of Fraserburgh became under-12 Scottish champions as well as securing podium finishes at the UK Rip Curl GromSearch competition for young surfers. “The talent is booming,” says Sam, whose project aims to show there is a “surf scene here with a bright future ahead”.</p>

                <p className="">But surfing isn’t a new thing in Scotland – even if the country has long been portrayed in surf media as “a barren landscape of empty line ups”. In fact, there have been smaller groups surfing here since the late 1950s. “The first Scottish surfer is 80-years-young, William ‘Willy’ Tait,” says Sam. The story goes that the Fraserburgh fisherman travelled south in search of surfing after seeing it on the television, “bringing the first surfboard back to Scotland”. He surfed by himself for a couple of years until another local from “the Broch” took an interest. Together they </p>
              </div>
              <div variants={textAnimate} className="col-sm-6">

                <p className="">built a board so they could go out in the water together. By the time the 1960s arrived, another surf community was brewing a little south of Edinburgh: Andy Bennets had discovered surfing during a holiday to Cornwall and he and his pals began scouting out the best surfing spots around the east coast. Meanwhile in Glasgow, Ian McKay and his crew were venturing west, says Sam, “still pretty certain it was the only place in Scotland there were good waves”. Gradually, as they started to explore, the different communities met. Surfing took them to the furthest corners of Scotland, leading them to some of the best-known waves surfed today.</p>

                <p className="">“Scotland is so unique as a surfing destination,” says Sam, who during his trip had run-ins with feisty seals and saw a surf competition called off by passing orcas. “You often have to travel to find the waves, going to incredible places off the beaten track.” But it’s important to be aware of the dangers: the swells and winds are unpredictable, and the cold, variable weather and water, short days, and sometimes lack of surfers can increase risk. That said, he considers Scotland to be a great place to learn the basics, thanks to its quiet waves – as long as you have a good wetsuit and go out nearer the summer months. “The small wind swells make more gentle waves for beginners and there’s a good bunch of great surf schools throughout the country to help people get going,” he says.</p>

                <p className="">What stands out most from conducting the project? “The positive impact surfing has on peoples’ physical and mental health: being out in the ocean in a constantly changing environment can almost overload the senses, but in a good way. Your mind is focused on the movement of the water, or where the next wave is coming from,” says Sam. And there’s the sense of community that comes with it, with surfers supporting each other’s creative pursuits and small businesses – many of which have an environmental angle. Surf Coo, for example, run by Jo-Anne in the northeast, who spends her time surfing and sewing ethical fleeces for surfers.</p>

                <p className="">What Sam remembers when looking back are lots of waves, new faces and lifelong friends. “Adventures in new corners of the country, a deeper understanding of the culture, the talent and kindness of the Scottish surf community…” he muses. “The potential it holds, the impact it’s having, and the joy a shared passion brings.”</p>
              </div>

            </div>


          </div>

          <div className="mb-5" ref={thirdContainer}>
            <div className="row align-items-center mb-5">
              <div variants={textAnimate} className="col-sm-6 mb-3">
                <img className="img-fluid"
                  src={require("../../Images/article4-img8.jpg")}
                />
              </div>
              <div variants={textAnimate} className="col-sm-6">
                <h4 className="fs-md mb-2">Phoebe Strachan</h4>
                <h5 className="mb-4">Female Scottish Champion</h5>
                <p className="mb-0">Whether surfing, farming or working as a joiner, Phoebe is known for tackling “male-dominated” fields head-on. She takes on heavier waves than some of the best male surfers in the country and is paving the way for up-and-coming female surfers in Scotland. She has also worked with the North Shore Surf Club, playing a huge role in the inclusivity of Scottish surfing.</p>
              </div>
            </div>

            <div className="row flex-row-reverse align-items-center mb-5">
              <div variants={textAnimate} className="col-sm-6 mb-3">
                <img className="img-fluid"
                  src={require("../../Images/article4-img9.jpg")}
                />
              </div>
              <div variants={textAnimate} className="col-sm-6">
                <h4 className="fs-md mb-2">Ben Larg</h4>
                <h5 className="mb-4">Up-and-coming big wave surfer</h5>
                <p className="mb-3">became a professional surfer at just 17. His recent signing with Red Bull and O’Neill Wetsuits has allowed him to chase some of the biggest waves around the world. When asked about the growth of surfing, he told Sam: “Surfing has brought me so much happiness. Everyone has the right to that happiness.” </p>
                <hr />
                <p className="mb-0 font-italic fs-sm">In Scotland We Surf will culminate in an exhibition in 2024, displaying a selection of surfers’ portraits, stories and photography. Sam’s goal is to develop a coffee table book featuring all the surfers that will act as a time stamp in the history of Scottish surfing. </p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article4;
