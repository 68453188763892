import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article5() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article6');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article4');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 700); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article5-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article5-img4.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article5-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <div
              variants={textAnimate}
            >

              <h1 className="fw-normal mb-4">FINE LINES</h1>
              <h4 className="mb-4">Malcolm Appleby</h4>

            </div>
            <p className="font-italic mb-5" variants={textAnimate}>An alchemistic experiment with a blow torch and a square of 24-carat gold led Malcolm Appleby MBE to pioneer a novel technique in metalwork. Since then, the maverick silversmith has gone from commission to highprofile commission, showcasing his unique talent in delicate fine art that pays homage to Scotland’s natural world.</p>
            <div variants={textAnimate} className="row mb-4">
              <div className="col-6">
                <img className="img-fluid"
                  src={require("../../Images/article5-img2.jpg")}
                />
                <p className="mt-2 mb-0 fs-sm fw-semibold">Birch Tree Banchory Bangle</p>
              </div>
              <div className="col-6">
                <img className="img-fluid"
                  src={require("../../Images/article5-img3.jpg")}
                />
                <p className="mt-2 mb-0 fs-sm fw-semibold">Flower Pom Pom Pendant</p>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-sm-6">

                <p className="" variants={textAnimate}>Malcolm Appleby’s Perthshire home grew around his
                  atelier, so this is the first room he finds himself in after
                  he wakes at 6am. “I like to get some work done before
                  my assistant arrives at 8am,” says the revered silversmith
                  and engraver, now 76, who can usually be seen sporting
                  a jumper knitted by his mother that is now a darned
                  kaleidoscope of yarns. On his plot of land in Aultbeag,
                  Grandtully, which had planning permission for “a boring
                  bungalow”, he and his wife Philippa (Swann, a writer and
                  photographer) have built a collage of an Arts & Crafts-style
                  house, with “bits stuck on over the years”. It is surrounded
                  by an acreage, where a woodland garden sprouts oak and
                  hazel saplings and is latticed with great juicy apple trees.
                  “I’m planting lots of trees…” says Malcolm, “which is one
                  of my great pleasures, and it’s as important as the work”</p>
                <p className="" variants={textAnimate}>The work: it’s exquisite, idiosyncratic and delightfully
                  creative, melding silver and gold like fine paintwork,
                  interplayed with more humble metals. Rare and precious
                  stones feature too, and the result is as dainty as a butterfly’s
                  wing. His portfolio includes gossamer-fine brooches with
                  plaid detailing, a silver hawthorn leaf pendant wrapped so
                  delicately around a pale amethyst it looks like it hardly dare
                  touch the precious stone, and slashed beakers of textured
                  metal, gilded, rubbed and engraved to add tone and depth.</p>

              </div>
              <div className="col-sm-6">

                <p className="" variants={textAnimate}>His is a devil-may-care artistic talent that has always
                  attracted high-profile clients: his commissions, right from
                  the off, have included fine art objects for The Royal Family
                  (he engraved the orb of the Investiture crown of the Prince
                  of Wales in 1969), 10 Downing Street (a condiments set) and
                  the National Museum of Scotland (a silver-gilt, rock crystal
                  and enamel cup and cover). But he also makes production
                  pendants, cufflinks and bangles from hand-carved casts,
                  including a popular ‘Walk in the woods’ charm necklace
                  with silver flowers and leaves dancing among labradorite,
                  tourmaline and pearls. And these, he says, should have the
                  same inspirational quality as larger works. </p>

                <p className="" variants={textAnimate}>It all started in 1946 in a Kent suburb. “I lived in Coney Hall,
                  which sounds very salubrious, but it wasn’t,” says Malcolm,
                  the son of a car park attendant and a dressmaker. “It was
                  a street of pre-war semis with ancient woodland at one
                  end and fields at the other. It was the ancient woods and
                  the fields that really interested me.” Inspired by the doorto-door salesmen of the 1950s, he sold his first piece of
                  artwork to a neighbour at the age of four – a folded paper
                  creation – and went to art school (Beckenham School of
                  Art) at 15. During this time, he became aware of the trade
                  of gun engraving after visiting John Wilkes, a traditional
                  firm of gun makers on Beak Street in Soho, London, with</p>
              </div>
            </div>
            
          </div>

          <div className="" ref={secondContainer}>

            <div className="row mb-5">
              <div variants={textAnimate} className="col-sm-6">
                {/* <h3 className="text-gold fw-normal mb-3 pe-4">“… it’s exquisite,
                  idiosyncratic and
                  delightfully creative,
                  melding silver and gold
                  like fine paintwork,
                  interplayed with more
                  humble metals.”</h3> */}
                <img className="img-fluid mb-3"
                  src={require("../../Images/article5-img5.jpg")}
                />
                <p className="">his Uncle Jerry who was in the Navy. He was seven years old at the time and never forgot it: “It was the place and the atmosphere, the dust… the smell of oils and walnut.”</p>
                <p className="">Malcolm was intrigued and inspired, and after relocating to
                  Ravensbourne College of Art & Design, he began designing
                  in his own style of gun engraving, which he touted around
                  London gunmakers. Wilkes of Soho were the only ones
                  to show any interest. To develop his skills he transferred
                  to the Central School of Arts & Crafts, where his talents
                  earned him a travel scholarship from the Goldsmiths’
                  Company worth £100, “which was quite a bit of dosh in
                  1964”. Not only was it an important introduction to the
                  Worshipful Company of Goldsmiths, it also marked his
                  first foray into Scotland: instead of going abroad, “like you
                  were supposed to with a travel grant”, he hitchhiked with
                  his brother to Orkney, “which was a bit of an adventure,
                  because we went in March, sometimes travelling in the
                  dead of night, with howling gales blowing through us.</p>
                <p className="">Malcolm thumbed lifts to Scotland and back frequently over the following years – “it was where the inspiration was” – while studying at Sir John Cass and then the Royal College of Art. It was here that he really got going, pioneering a new technique in metalwork by blasting two small squares of heavily engraved steel white hot and melting 24-carat and other colours of gold over the surface. “I wanted to see what would happen. I worked a lot with steel engraving </p>
              </div>
              <div variants={textAnimate} className="col-sm-6">
                <p className="">and gold inlay, and the normal technique is punching or hammering the gold in,” he explains. When he turned the flame off, “there were two squares of filthy, black mess. It looked awful. The other students were smirking over my shoulder (…) I had to get myself out of it somehow.”</p>
                <p className="">His solution? Pouring sulphuric and nitric acid onto the metal. “There was this acrid brown smoke coming out of the flask,” remembers Malcolm. “But as I watched, it all got burnt away by the acid and an amazing gold painting appeared.” He describes it as looking like a massive enlargement of a section of a Rembrandt work, rich and full of texture. “It turned me from being a metalworker into an artist. Normally you’re inspired by other people’s work, but this was something I had done myself, and I was inspiring myself,” he says. </p>
                <p className="">Always one to throw whatever he wanted at the wall – he’s gone on to experiment with the artistic potential of metal throughout his career – his days at the Royal College were numbered. “I loved it, it was fantastic,” he says. “But they treated me as if I was going to be a trade engraver, which I never was going to do. It was the art that interested me.” After two years, his tutor said it was time he left. But every cloud and all that. Because everyone wanted him. “The week I left I got commissions, commissions, commissions,” says Malcolm. One of them was from the Worshipful Company of Goldsmiths, who bought an early piece for their collection for the princely sum of £175. He was, at 22, the most wanted engraver in town. </p>
                <p className="">In 1969, he finally moved to Scotland, buying the Victorian former Railway Station at Crathes, Banchory for 1,600 pounds, seven shillings and four pence. He lived and worked there for 27 years, leaving the building largely in its original state. In 1996, he moved to Grandtully, where he still lives and is inspired by the Scots pine and wildflowers, the intricate fungi and the chestnuts and acorns. His is a work of love, and commissions can take anything from months to years. “Or I can make something spontaneously and it doesn’t take long at all, but creatively it may have equal status,” explains Malcolm. </p>
                <p className="">His is a work loaded with emotion, too. The commissions are not, he says, “standard jobs being knocked out”. He made a pair of candlesticks for St. Giles Cathedral in Edinburgh, where the Queen lay in state. “The candlesticks were just next to her coffin. It was a great honour to have them there,” says Malcolm. They were a commission for an anonymous donor, whose last wish was to give them to the cathedral. “It was all made in a mad rush, and I had great help from a lot of people to get the job done on time, </p>
              </div>
            </div>

            <img className="img-fluid mb-5"
                  src={require("../../Images/article5-img6.jpg")}
                />

            <div className="row mb-5">
              <div variants={textAnimate} className="col-sm-6">
                <p className="">before he died,” recalls Malcolm sadly. “We were able to show him the piece just before it was hallmarked, and we gave him a little book all about it. He died the next day. It was a very emotional moment.” </p>
                <p className="">These days, Malcolm “can’t work the hours I used to”. He remembers finishing the condiments set for Downing Street in 1987, working three shifts a day, until 2am, to get the job done. “Things have changed. The body’s changed,” he says. He’s got enough commissions. He likes doing one-off pieces for the sheer enjoyment and he still engraves guns in his Grandtully atelier. Every year he produces the Banchory Bangle to be raffled by the Children 1st Deeside Committee, which supports families in need. The 2022 design features a song thrush in a birch tree to deliver the message that our natural world is important for children’s wellbeing and the health of future generations. </p>
                <p className="">“We need to bring up our children in a clean environment. It’s our lives. It’s our environment. It’s our world. And we’ve got to do as much as we can to hang onto it,” says Malcolm. “I suppose by doing these bangles, and a lot of the other work I do, I’m expressing that. But it’s no good unless something else is done to improve our environment and biodiversity.” He reuses and recycles as much as he can, and in his studio you’ll find vices he bought while  </p>
              </div>
              <div variants={textAnimate} className="col-sm-6">
              <p className="">at school, adjustable bench pins made from charity shop rolling pins, and doming blocks made from trees around his house. Even his designs sometimes feature recycled metal: he recently rediscovered a kilt belt buckle he made using a scrap Damascus gun barrel. “The piece had already had one lifetime of approximately 100 years before being made into this buckle,” he wrote in an Instagram post. “It could exist for many hundreds of years now.”
 </p>
              <p className="">For his services to hand engraving, he received an honorary D-Litt from Heriot-Watt University in 2000, was appointed Member of the Order of the British Empire (MBE) in the 2014 Birthday Honours, and was honoured by being appointed FRSE (Fellowship of the Royal Society of Edinburgh) in 2021. He also works with up-and-coming designers, sharing his skills and expertise, and regularly hosts symposiums. It’s the fact of making people feel comfortable and happy though that is, for Malcolm, “one of my greatest achievements”. He says: “If we can make people happy and make them aware of our environment around us, then all the better.”
 </p>
              <p className="mt-5 fw-semibold font-italic">To view more of Malcolm’s work, visit his Instagram pages:  @malcolmappleby46 @malcolm_appleby_engraving Open by appointment only </p>
                
              </div>
            </div>

          </div>


          </div>



        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article5;
