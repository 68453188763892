import React from "react";
import { RouterProvider } from "react-router-dom";

import MyRoutes from "../Routes/Routes";

const Layout = ({ children }) => {
  return (
    <>
      {/* <MyRoutes /> */}
      <RouterProvider router={MyRoutes} />
    </>
  );
};

export default Layout;