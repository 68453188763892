import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article12() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0
  });

  const { ref: fourContainer, inView: fourInView } = useInView({
    threshold: 0
  });

  const { ref: fiveContainer, inView: fiveInView } = useInView({
    threshold: 0
  });

  const { ref: sixContainer, inView: sixInView } = useInView({
    threshold: 0
  });

  const { ref: sevenContainer, inView: sevenInView } = useInView({
    threshold: 0
  });

  const { ref: eightContainer, inView: eightInView } = useInView({
    threshold: 0
  });

  const { ref: nineContainer, inView: nineInView } = useInView({
    threshold: 0
  });

  const { ref: tenContainer, inView: tenInView } = useInView({
    threshold: 0
  });

  const { ref: elevenContainer, inView: elevenInView } = useInView({
    threshold: 0
  });



  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (thirdInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = 'active';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (fourInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = 'active';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (fiveInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = 'active';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (sixInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = 'active';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (sevenInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = 'active';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (eightInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = 'active';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (nineInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = 'active';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (tenInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = 'active';
      var img3 = document.getElementById("img11");
      img3.className = '';

      return "";
    }
    else if (elevenInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = '';
      var img3 = document.getElementById("img3");
      img3.className = '';
      var img3 = document.getElementById("img4");
      img3.className = '';
      var img3 = document.getElementById("img5");
      img3.className = '';
      var img3 = document.getElementById("img6");
      img3.className = '';
      var img3 = document.getElementById("img7");
      img3.className = '';
      var img3 = document.getElementById("img8");
      img3.className = '';
      var img3 = document.getElementById("img9");
      img3.className = '';
      var img3 = document.getElementById("img10");
      img3.className = '';
      var img3 = document.getElementById("img11");
      img3.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article13');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article11');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article12-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article12-img4.jpg")} />
          <img id="img3" className={getAnimationClass()} src={require("../../Images/article12-img5.jpg")} />
          <img id="img4" className={getAnimationClass()} src={require("../../Images/article12-img6.jpg")} />
          <img id="img5" className={getAnimationClass()} src={require("../../Images/article12-img7.jpg")} />
          <img id="img6" className={getAnimationClass()} src={require("../../Images/article12-img8.jpg")} />
          <img id="img7" className={getAnimationClass()} src={require("../../Images/article12-img9.jpg")} />
          <img id="img8" className={getAnimationClass()} src={require("../../Images/article12-img10.jpg")} />
          <img id="img9" className={getAnimationClass()} src={require("../../Images/article12-img11.jpg")} />
          <img id="img10" className={getAnimationClass()} src={require("../../Images/article12-img12.jpg")} />
          <img id="img11" className={getAnimationClass()} src={require("../../Images/article12-img13.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article12-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
            <div className="" ref={firstContainer}>
              <div className="row align-items-center mb-5">
                <div className="col-sm-7 mb-4">
                  <motion.div variants={textAnimate}>
                    <h1 className="fs-xl fw-light mb-1">CAITHNESS</h1>
                    <h3 className="mb-3 fs-md">Discovering the nooks & crannies.</h3>
                  </motion.div>
                  <motion.p className="mb-0 font-italic" variants={textAnimate}>Viking history, fishing harbours and a sense of faraway adventure. To the average traveller, John O’Groats is a more famous name than Caithness itself, which says something about the region’s place in public consciousness. But for those who make the effort to journey here – and its position on the North Coast 500 route means there are plenty who do – the rewards are substantial.</motion.p>
                </div>
                <div className="col-sm-5 text-sm-end">
                  <motion.img variants={textAnimate} className="img-fluid" src={require("../../Images/article12-img2.jpg")} />
                </div>
              </div>


              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h3 className="mb-5">Getting <br />there</h3>
                  <img className="img-fluid mb-5"
                    src={require("../../Images/article12-img3.png")}
                  />
                  <p className="fs-sm mb-4">TRAVEL // <br />To give an idea on travel time by road, see distances below to John O’Groats:</p>
                  <ul className="list-unstyled fs-sm">
                    <li className="d-flex justify-content-between mb-2">
                      <span>ABERDEEN</span>
                      <span>5 hrs, 00 mins</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2">
                      <span>DUNDEE</span>
                      <span>5 hrs, 00 mins</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2">
                      <span>EDINBURGH</span>
                      <span>5 hrs, 30 mins</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2">
                      <span>GLASGOW</span>
                      <span>5 hrs, 30 mins</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2">
                      <span>INVERNESS</span>
                      <span>2 hrs, 40 mins</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2">
                      <span>PERTH</span>
                      <span>4 hrs, 40 mins</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2">
                      <span>STIRLING</span>
                      <span>5 hrs, 00 mins</span>
                    </li>
                  </ul>
                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-6">
                  <p className="">Caithness is a place on the edge. Rough-hewn cliffs and windswept gorse characterise this far-flung corner of the map, the so-called ‘Land Beyond the Highlands’, the place where the north-eastern tip of the mainland reaches out into the wild waves of the North Sea. It’s a region where the rocks, moors and hills have an elemental rawness to them, a semi-mystical feel that can sometimes make the whole headland seem closer in spirit to Orkney and Shetland – both of which lie off its coast – than to the rest of the country.</p>
                  <p className="">But while this is a remote land almost entirely bounded by the sea – the only exception being its western edge, which forms a land boundary with neighbouring Sutherland – its human history is pronounced. Today some 25,000 people live up here, concentrated on the small towns of Wick and Thurso and a scattering of smaller villages and coastal settlements, among them the iconic outpost of John O’Groats. Edinburgh feels a long way away indeed.</p>
                  <p className="">This is, however, no mountainous retreat – instead you’ll find jagged sea stacks, stony outcrops and rolling farmland, as well as a character and prehistory that is very much Caithness’ own. Its location also means there’s plenty of Norse heritage to explore. Back in 2016, when a new county flag was unveiled, the chosen design centred on a blue and gold Nordic cross – blue for the sea, gold for the beaches – on a black background that represents the dark local stone. In the corner of the flag, meanwhile, stands a galley, its sails billowing, providing a reminder that the local gaze remains a seaward one.</p>
                </motion.div>
              </div>

              <motion.img variants={textAnimate} className="img-fluid mb-5" src={require("../../Images/article12-img3.jpg")} />
              <motion.div variants={textAnimate} className="mb-5">
                <h3 className="mb-3">History</h3>
                <p className="">It’s said that there are more brochs in Caithness than in any other Scottish county. These drystone Iron Age remains hint at how much activity there was here in ancient times, with numerous cairns and standing stones adding to the prehistoric picture. Later, the area was part of a Pictish province named Cait when it was invaded by Vikings, most likely in the 10th century, an arrival that means many of today’s place names – and even some local surnames – have Norse origins. By the late 12th century, however, Scottish control had been implemented and the region was incorporated into the sheriffdom of Inverness, before later becoming an independent county in 1707.</p>
              </motion.div>



            </div>

            <div className="" ref={secondContainer}>
              <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article12-img14.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article12-img15.jpg")}
                  />
                </div>
              </div>
              <h3 className="mb-3">See & Do</h3>
              <p className="mb-5">Many of Caithness’ attractions are shaped by the region’s long history and geographical remoteness, meaning you’ll find drama in spades.</p>

              <motion.img variants={textAnimate} className="img-fluid mb-5" src={require("../../Images/article12-img16.jpg")} />


            </div>

            <div className="mb-5" ref={thirdContainer}>
              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Keiss Castle, Near Wick</h4>
                  <p className="mb-4">Standing precariously on a clifftop precipice, as if it were some sort of monstrous geological feature in its own right, the ruined Keiss Castle is – or rather, was – a fine coastal stronghold a few miles north of Wick. It was built as a Z-plan tower house in the 16th century, and was a favoured residence of George Sinclair, the 5th Earl of Caithness.</p>

                  <h4 className="mb-2">Castle Sinclair Girnigoe, Near Wick</h4>
                  <p className="mb-4">Even closer to Wick – and arguably even more picturesque – is Castle Sinclair Girnigoe, first constructed around 1470 and subsequently enlarged, but uninhabited since 1690. Its current state of photogenic decay, balanced above the raging North Sea, conceals a prestigious past that once made it one of the most important bases in the region. In addition, it was occupied by Oliver Cromwell’s troops during the Civil War.</p>

                  <h4 className="mb-2">Dunnet Head Nature Reserve</h4>
                  <p className="">A rewarding place to visit at any time of year, this nature reserve – which occupies the most northerly point of mainland Britain – is a particular joy in the spring and summer, when nesting seabirds turn it into an avian nursery. Puffins, razorbills, guillemots and more all flock to this gloriously windswept peninsula.</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Dunnet Beach</h4>
                  <p className="mb-4">There are beaches, and there are beaches. This two-mile arc of pale, dune-backed sands is as wild and wonderful as it gets, with vast empty views and, even in high summer, few crowds. Dogs are welcome – and there’s surely no greater treat for a pooch than an hour spent scampering along the sands.</p>

                  <h4 className="mb-2">The Whaligoe Steps, Near Wick </h4>
                  <p className="mb-4">Three hundred and sixty five handmade steps still form a precipitous, zigzagging route between the natural harbour of Whaligoe Haven and the clifftop above. They’ve been in use for more than 200 years, chiefly by local fishermen and fisherwomen hauling their catch up to market – herring, salmon and shellfish have all traditionally been abundant.</p>

                  <h4 className="mb-2">Thrumster Station, Near Wick</h4>
                  <p className="">Once part of the UK’s most northerly light railway line, which ran between Wick and Lybster, Thrumster Station has been out of action since 1944. Thanks to lottery-funded restoration, however, it still has a handsome bearing, with neat black and yellow signage and a carefully restored station building adding to the sense of nostalgia.</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Achavanich Standing Stones, Near Lybster</h4>
                  <p className="mb-4">Gazing out across Loch Stemster, and witness to who knows how many centuries of ancient ritual and remembrance, the enigmatic Achavanich Standing Stones are thought to be around 4,000 years old. This was clearly a site of some importance – some 35 stones are still standing, arranged in an unusual horseshoe shape, with a further six having fallen.</p>

                  <h4 className="mb-2">Ebenezer Place, Wick </h4>
                  <p className="mb-4">Ebenezer Place takes around the same amount of time to walk down as it does to say out loud, and since 2006 has been officially declared the world’s shortest street by the Guinness Book of Records. It’s just over two metres long and is, essentially, an angled doorway on the side of Mackays Hotel in Wick. One for the been-there-done-that travel completists.</p>

                  <h4 className="mb-2">The Grey Cairns of Camster, Near Lybster</h4>
                  <p className="">Few prehistoric remains are so imposing as this pair of Neolithic tombs, lying on a lonely moor and restored to show the scale and craftsmanship of the 5,000-year-old originals. Both structures – there’s a long cairn and a round cairn – have chambers and compartments inside, standing testament to customs long gone.</p>

                </motion.div>
              </div>
            </div>

            <div className="mb-5" ref={fourContainer}>
              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">Duncansby Head, Near John O’Groats</h4>
                  <p className="mb-4">Despite many assuming that John O’Groats takes the title of being the most northeasterly tip of the British mainland, that honour goes in fact to the more feral (and far less commercial) Duncansby Head, where the cliffs of the headland plunge into the waves and angular sea stacks rise offshore.</p>

                  <h4 className="mb-2">The Trinkie, Wick</h4>
                  <p className="mb-4">This natural saltwater swimming pool – one of two in the local area, remarkably – is something of a Wick institution. Carved into the coastal rocks, and cleaned and repainted as and when needed, the century-old Twinkie is where to come for a bracing but memorable dip in the blue.</p>

                  <h4 className="mb-2">North Coast Visitor Centre, Thurso</h4>
                  <p className="">Home to a permanent exhibition spread across three floors, the absorbing North Coast Visitor Centre (formerly Caithness Horizons) occupies the 19th-century Thurso Town Hall and the adjacent Carnegie Library. You’ll be able to trace the story of Caithness through the ages via Pictish artefacts, botanical collections and even an overview of the area’s role in nuclear power development.</p>

                </motion.div>

                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">Castle of Mey, Near John O’Groats</h4>
                  <p className="mb-4">An elegant country pile looking out across the Pentland Firth and the Orkney Islands, the Castle of Mey was owned (and much loved) by the Queen Mother from the 1950s until 1996. It’s usually open from late spring through to early autumn, and guided tours of the castle and its walled gardens give an entertaining overview of both the building and the Queen Mum.</p>

                  <h4 className="mb-2">Pulteney Distillery, Wick </h4>
                  <p className="mb-4">Created in the 1820s as a means of providing firewater for the many thirsty folk employed in the local herring trade, this well respected distillery still offers a treat for whisky-lovers. It’s known for its Old Pulteney single malt, a heavy, fragrant drink with enjoyable depth of flavour. Book a tour to get the full lowdown – and to sample a dram.</p>

                  <h4 className="mb-2">Dunnet Bay Distillery, Dunnet</h4>
                  <p className="">Home to the multi-award-winning Rock Rose Gin – and relying on two bespoke copper pot stills named Elizabeth (after the Queen Mum) and Margaret (after the owner’s mum!) – Dunnet Bay Distillery offers guided tours throughout the year. Pre-booking is highly advised. The distillery is a five-star Visit Scotland attraction and has Green Tourism gold certification, so it’s well worth a visit.</p>

                </motion.div>
              </div>
            </div>

            <div className="mb-5" ref={fiveContainer}>
              <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article12-img17.jpg")} />
              <motion.img variants={textAnimate} className="img-fluid mb-0" src={require("../../Images/article12-img18.jpg")} />
              <p class="mt-2 mb-0 fs-sm fw-semibold">Opposite page: Bay of Sannick, this page: The Castle & Gardens of Mey and The Trinkie</p>

            </div>

            <div className="mb-5" ref={sixContainer}>
              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">Puffin Cove, Near Thurso  </h4>
                  <p className="mb-4">A reasonably straightforward but occasionally steep trek from a layby brings you to Puffin Cove, where in breeding season (roughly April to July) you’ll see sizable numbers of Britain’s most lovable little seabird. You won’t see puffins there at other times of year, but the views and craggy coastal features make it a rewarding place to visit at any time.</p>

                  <h4 className="mb-2">Wick Heritage Centre, Wick</h4>
                  <p className="mb-4">Wick’s fortunes rose and fell on the back of the herring industry – the mid-19th century was a particular golden era – and this large heritage museum does a brilliant job of recounting the tales behind the town. It touches on all sorts of other aspects of life, from household furnishings to hordes of local photographs. </p>

                  <h4 className="mb-2">Sinclair’s Bay, Near Wick</h4>
                  <p className="">A colossal strand of white sand bookended by two ruined fortresses – namely Keiss Castle and Castle Sinclair Girnigoe – Sinclair’s Bay is renowned for everything from its seabirds to its surfing. Known locally as Reiss Beach, the sands are bisected by a small stream.</p>

                </motion.div>

                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">Strathy Bay, Near Melvich</h4>
                  <p className="mb-4">This is a popular stop-off for travellers on the North Coast 500 road trip – and it’s not hard to see why. The views out across the Pentland Firth are heavenly on a sunny day, while caves, cliffs and summer wildflowers add to the overall allure. It also marks the spot where the River Strathy flows into the sea.</p>

                  <h4 className="mb-2">Hill O’Many Stanes, Near Lybster  </h4>
                  <p className="mb-4">Some four millennia ago, people unknown erected more than 200 stones in loose rows on a low moorland hill. Exactly why is unclear – the theory is that the flagstone slabs served some sort of ceremonial or religious purpose – but the mystery around the site only serves to add to its pull. There’s parking a couple of minutes’ walk away.</p>

                  <h4 className="mb-2">Dunbeath Bay, Dunbeath</h4>
                  <p className="">The historic remains found nearby – including monastic ruins and Iron Age brochs – hint at the strategic importance of this bay on Caithness’ southern coast. Today it remains a stirring spot, with rumpled cliffs sloping down to the waves and some fine coastal walking to enjoy.</p>

                </motion.div>
              </div>
            </div>

            <div className="mb-5" ref={sevenContainer}>
              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">John O’Groats Signpost, John O’Groats  </h4>
                  <p className="mb-4">New York is, according to the signage, a mere 3,730 miles from this much-photographed marker, a snap of which has become seen as a near-obligatory rite of passage for anyone beginning or ending the long slog to Cornwall’s Land’s End – or, indeed, anyone who happens to be passing. There was a time when you needed to pay a fee to get up close to the signpost; these days, thankfully, it’s free.</p>

                  <h4 className="mb-2">Bay of Sannick, Near John O’Groats</h4>
                  <p className="mb-4">The great bays of the Caithness coast tend to have characteristics in common, being boundless filmic expanses of sand and salt-spray, and this wide cove near John O’Groats is no exception. It’s the kind of place where even when there are other people around, you’ll still get the sense of having the beach to yourself.</p>

                </motion.div>

                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">Castle of Old Wick, Near Wick</h4>
                  <p className="mb-4">An unmistakable sight on the coastal skyline, like a lone giant tooth marooned on a clifftop, the Castle of Old Wick has origins that date back more than 600 years. These days more or less all that’s still standing are the remnants of a four-storey tower, albeit one that enjoys an impossibly dramatic location on a craggy finger of rock between two sheer drops.</p>

                  <h4 className="mb-2">Noss Head Lighthouse, Near Wick </h4>
                  <p className="mb-4">‘As sweet to me as light of moon or star/ Is thy bright gleam, old trusty friend Noss Head’. So begins the poem, by James G Duncan, dedicated to this long-standing lighthouse. A fixture on the clifftop since 1849, and still very much a working beacon, it was automated in 1987. If you really want to make the most of a visit, the lighthouse keeper’s cottage is available as a self-catering property.</p>

                </motion.div>
              </div>
            </div>

            <div className="mb-5" ref={eightContainer}>
              <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article12-img19.jpg")} />
              <motion.img variants={textAnimate} className="img-fluid mb-0" src={require("../../Images/article12-img20.jpg")} />
              <p class="mt-2 mb-0 fs-sm fw-semibold">This Page: Dunnet Head Lighthouse and Whaligoe Steps, opposite page: Castle of Old Wick</p>

            </div>

            <div className="mb-5" ref={nineContainer}>
              <h3 className="mb-3">Eat & Drink</h3>
              <p className="mb-5">With the North Coast 500 route skirting the perimeter of the region, <br />Caithness has a strong selection of great places to eat, drink and stay. </p>
              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Printers Rest, Wick</h4>
                  <p className="mb-4">Run by Karen and John McMaster, who previously headed up the award-winning Whaligoe Steps Café, this former printing press in Wick has now been reinvented as a restaurant, café, yarn shop, photography studio and glass workshop. The food is great and has an international feel, with mezze, pastas, curries, Turkish pide and more. A fine stop-off on the North Coast 500.</p>

                  <h4 className="mb-2">Castletown Chip Shop, Thurso</h4>
                  <p className="mb-4">There’s nothing fancy or wholesome about this Thurso fish and chip shop, but it serves up the kind of grub that hungry bellies hanker after. As well as the traditional fish suppers and jumbo sausages, your visit might also coincide with a batch of deep-fried spam fritters or – around Christmas – battered pigs-in-blankets and sprouts.</p>

                  <h4 className="mb-2">No1 Bistro, Wick</h4>
                  <p className="">Part of Mackays Hotel in Wick, this is an enjoyable lunch spot with an emphasis on Scottish produce and a menu that incorporates everything from pan-fried Orkney scallops or mushroom ravioli to pulled beef mac and cheese. Suppliers include Caithness Smokehouse and Scrabster Seafoods.</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Storehouse, John O’Groats</h4>
                  <p className="mb-4">This coastal café recently changed hands and will be reopening at some point in 2023, although details at time of writing were still hazy. But with floor-to-ceiling windows giving the place excellent sea views, it’s definitely one to keep tabs on.</p>

                  <h4 className="mb-2">Harbour Chip Shop, Wick </h4>
                  <p className="mb-4">You can’t get much closer to the sea than this popular harbourside chippy, which serves up soups and macaroni cheese alongside the chip shop standards. The same team now also owns Henrietta’s Chip Shop, another longstanding Wick eatery.</p>

                  <h4 className="mb-2">Bord de L’Eau, Wick</h4>
                  <p className="">Daniel Chretien hails from the southwest of France but can now be found at the helm of Gallic restaurant Bord de l’Eau, a bread roll’s throw from the River Wick. Traditional French cuisine is the order of the day, so expect everything from terrines, soups and pan-fried frogs’ legs to hand-picked red wines.</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Captain’s Galley, Scrabster</h4>
                  <p className="mb-4">Seafood takes centre-stage at this award-winning Scrabster restaurant, with the menu featuring seared scallops, locally spear-caught sea bass, and even Caithness seaweed butter new potatoes. The harbour is literally right outside the door, and there’s also a takeaway seafood bar. </p>

                  <h4 className="mb-2">Y Not Bar and Grill, Thurso  </h4>
                  <p className="mb-4">Seafood, roasts and cocktails all feature at this casual bar and grill in Thurso, which has a regularly changing selection of specials. </p>

                  <h4 className="mb-2">Olive, Thurso </h4>
                  <p className="">Not all coffee shops are created equal. This Brabster Street cafe doubles not only as the kind of quality brunch spot you’ll want to linger in, but also hosts monthly BYOB pizza nights and live music events. It’s open until 4pm on most days.</p>

                </motion.div>
              </div>

            </div>

            <div className="mb-5" ref={tenContainer}>
              <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article12-img21.jpg")} />
            </div>

            <div className="mb-5" ref={elevenContainer}>
              <h3 className="mb-3">Stay</h3>

              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">The Portland Hotel, Lybster</h4>
                  <p className="mb-4">Here’s a welcome refuge on a bleary day. The 22 en-suite rooms at this family-run village hotel range from design-led singles to deluxe four-poster sanctuaries. There’s a good restaurant to linger in, as well as a gin and whisky bar, and the hotel’s location means it’s well accustomed to attracting North Coast 500 drivers and bikers.</p>

                  <h4 className="mb-2">Ulbster Arms Hotel, Halkirk</h4>
                  <p className="mb-4">A 19th-century bolthole on the banks of the River Thurso, this tradition-heavy hotel boasts log fires, premium doubles and an a la carte restaurant, although you may find it hard to drag yourself away from the wood-panelled Lounge Bar, complete with an original carved fireplace from a Dutch galleon. There are three lodges in the grounds, including one in a converted barn, so the accommodation options are numerous.</p>

                  <h4 className="mb-2">Mackays Hotel, Wick </h4>
                  <p className="">Famed for having the world’s smallest street on its doorstep, the family-owned Mackays Hotel is a notable draw in its own right. Rooms are smart, with a contemporary design, and there’s dog-friendly accommodation for those doing the North Coast 500 with their canine companions in tow. It’s also home to the No1 Bistro.</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Seaview Hotel , John O’Groats</h4>
                  <p className="mb-4">The name lets you know what to expect from this whitewashed John O’Groats hotel, which counts a far-flung location and big panoramas as its chief selling points. It’s a friendly spot, and there’s a bar and restaurant to keep you well fuelled.</p>

                  <h4 className="mb-2">Station Hotel, Thurso  </h4>
                  <p className="mb-4">Another that does what it says on the tin, this three-star hotel is located steps away from Thurso’s station, a stop on the Wick-to-Inverness line. Don’t expect anything fancy, but it’s well priced and occupies a handsome old period building with a neat white balcony.</p>

                  <h4 className="mb-2">Pennyland House, Near Thurso </h4>
                  <p className="">With belting views across to Orkney, this highly rated B&B has just six bedrooms – all named after the owner’s favourite golf courses – and sits a 10-minute walk outside of Thurso. The décor has real character and breakfast covers all the main bases, from porridge to a full Scottish plate.</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-4">
                  <h4 className="mb-2">Forss House Hotel, Near Thurso</h4>
                  <p className="mb-4">Tucked away in woodland on a bend of the River Forss, this country house hotel is the real deal. There’s a double AA-rosette restaurant – which makes good use of produce from nearby estates – and a choice of 14 countryside rooms, all with antique furnishings. Some rooms have rolltop baths and super king-size beds. Afternoon tea is served at weekends, complete with scones and bubbly, and the house bar has dozens of different single malts. What’s not to love?</p>

                  <h4 className="mb-2">The Marine, Thurso </h4>
                  <p className="mb-4">A former sailors’ pub reinvented as an upmarket B&B, The Marine has just three rooms – as well as a self-catering apartment next door – but wins plaudits for its bay views, its comfortable rooms, and a breakfast menu that includes locally smoked salmon and local free-range eggs. </p>

                </motion.div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article12-img22.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article12-img23.jpg")}
                  />
                </div>
              </div>
              <img className="img-fluid mb-5" src={require("../../Images/article12-img24.jpg")} />

              <h3 className="mb-3">Shopping <br />& Activities</h3>
              <p className="mb-5">Whether you’re looking to pick up a meaningful gift or just feel the sea breeze in your hair, Caithness has some great options for visitors. </p>
              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">North Lands Creative, Lybster</h4>
                  <p className="mb-4">A fantastic studio and gallery dedicated to ‘the study and development of glass as an artform’, North Lands Creative was set up in an old stone building in Lybster back in 1995 and has since gone on to become an internationally significant centre for glass. A new studio was opened in 2002 by the then-Prince of Wales, now King Charles III. Masterclasses, exhibitions and other events are held each year.</p>

                  <h4 className="mb-2">Coo’s Tail Gallery, Thurso</h4>
                  <p className="mb-4">You’ll find homeware, jewellery and hand-crafted gifts at this Thurso outlet. It’s a stockist of Lisa Poulsen’s much-lauded StoneScapes digital landscapes, which are centred on the flagstone that Caithness is famed for. For more about Lisa’s remarkable artworks, visit her site at inspiredbycaithness.com</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">Lyth Arts Centre, Lyth</h4>
                  <p className="mb-4">Sitting inland, and roughly equidistant from Wick and John O’Groats, Lyth Arts Centre bills itself as the UK’s most northerly mainland arts centre. You might catch anything from theatre, dance or comedy to jazz, classical and family events.</p>

                  <h4 className="mb-2">Patricia Niemann Design, Berriedale </h4>
                  <p className="mb-4">Patricia is a Bavarian designer and goldsmith who has put down her roots in Caithness, now creating fine jewellery with both Scottish and German influences. Gemstones, pearls and even deer antlers all feature. Her studio showroom is based in the old post office at Berriedale, a treasure trove among the steep wooded hills. </p>

                </motion.div>
              </div>

              <img className="img-fluid mb-4" src={require("../../Images/article12-img25.jpg")} />

              <div className="row mb-5">
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article12-img26.jpg")}
                  />
                </div>
                <div className="col-6">
                  <img className="img-fluid"
                    src={require("../../Images/article12-img27.jpg")}
                  />
                </div>
              </div>

              <div className="row mb-5">
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">John O’Groats Trail</h4>
                  <p className="mb-4">This work-in-progress walking route between Inverness and John O’Groats – or vice versa – covers 147 miles and takes in windswept clifftops, wave-bashed shorelines and scenic footpaths. Some sections are currently pathless whereas others are well established hiking trails, but it’s already possible to walk end-to-end with the right planning and experience. See jogt.org.uk for route updates.</p>

                  <h4 className="mb-2">Reay Golf Course, Reay</h4>
                  <p className="mb-4">The UK’s most northerly 18-hole links golf course is appealing in various ways, not least for the fact that you can just turn up and play. A stunning location on Sandside Bay is another boon. The club was founded in 1893 and has been named one of the top 100 courses in Scotland. </p>

                  <h4 className="mb-2">Caithness Sea Coast, Wick</h4>
                  <p className="mb-4">Running from spring through to mid-autumn, Caithness Sea Coast has regular sailings from Wick Harbour Marina, with options ranging from short family harbour cruises to three-hour voyages out to the uninhabited islands of the Pentland Skerries. The vessel is a ten-metre-long RIB (Rigid Inflatable Boat) and all trips are likely to involve a mixture of wildlife, geology and history.</p>

                </motion.div>
                <motion.div variants={textAnimate} className="col-sm-6">
                  <h4 className="mb-2">John O’Groats Ferries, John O’Groats</h4>
                  <p className="mb-4">As well as operating two daily 40-minute ferry crossings to Orkney – available from early May to late September – this company also offers 90-minute wildlife cruises. Come during the breeding season to catch large colonies of puffins, guillemots, razorbills and more. You’ll also see plenty of Atlantic grey seals.</p>

                  <h4 className="mb-2">Wick Golf Club, Near Wick </h4>
                  <p className="mb-4">Having recently celebrated its 150th anniversary, this historic course is well placed on the North Coast 500 to cater for any urges you might have to tee off down giant, sea-facing fairways. </p>

                </motion.div>
              </div>


            </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article12;
