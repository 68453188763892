import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article18() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';

      return "";
    }

  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article19');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article17');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article18-img1.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article18-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div variants={textAnimate}>
              <h1 className="fs-xl fw-light mb-3">Gooseberry & <br/>Oregano Friands</h1>
            </motion.div>

            <motion.p className="mb-5 font-italic" variants={textAnimate}>To keep up to date with Baern Café: <br/>Baern Café: @baern_cafe <br/>Bowhouse: www.bowhousefife.com</motion.p>

            <motion.p className="" variants={textAnimate}>Friands are beautiful little almond and brown butter cakes, and they’ve been a favourite at Baern throughout the seasons. We change the flavours depending on what is available from the gardens or the hedgerows here in the East Neuk. </motion.p>

            <motion.p className="mb-5" variants={textAnimate}>This is our summer version, but they also work really well with bay leaves and apple in the autumn, or sage and lightly pickled fruits in the winter. We like to top ours with toasted Italian meringue or whipped crème fraiche, but they’re equally lovely with just a dusting of fresh nutmeg or herbs.</motion.p>

            <motion.h4 className="mb-3">Ingredients</motion.h4>

            <ul className="list-unstyled mb-5">
              <li>180g unsalted butter</li>
              <li>60g plain flour</li>
              <li>200g icing sugar</li>
              <li>1/8 tsp. fine salt</li>
              <li>150g egg whites</li>
              <li>100g gooseberries cut in half</li>
              <li>25g fresh oregano on the stem  </li>
            </ul>

            <motion.p className="" variants={textAnimate}>Brown your butter. Warm the butter and oregano in a saucepan until it foams and turns a rich chestnut brown colour, it should smell like toasted nuts. Take the pan off the heat and let it stand for five minutes to allow the oregano to infuse. Strain through a sieve into a metal bowl, catching all the oregano and the dark brown butter solids that have formed, discard these, allow the mixture to cool slightly. </motion.p>
            <motion.p className="" variants={textAnimate}>Sieve the flour, icing sugar, ground almonds and salt into a large bowl. Whisk your egg whites until frothy, and then fold into the dry mix. Add the warm (not too hot, not too cold) brown butter and fold until the batter is smooth. We like to put the mix into a piping bag here to make the next step easier. </motion.p>
            <motion.p className="" variants={textAnimate}>Brush an oval silicone mould with unscented oil, fill the moulds two thirds of the way, and then gently press three halves of gooseberries into the centre. Bake for ten minutes at 200  degrees (fan), then turn the oven down to 190 (fan), rotate the trays in the oven, and bake for another eight minutes. This high temperature forms the delicious chewy crust. They should rise in the oven and then settle back down while they cool in the moulds on a wire rack. Let them cool for five to ten minutes before removing them from the moulds, and then allow to cool completely before icing. </motion.p>

          </div>
          </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article18;
