import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article10() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0
  });


  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      var img2 = document.getElementById("img2");
      img2.className = '';

      return "";
    }

    else if (secondInView) {
      var img1 = document.getElementById("img1");
      img1.className = '';
      var img2 = document.getElementById("img2");
      img2.className = 'active';

      return "";
    }
  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article11');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article9');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article10-img1.jpg")} />
          <img id="img2" className={getAnimationClass()} src={require("../../Images/article10-img4.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">
          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article10-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <h1 className="fs-xl fw-light mb-3">The Rewilding of <br/>the Supper Club </h1>
            <motion.h4 className="text-gold mb-5" variants={textAnimate}>What started as an urban trend is now spreading to remote corners of Scotland and, post pandemic, there’s a renewed appetite for communal dining and wild feasting.</motion.h4>
            <motion.p className="" variants={textAnimate}>I’m sitting by the fire at Tulach Ard, a rambling 18th-century manse on the shore of Loch Alsh, the ice clinking in my G&T, chatting to Stella Stewart about architecture, design, sheep and the old crofter selling eggs at the top of the lane, as we wait for everyone to arrive for dinner. I’m not the host, but then neither is she - although this is her house. </motion.p>
            <motion.p className="" variants={textAnimate}>I’ve driven to the northwest coast of Scotland, 80 meandering miles cross-country, for one of Eolach’s ‘Secret Dinners’. Chef Verity Hurding and her wife Jenny’s supper clubs have a growing following and seats at the table are snapped up in seconds.</motion.p>
            <motion.p className="" variants={textAnimate}>The supper club-meets-wilderness dining phenomenon is sweeping across rural Scotland right now. This once urban trend has spiralled out to more far-flung areas and a growing number of inventive chefs are celebrating and showcasing the local produce in the landscape where it was farmed, fished, foraged or grown. </motion.p>
            <motion.p className="" variants={textAnimate}>One of the first to head for the hills was Ballintaggart Farm’s Chris Rowley. Originally in banking, he and his wife Rachel set up a popular supper club in Edinburgh, but after retraining as a chef at Leiths they packed their bags to move to the country. Along with a farm, cookery school, boutique hotel and restaurant, The Grandtully, in Perthshire they organise a smattering of communal feasts throughout the year.  </motion.p>
            <motion.p className="" variants={textAnimate}>On the other side of the Cairngorms at Glen Dye, Xanthe Gladstone’s chef residencies combine foraging forays and cooking demos with sumptuous suppers in the old steading, while the Free Company in the Pentland Hills showcase their farm produce in the spring, summer, autumn and winter ‘series’ of sell-out suppers held in an old barn.  And with the memory of Covid lockdowns still too close for comfort, the concept of convivial, communal dining has never been so enticing. </motion.p>

            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article10-img2.jpg")} />
            <motion.img variants={textAnimate} className="img-fluid mb-4" src={require("../../Images/article10-img3.jpg")} />
            <div className="row mb-5">
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>Creating wild feasts in spectacular locations might have the romantic edge over supper in a flat in Edinburgh’s New Town, but it’s not without its challenges. After trekking across the country for a gastronomic marathon in a hidden heather-sprung glen or on a dune-backed beach, dinner guests need somewhere to sleep.</motion.p>
                <motion.p className="" variants={textAnimate}>Cue Tulach Ard. Verity and Jenny have hosted suppers in their home in Glenelg, the rugged peninsula just a short curve around the coast, where diners can bunk down in the local inn or sprinkling of B&Bs in the summer. But they are now also working with properties such as Tulach Ard and Guardswell Farm in Perthshire, which has a two-fold advantage – owner Anna Lamotte can provide the fresh produce from side venture Guardswell Grows for the feast along with boutique, sheepskin-strewn beds in a peppering of bespoke hillside huts.</motion.p>
                <motion.p className="" variants={textAnimate}>It was Stella’s grandparents who bought Tulach Ard in the 1960s.  The old part of the house, she tells me, dates back to 1740; the more recent addition at the back, overlooking the loch, was added in 1910. This extension, where we’re sitting, was originally three small rooms but her brother, Lachlan, an architect like their father, redesigned it into one glorious wide-angled, horizontal-slatted, wood-panelled room, a striking Caithness stone-clad fireplace at either end.  </motion.p>
                <motion.p className="" variants={textAnimate}>If the contemporary oak and modern tartan interiors look familiar, that’s because Stella is the daughter of Annie and Lachlan Stewart who founded the Ross-shire textile and stoneware brand Anta, which takes its inspiration from the Scottish landscape. </motion.p>
                <motion.p className="" variants={textAnimate}>The house has been carefully restored and converted into a unique self-catering property furnished with Anta fabrics and furniture interspersed with family antiques. The eight bedrooms, some with contemporary four posters and roll-top baths, have views out over the sea to Skye or onto the wooded hillside.</motion.p>
                <motion.p className="" variants={textAnimate}>A flurry at the door signals the arrival of the other guests who drift in clutching bottles of wine and champagne, (the dinner is BYOB) chatting and laughing. The other diners are a couple from Skye who run a bakery and a group of Scottish and Australian friends from Glenelg, and they bring an instant dinner party vibe.</motion.p>

              </div>
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>“The type of person who books a supper club is happy to talk to strangers and eat whatever is on the menu,” Jenny explains later. They are adventurous, ready to take a leap of faith and open to whatever the evening brings.</motion.p>

                <motion.p className="" variants={textAnimate}>Our group is no exception - and proof that a table of strangers can be a beautiful thing. There’s a warmth and conviviality. The conversation flows. </motion.p>
                <motion.p className="" variants={textAnimate}>After fireside drinks we take our seats at the long table for the seven-course tasting menu. To start: a tiny home-baked brioche topped with Highland Wagyu beef and shallot with a bijou beremeal, parsnip and chestnut tart. The parsnips are sourced from their friends Jake and Kate of Cosaig Growers in Glenelg, who have turned a north-facing slope blanketed in bracken and a boggy field into a successful market garden using the no-dig method. </motion.p>
                <motion.p className="" variants={textAnimate}>“They treat the compost like a recipe,” Verity explains later, “using the pub’s food scraps and wool scraps from shearing. They grow the best-tasting vegetables.”</motion.p>
                <motion.p className="" variants={textAnimate}>Next is creel-caught langoustine from Duncan MacKerlich on Loch Duich. </motion.p>
                <motion.p className="" variants={textAnimate}>“You can see where they’re landed from here.” Passion for provenance is a supper club staple. </motion.p>
                <motion.p className="" variants={textAnimate}>As we chat the dishes keep coming. ‘Potato, Potato, Potato’ is salt-baked new potatoes in a creamy potato and leek sauce topped with crisp dehydrated skins. The lamb, from butcher George Macrae in Kyle of Lochalsh down the road, is salted eight hours before cooking then pan seared.</motion.p>
                <motion.p className="" variants={textAnimate}>To finish: ‘Pear, mint, sorrel, yoghurt’ - tiny cubes of compressed pear with three types of mint, lemon balm, fennel fronds and sorrel. “I’ve been wanting to do something with fennel and pear for a while, playing about with it in my head.” Now she has - and it’s sublime.</motion.p>
                <motion.p className="" variants={textAnimate}>Verity and Jenny like to keep the supper clubs to around eight people and after the last dish is served “sitting down with everyone is the reward,” Jenny smiles. “With supper clubs there’s no boundary between the chef and diners. It gets to the core of what hospitality is. Food is a way to care, for people to come together to share and express love.”</motion.p>
              </div>
            </div>

            

          </div>

          <div className="" ref={secondContainer}>
            

          <motion.h2 className="text-orange fw-normal mb-5" variants={textAnimate}>“… a table of strangers can 
                <br/>be a beautiful thing. There’s 
                <br/>a warmth and conviviality. 
                <br/>The conversation flows.”</motion.h2>
            <div className="row mb-5">
              <div className="col-sm-6">
                <motion.p className="" variants={textAnimate}>Small, intimate supper clubs are only part of the story, however. At the Dell of Abernethy in the Cairngorms, an old lodge and cluster of retro-chic holiday cottages in Abernethy forest, Polly and Ross Cameron’s monthly shindigs are larger, livelier affairs - but then they are old hands at putting on events. They founded the Insider music festival and used to hold a monthly supper club in the restaurant they ran in Rothiemurchus, Ord Ban, near Aviemore. Polly is also a chef.</motion.p>
                <motion.p className="" variants={textAnimate}>The supper clubs are held in a giant woodland tipi, the one that drew the BBC Springwatch crew here - and back again for four seasons of filming.  Strung with fairy lights among the trees, the tipi twinkles in the dark, beckoning us in.  After peeling off our coats we cluster around the roaring fire in the middle of the tent, warmed by the flickering flames as we sip glasses of bubbly and canapés are handed around: moreish mouthfuls of blue cheese, saffron poached pear & walnut crostini, roast carrot & hazelnut crostini, Great Glen charcuterie, green peppercorn salami, roast beetroot & horseradish, Persian herb frittata & green olive and a spoonful of scallop & green apple ceviche. At the bar Ross whips up cocktails, the local Inshriach Gin Sour an immediate hit.</motion.p>
                <motion.p className="" variants={textAnimate}>After taking our seats at one of the trestle tables, we tuck into creamy bowls of Cullen Skink with honey and walnut soda bread followed by venison carpaccio from the</motion.p>
                

              </div>
              <div className="col-sm-6">

                <motion.p className="" variants={textAnimate}>surrounding forest, laced with orange and horseradish before a hearty dish of melt-in-the-mouth braised beef with butterbeans and figs. For dessert: chocolate and hazelnut tart, candied chestnut and a dreamy buttermilk ice cream. And finally, cheese and biscuits; Cromal - a light, lemony organic cheese from the Connage Highland Dairy on the Moray Firth with crackers and sticky honeycomb</motion.p>

                <motion.p className="" variants={textAnimate}>At our table we discover the young couple from the Isle of Mull are the musicians, Sorren Maclean on guitar and Hannah Fisher on fiddle. Their performances, woven between each course, are mesmerising and silence the rowdy room until the music fades and the eating and chatting starts up again. It’s the early hours before everyone eventually starts to wander off into the night and we stumble through the trees to our bed in the lodge.</motion.p>
                <motion.p className="" variants={textAnimate}>It’s an unforgettable experience, a wild woodland feast with all the right ingredients, one of which is a crowd of strangers, happy to share food and laughter, stories and wonderment, to break bread with each other once more in a large canvas tent in the middle of a forest.</motion.p>
                
              </div>
            </div>
            
          </div>
        </div>
        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article10;
