import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import Scroll from '../../Layout/SmoothScroll';
import { useState } from "react";
function Article14() {
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0
  });

  const getAnimationClass = () => {
    if (firstInView) {
      var img1 = document.getElementById("img1");
      img1.className = 'active';
      return "";
    }

  };

  const textAnimate = {
    offscreen: { y: 10, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        duration: 0.5
      }
    }
  }
  const imgAnimate = {
    offscreen: { opacity: 0 },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.25,
        delay: 0.5,
        duration: 0.5
      }
    }
  }

  const [addClass, setAddClass] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article15');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick1 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/article13');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  const handleClick2 = () => {
    setAddClass(true);
      // Call your function here
    setTimeout(() => {
      navigate('/');
      
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
    
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Scroll />
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.25 }}
        className="magazine-wrap article-wrap"
      >
        <motion.div
          variants={imgAnimate}
          className="article-left"
        >
          {/* {getImage()} */}
          <img id="img1" className="active" src={require("../../Images/article14-img1.jpg")} />
        </motion.div>
        <div className="article-right scroll-sec">

          <motion.div variants={textAnimate} className="article-left-mob">
            <img src={require("../../Images/article14-img1.jpg")} alt="" />
          </motion.div>

          <motion.div
            variants={textAnimate}
            className="hamburger-menu mb-5">
            <Link to="/">
              <img
                src={require("../../Images/hamburger-menu-icon.png")}
              />
            </Link>
          </motion.div>
          <div className="article-mob-wrap">
          <div className="" ref={firstContainer}>
            <motion.div variants={textAnimate}>
              <h1 className="fs-xl fw-normal mb-5 d-inline-block"><span className="fs-md">What’s on</span> <br /><span className="fw-semibold text-orange">SCOTLAND</span> <br /><span className="text-end d-block fs-md">Spring-Summer</span></h1>
            </motion.div>

            <div className="row mb-5">
              <motion.div variants={textAnimate}className="col-sm-6">
                <h4 variants={textAnimate} className="mb-2 text-orange">Alchemy Film & Moving Image Festival</h4>
                <h5 variants={textAnimate} className="mb-2 text-orange">27th - 30th April</h5>

                <p variants={textAnimate} className="mb-4">Based in the Scottish Borders town of Hawick, Alchemy Film & Arts works with communities and artists on a local and international level, using film as a way to make positive change. The 13th edition of the Alchemy Film and Moving Image Festival will screen a diverse range of experimental films along with discussions and moving-image exhibitions around the town. As the organisers say, the event “is known for the openness, solidarity, humour and non-competitive nature with which it is delivered.”</p>
                <p variants={textAnimate} className="mb-4 text-orange">alchemyfilmandarts.org.uk/festival</p>

                
                
              </motion.div>
              
              <motion.div variants={textAnimate} className="col-sm-6">

                <h4 variants={textAnimate} className="mb-2 text-orange">Arran Mountain Festival</h4>
                <h5 variants={textAnimate} className="mb-2 text-orange">12th - 15th May</h5>

                <p variants={textAnimate} className="mb-4">Whether you’re an experienced walker or simply keen to explore more this summer, the Arran Mountain Festival is an opportunity to discover a fascinating island – while being guided by experienced local leaders. The programme features a wide range of walks, in small groups, over its four days, from moderate, such as the Three Beinns Horseshoe, to challenging routes such as Cioch Na H-Oighe and Goatfell, to rock clambering on the A’Chir Ridge Scramble.</p>
                <p variants={textAnimate} className="mb-4 text-orange">arranmountainfestival.co.uk</p>

              </motion.div>
            </div>

            <div className="row mb-5">
              
              <motion.div variants={textAnimate} className="col-sm-6">

                <h4 variants={textAnimate} className="mb-2 text-orange">Soo Burnell, ‘I am home’ </h4>
                <h5 variants={textAnimate} className="mb-2 text-orange">23rd June - 2nd July</h5>

                <p variants={textAnimate} className="mb-4">Following on from the success of her Poolside and Cinema collections, Edinburgh artist Soo Burnell showcases a new collection of photography, ‘I am home’, that turns her gaze, and ours, to iconic buildings around the city, both well known, including the Scottish National Portrait Gallery, and lesser known spaces that are loved by locals. Combining her eye for architecture and symmetry with stylised figures highlighting the scale of each scene, once again Soo has created evocative works that exude a sense of calm.</p>
                <p variants={textAnimate} className="mb-4 text-orange">soo-burnell.com</p>

              </motion.div>

              <motion.div variants={textAnimate} className="col-sm-6">

                <h4 variants={textAnimate} className="mb-2 text-orange">Wild Dining </h4>
                <h5 variants={textAnimate} className="mb-2 text-orange">8th June, 13th July & 24th August</h5>

                <p variants={textAnimate} className="mb-4">As part of their programme of events, Jupiter Artland is hosting a series of wild dining evenings with the award winning and National Chef of the Year semi-finalist 2022 David Millar, who is the chef of Café Party at Jupiter Artland. Located near Bonnington House Steadings near Wilkieston, and within easy reach of Edinburgh, the woodlands will come to life with a feast for the senses as guests are seated at one long communal table, creating a magical dining experience below the trees. </p>
                <p variants={textAnimate} className="mb-4 text-orange">jupiterartland.org</p>

              </motion.div>
            </div>

            <div className="row mb-5">
              
              <motion.div variants={textAnimate} className="col-sm-6">

                <h4 variants={textAnimate} className="mb-2 text-orange">Tall Ships Lerwick </h4>
                <h5 variants={textAnimate} className="mb-2 text-orange">26th - 29th July</h5>

                <p variants={textAnimate} className="mb-4">Having previously hosted the Tall Ships Races in 1999 and 2011, Lerwick in Shetland is welcoming back the fleet this July. The annual race series has run since 1956 and continues to provide life-changing adventures for thousands of young sail trainees. Many ships have already confirmed their participation, including the Roald Amundsen, a Class A Brig named after the Norwegian explorer and launched in 1952, and the dramatic looking Valentine, a Gaff Schooner 2 that was built in Norway ten years earlier. </p>
                <p variants={textAnimate} className="mb-4 text-orange">tallshipslerwick.com</p>

              </motion.div>

              <motion.div variants={textAnimate} className="col-sm-6">

                <h4 variants={textAnimate} className="mb-2 text-orange">East Neuk Open Studios </h4>
                <h5 variants={textAnimate} className="mb-2 text-orange">24th & 25th June, 1st & 2nd July</h5>

                <p variants={textAnimate} className="mb-4">Spread over two weekends in June and July, East Neuk Open Studios literally opens the doors of artists’ studios and exhibition spaces across Fife’s East Neuk, from Kingsbarns to Lower Largo via Crail, Cellardyke, Anstruther, Pittenweem, St Monans and Ardross. Last year’s event saw over 50 artists exhibiting their work on this annual art trail, which guides visitors through the characterful fishing villages that provide the inspiration for so many local artists, designers and makers.</p>
                <p variants={textAnimate} className="mb-4 text-orange">eastneukopenstudios.org</p>

              </motion.div>

            </div>

            <div className="row mb-5">
              
              <motion.div variants={textAnimate} className="col-12">

                <h4 variants={textAnimate} className="mb-2 text-orange">Thrive Bike </h4>
                <h5 variants={textAnimate} className="mb-2 text-orange">30th September & 1st October</h5>

                <p variants={textAnimate} className="mb-4">Billed as a weekend of energy and excitement, visitors to the Thrive Bike event at Ballater in Aberdeenshire can expect a range of organised rides and events, including guest speakers, all with the goal of motivating people to cycle and positively impact on their wellbeing. The event includes guided electric and non-electric bike rides for adults and kids, so you can explore no matter your experience or ability.</p>
                <p variants={textAnimate} className="mb-4 text-orange">thriveballater.com</p>

              </motion.div>

            </div>

            <motion.div variants={textAnimate} className="bg-orange p-5 mt-5">
              <p className="mb-3">Scottish Vegan Festival Edinburgh <br/>2nd April</p>
              <p className="mb-3">Spirit of Speyside <br/>26th April - 1st May</p>
              <p className="mb-3">Islay Festival of Music & Malt <br/>26th May</p>
              <p className="mb-3">Seafood Tarbet Seafood Festival <br/>1st July</p>
              <p className="mb-3">Edinburgh Food Festival <br/>21st - 30th July</p>
              <p className="mb-3">Scottish Wild Food Festival <br/>16th & 17th September</p>
              <p className="mb-3">Wigtown Book Festival <br/>22nd September - 1st October</p>
              <p className="mb-3">30th Annual World Porridge Championships <br/>7th October</p>
              <p className="mb-3">Tiree Wave Classic <br/>14th - 20th October</p>

            </motion.div>

          </div>
          </div>

        </div>
      </motion.div>
      <div className="btns-group">
        <button className="btn nxtPrv-btn" onClick={handleClick1}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
        </button>
        <button className="btn home-btn" onClick={handleClick2}> Contents </button>
        <button className="btn nxtPrv-btn" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
        </button>
      </div>
      <button className={addClass ? 'active fold' : 'fold'} onClick={handleClick}></button>

      <div className={addClass ? 'active1 unfold' : 'unfold'}></div>
    </motion.div>

  );
}

export default Article14;
